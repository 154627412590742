import { useMemo, useState } from "react";

// Components
import SectionLabel from "../SectionLabel";
import NewTag from "./NewTag";

// Data & Definitions
import { ColorFamily } from "../../../../../style";

// Utils
import Tag from "./Tag";
import { LS_Engagement } from "../../../../../data/learningSystem/types";

/** Render Engagement Tags */
export default function Tags({
	observation,
	colorTheme,
}: {
	observation: LS_Engagement;
	colorTheme: ColorFamily;
}) {
	const [addTagActive, setAddTagActive] = useState(false);

	/** Sort tag categories and values alphabetically for consistency. */
	const tagsSortedByCategory = useMemo(() => {
		const tagsAsArray = Object.entries(observation.data.metadata.tags ?? {});
		tagsAsArray.sort((a, b) => a[0].localeCompare(b[0]));

		for (const category of tagsAsArray) {
			(category[1] as Array<string>).sort((a, b) => a.localeCompare(b));
		}

		return tagsAsArray;
	}, [observation]);

	return (
		<div id="course-engagement-tags" css={{ width: "100%", marginTop: 15 }}>
			<SectionLabel
				text={"tags"}
				colorTheme={colorTheme}
				action={{
					label: "New Tag",
					onClick: () => setAddTagActive((value) => !value),
				}}
			/>
			<NewTag
				active={addTagActive}
				setActive={setAddTagActive}
				colorTheme={colorTheme}
				observation={observation}
			/>
			<div css={{ display: "flex", flexWrap: "wrap", gap: 5, marginTop: 5 }}>
				{tagsSortedByCategory.map(
					([category, categoryEntries], categoryIndex) => {
						return Array.isArray(categoryEntries)
							? categoryEntries.map((name) => (
									<Tag
										key={`${category}-${name}`}
										observation={observation}
										category={category}
										categoryIndex={categoryIndex}
										name={name as string}
									/>
							  ))
							: null;
					},
				)}
			</div>
		</div>
	);
}
