import { css } from "@emotion/react";
import { useAtom } from "jotai";
import useDimensions from "react-cool-dimensions";
import { SpringValue, animated } from "@react-spring/web";
import { Property } from "csstype";

import { mediaQueries } from "../../style/breakpoints";
import { darkGray, midGray, portalColors } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";
import NavigatorLogo from "../../assets/svg/navigatorLogo.svg?react";
import UserLogin from "./UserLogin";

import UserRegistration from "./UserRegistration";
import { authenticationStateAtom } from "../../data/general";
import ResetPassword from "./ResetPassword";
import { supabaseAuthErrorAtom } from "../../data/user";

type UserControlsPanelProps = {
	animationSpring: { backgroundColor: SpringValue<Property.Color> };
};

export default function UserControlsPanel({
	animationSpring,
}: UserControlsPanelProps) {
	const [authenticationState, setAuthenticationState] = useAtom(
		authenticationStateAtom,
	);
	const [authError, setAuthError] = useAtom(supabaseAuthErrorAtom);

	const { observe, height: controlsHeight } = useDimensions({
		useBorderBoxSize: true,
	});

	const processSelectionButtonCSS = css({
		width: 100,
		fontFamily: PRIMARY_FONT,
		fontSize: 14,
		fontWeight: 600,
		color: darkGray,
		textAlign: "left",
		border: "none",
		outline: "none",
		backgroundColor: "transparent",
		transition: "all .25s ease",
		"&:hover": {
			backgroundColor: darkGray,
			color: "white",
		},
		"&:disabled": {
			opacity: 0.5,
			backgroundColor: "transparent",
			color: darkGray,
		},
	});

	return (
		<animated.div
			id="user-controls-panel"
			css={[
				{
					backgroundColor: portalColors.gray.light,
					padding: 25,
					boxSizing: "border-box",
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					marginBottom: 5,
					overflow: "hidden",
					transition: "all .5s ease",
					height: controlsHeight + 50,
				},
			]}
		>
			<div ref={observe} id="inner-positioning-div">
				<UserLogin animationSpring={animationSpring} />
				<UserRegistration animationSpring={animationSpring} />
				<ResetPassword />
				<div
					id="user-controls"
					css={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "flex-end",
					}}
				>
					<NavigatorLogo
						css={{
							height: "5rem",
							transform: "scale(.75)",
							transformOrigin: "bottom left",
							[mediaQueries[1]]: {
								transform: "scale(1)",
							},
							[mediaQueries[1]]: {
								transform: "scale(1)",
							},
						}}
					/>
					<div
						css={{
							display: "flex",
							flexDirection: "column",
							pointerEvents:
								authenticationState === "launchNavigator" ? "none" : "all",
							opacity: authenticationState === "launchNavigator" ? 0.25 : 1,
						}}
					>
						<button
							type="button"
							disabled={authenticationState === "confirmUserEmail"}
							css={[
								processSelectionButtonCSS,
								authenticationState === "login"
									? {
											backgroundColor: darkGray,
											color: "white",
										}
									: {},
							]}
							onClick={() => {
								setAuthenticationState("login");
								setTimeout(() => setAuthError(null), 500);
							}}
						>
							LOGIN
						</button>
						<button
							type="button"
							disabled={authenticationState === "confirmUserEmail"}
							css={[
								processSelectionButtonCSS,
								authenticationState === "register"
									? {
											backgroundColor: darkGray,
											color: "white",
										}
									: {},
							]}
							onClick={() => {
								setAuthenticationState("register");
								setTimeout(() => setAuthError(null), 500);
							}}
						>
							REGISTER
						</button>
						<button
							type="button"
							disabled={authenticationState === "confirmUserEmail"}
							css={[
								processSelectionButtonCSS,
								authenticationState === undefined
									? {
											backgroundColor: darkGray,
											color: "white",
										}
									: {},
							]}
							onClick={() => {
								setAuthenticationState(undefined);
								setTimeout(() => setAuthError(null), 500);
							}}
						>
							CLEAR
						</button>
					</div>
				</div>
			</div>
		</animated.div>
	);
}
