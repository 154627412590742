// Definitions
import { darkGray, ffOrange, portalColors } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";

// Components
import ChevronIcon from "../../assets/svg/icons/chevronRight.svg?react";

// Data
import { useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
	atlasOrganizationProjectsAtom,
	currentAtlasProjectAtom,
} from "../../data/project";
import { mediaQueries } from "../../style";
import { currentNavigatorFeatureAtom } from "../../data/feature";

export default function ProjectSelector() {
	const [state, setState] = useState<"open" | "closed">("closed");

	const availableProjects = useAtomValue(atlasOrganizationProjectsAtom);
	const [currentProject, setCurrentProject] = useAtom(currentAtlasProjectAtom);
	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);

	return (
		<div
			id="projectSelector"
			css={{
				marginTop: 5,
				marginBottom: 15,
				opacity: currentFeature === "Analytics" ? 1 : 0.5,
				pointerEvents: currentFeature === "Analytics" ? "initial" : "none",
			}}
		>
			<p
				css={{
					fontFamily: PRIMARY_FONT,
					color: darkGray,
					fontWeight: 700,
					fontSize: 12,
					margin: 0,
					textTransform: "uppercase",
				}}
			>
				Project
			</p>
			<div
				css={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					cursor: "default",
				}}
				onClick={() => {
					if (availableProjects.length > 1) {
						setState(state === "closed" ? "open" : "closed");
					}
				}}
			>
				<span
					css={{
						fontFamily: PRIMARY_FONT,
						fontWeight: 500,
						fontSize: 12,
						color: darkGray,
					}}
				>
					{currentProject?.name}
				</span>
				{availableProjects.length && (
					<ChevronIcon
						width={20}
						height={20}
						fill={darkGray}
						css={{
							transition: "all .5s ease",
							...(state === "open" ? { transform: "rotate(90deg)" } : {}),
							opacity: availableProjects!.length > 1 ? 1 : 0,
						}}
					/>
				)}
			</div>
			<div
				css={{
					borderTopStyle: "solid",
					borderTopWidth: 1,
					borderTopColor: darkGray + "40",
					marginTop: 5,
				}}
			>
				<div
					css={{
						transition: "all .5s ease",
						height:
							state === "closed"
								? 0
								: 40 * (availableProjects!.length - 1) + 10,
						overflow: "hidden",
						borderBottomStyle: "solid",
						borderBottomWidth: 1,
						borderBottomColor: darkGray + "40",
						opacity: state === "closed" ? 0 : 1,
						paddingTop: 5,
						paddingBottom: 5,
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
					}}
				>
					{availableProjects!
						.filter((project) => project.name !== currentProject?.name)
						.map((project) => (
							<button
								key={project.id}
								onClick={() => {
									setState("closed");
									setCurrentProject(project);
								}}
								css={{
									transition: "all .25s ease",
									fontFamily: PRIMARY_FONT,
									fontWeight: 600,
									fontSize: 12,
									color: portalColors.tan.dark,
									marginTop: 5,
									cursor: "default",
									padding: 10,
									border: "none",
									outline: "none",
									backgroundColor: "white",
									"&:hover": {
										color: "white",
										backgroundColor: ffOrange,
									},
									[mediaQueries[1]]: {
										backgroundColor: portalColors.tan.mid,
										color: "white",
									},
								}}
							>
								{project.name}
							</button>
						))}
				</div>
			</div>
		</div>
	);
}
