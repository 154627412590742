import { useEffect, useMemo, useState } from "react";
import { useTrail, easings } from "@react-spring/web";
import useDimensions from "react-cool-dimensions";
import { useAtomValue } from "jotai";
import { DateTime } from "luxon";
import Color from "color";

// Assets
import FirstPage from "../../../../assets/svg/icons/firstPage.svg?react";
import PreviousPage from "../../../../assets/svg/icons/previousPage.svg?react";
import NextPage from "../../../../assets/svg/icons/nextPage.svg?react";
import LastPage from "../../../../assets/svg/icons/lastPage.svg?react";

// Components
import ChartTitle from "../../../charts/ChartTitle";
import Tags from "./Tags";
import CardLabel from "./CardLabel";
import StringData from "./StringData";
import Tasks from "./Tasks";

// Data & Definitions
import { PRIMARY_FONT, mediaQueries, portalColors } from "../../../../style";
import { CORE_CHART_PROPS } from "../../../../types/charts/props";
import {
	currentNavigatorFeatureAtom,
	navigatorFeatures,
} from "../../../../data/feature";
import { engagementFiltersAtom } from "../../../../data/learningSystem/engagement/filters";
import UserResponses from "./UserResponses";
import { useAvailableWidth } from "../../../../data/hooks/useAvailableWidth";
import { LS_Engagement } from "../../../../data/learningSystem/types";

/** Default Table Chart */
export default function EngagementCards({
	observations,
	dataRefreshing,
	margins = { vertical: 25, horizontal: 25 },
	chartTitle,
	colorTheme = portalColors.gray,
	transparentBackground = false,
	engagementState,
}: CORE_CHART_PROPS<LS_Engagement> & {
	engagementState: "in-progress" | "complete";
}) {
	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);
	const engagementFilters = useAtomValue(engagementFiltersAtom);

	const availableWidth = useAvailableWidth(60);

	const [currentPageOfResults, setCurrentPageOResults] = useState(0);
	const pagedData = useMemo(
		() =>
			observations.slice(
				currentPageOfResults * 10,
				currentPageOfResults * 10 + 10,
			),
		[observations, currentPageOfResults],
	);

	const { canNext, canPrevious, lastPageIndex } = useMemo(
		() => ({
			canNext: (currentPageOfResults + 1) * 10 < observations.length,
			canPrevious: currentPageOfResults > 0,
			lastPageIndex: Math.floor(observations.length / 10),
		}),
		[observations, currentPageOfResults],
	);

	// Reset the current page if data is refreshing change
	useEffect(() => {
		if (dataRefreshing) setCurrentPageOResults(0);
	}, [dataRefreshing]);

	// Reset the current page if filters change
	useEffect(() => {
		setCurrentPageOResults(0);
	}, [engagementFilters]);

	// Need to observe the height of a couple of sub-components.
	const { observe: observeTitle, height: titleHeight } = useDimensions({
		useBorderBoxSize: true,
	});

	const [markerTrails, markerTrailsAPI] = useTrail(
		observations.length,
		(index) => ({
			from: { opacity: 0, filter: "saturate(0%)" },
			config: { easing: easings.easeInOutCirc, duration: 100 },
		}),
	);

	useEffect(() => {
		if (dataRefreshing) {
			markerTrailsAPI.start({
				to: { opacity: 0, filter: "saturate(0%)" },
				immediate: true,
			});
		} else {
			markerTrailsAPI.start({
				to: { opacity: 1, filter: "saturate(100%)" },
				immediate: false,
			});
		}
	}, [dataRefreshing, markerTrailsAPI]);

	const renderData = () => {
		return (
			<div
				css={{
					display: "grid",
					gridTemplateColumns: "repeat(1, 1fr)",
					gap: 10,

					[mediaQueries[0]]: {
						gridTemplateColumns: "repeat(2, 1fr)",
					},

					[mediaQueries[2]]: {
						// gridTemplateColumns: "repeat(3, 1fr)",
					},
					[mediaQueries[3]]: {
						gridTemplateColumns: "repeat(3, 1fr)",
					},
					[mediaQueries[4]]: {
						gridTemplateColumns: "repeat(4, 1fr)",
					},
				}}
			>
				{pagedData.map((observation) => (
					<section
						key={observation.observationLabel}
						css={{
							backgroundColor: transparentBackground
								? colorTheme.light
								: "white",
							fontFamily: PRIMARY_FONT,
							borderRadius: 5,
						}}
					>
						<div
							id="card-title"
							css={{
								padding: 15,
								backgroundColor: Color(colorTheme.dark).hex(),
								borderTopLeftRadius: 5,
								borderTopRightRadius: 5,
							}}
						>
							<h3
								css={{
									fontWeight: 700,
									color: "white",
									margin: 0,
									textTransform: "uppercase",
									fontSize: 16,
								}}
							>
								{/* @ts-ignore */}
								{`${observation.data.user?.data.raw_user_meta_data?.firstName} ${observation.data.user?.data.raw_user_meta_data?.lastName}`}
							</h3>
							<p
								css={{
									margin: 0,
									fontSize: 11,
									color: "white",
									fontWeight: 500,
									textTransform: "uppercase",
									wordBreak: "break-all",
								}}
							>
								{observation.data.user?.data.email}
							</p>
						</div>
						<div
							id="course-name"
							css={{
								backgroundColor: colorTheme.mid,
								padding: 10,
								paddingLeft: 15,
							}}
						>
							<p
								css={{
									margin: 0,
									fontSize: 12,
									color: "white",
									fontWeight: 600,
								}}
							>
								{`${observation.data.course_display_name}`}
							</p>
						</div>
						<div id="card-content" css={{ padding: 15, paddingTop: 5 }}>
							<div
								id="course-dates"
								css={{
									marginTop: 5,
									display: "grid",
									gridTemplateColumns: "auto auto",
									gap: 10,
									width: "100%",
								}}
							>
								<div>
									<CardLabel
										text={"Start Date"}
										colorTheme={portalColors.gray}
									/>
									<StringData
										text={
											DateTime.fromISO(
												observation.data.started_at,
											).toISODate() ?? ""
										}
										colorTheme={portalColors.gray}
										size="small"
									/>
								</div>
								{observation.data.finished_at && (
									<div>
										<CardLabel
											text={"Completion Date"}
											colorTheme={portalColors.gray}
										/>
										<StringData
											text={
												DateTime.fromISO(
													observation.data.finished_at,
												).toISODate() ?? ""
											}
											colorTheme={portalColors.gray}
											size="small"
										/>
									</div>
								)}
							</div>
							{!observation.data.finished_at &&
								observation.data.last_completed_module && (
									<div css={{ marginTop: 5 }}>
										<CardLabel
											text={"Last Completed Module"}
											colorTheme={portalColors.gray}
										/>
										<StringData
											text={observation.data.last_completed_module}
											colorTheme={portalColors.gray}
											size="small"
										/>
									</div>
								)}
							<Tags observation={observation} colorTheme={colorTheme} />
							<Tasks
								observation={observation}
								colorTheme={colorTheme}
								engagementState={engagementState}
							/>
							<UserResponses
								observation={observation}
								colorTheme={colorTheme}
							/>
						</div>
					</section>
				))}
			</div>
		);
	};

	const renderControls = () => {
		return (
			<div
				css={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
					marginTop: 10,
					fontSize: 12,
					color: portalColors.gray.dark,
				}}
			>
				<span css={{ marginRight: 25 }}>
					{`Records ${currentPageOfResults * 10 + 1}-${Math.min(
						observations.length,
						currentPageOfResults * 10 + 10,
					)} of
          ${observations.length}`}
				</span>
				<div css={{ display: "flex", gap: 5 }}>
					<FirstPage
						css={{
							transition: "all .33s ease",
							fill: canPrevious
								? portalColors.gray.dark
								: portalColors.gray.mid,
							":hover": {
								fill: canPrevious
									? navigatorFeatures[currentFeature].colors.mid
									: undefined,
							},
						}}
						onClick={() => canPrevious && setCurrentPageOResults(0)}
					/>
					<PreviousPage
						css={{
							transition: "all .33s ease",
							fill: canPrevious
								? portalColors.gray.dark
								: portalColors.gray.mid,
							":hover": {
								fill: canPrevious
									? navigatorFeatures[currentFeature].colors.mid
									: undefined,
							},
						}}
						onClick={() =>
							canPrevious && setCurrentPageOResults((value) => value - 1)
						}
					/>
					<NextPage
						css={{
							fill: canNext ? portalColors.gray.dark : portalColors.gray.mid,
							":hover": {
								fill: canNext
									? navigatorFeatures[currentFeature].colors.mid
									: undefined,
							},
						}}
						onClick={() =>
							canNext && setCurrentPageOResults((value) => value + 1)
						}
					/>
					<LastPage
						css={{
							fill: canNext ? portalColors.gray.dark : portalColors.gray.mid,
							":hover": {
								fill: canNext
									? navigatorFeatures[currentFeature].colors.mid
									: undefined,
							},
						}}
						onClick={() => canNext && setCurrentPageOResults(lastPageIndex)}
					/>
				</div>
			</div>
		);
	};

	return (
		<div
			id={chartTitle.text.replace(" ", "-").toLowerCase()}
			css={{
				backgroundColor: transparentBackground
					? "transparent"
					: portalColors.gray.light,
				paddingLeft: margins.horizontal,
				paddingRight: margins.horizontal,
				paddingTop: margins.vertical,
				paddingBottom: margins.vertical,
				boxSizing: "border-box",
				width: availableWidth,
				borderRadius: 5,
			}}
		>
			<div css={{ overflow: "hidden" }}>
				<div ref={observeTitle}>
					<ChartTitle
						callOut={chartTitle.callOut}
						text={chartTitle.text}
						color={portalColors.gray.dark}
						containerCSS={{
							boxSizing: "border-box",
							paddingBottom: 20,
							paddingRight: 20,
							fontSize: 26,
							textTransform: "capitalize",
						}}
					/>
				</div>
				{observations.length ? renderData() : null}
			</div>
			{renderControls()}
		</div>
	);
}
