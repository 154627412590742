import { atom } from "jotai";

const currentScreenDimensionsAtom = atom<[number, number]>([0, 0]);
const globalNavDimensionsAtom = atom<[number, number]>([0, 90]);
const featureNavDimensionsAtom = atom<[number, number]>([0, 0]);

export {
  currentScreenDimensionsAtom,
  globalNavDimensionsAtom,
  featureNavDimensionsAtom,
};
