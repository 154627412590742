import { type Getter, type Setter, atom } from "jotai";
import type { AtlasEngineDatabase } from "@form-films/atlas-engine-shared-definitions";

import { experiencesFilteredByEnclosingURLAtom } from "../experience";
import { gatherRecords } from "../../utils/utilities";
import { selectedDateRangeAtom } from "../../dateTime";
import supabaseClientAtom from "../../supabaseClient";
import { currentAtlasProjectAtom } from "../../project";
import { convertToObservationsWithStringLabels } from "../../../utils/observation";
import type { AnalyticsEvent } from "./types";

/**
 * All observations of analytics events for the
 * current project in the selected timeframe.
 * */
export const AS_eventObservationsAtom = atom<Array<AnalyticsEvent>>([]);

/**
 * Get all event observations belonging to experiences
 * filtered by the currently selected enclosing URLs.
 * */
export const AS_eventsObservationsFilteredByEnclosingURLsAtom = atom<
	AnalyticsEvent[]
>((get) => {
	const allEvents = get(AS_eventObservationsAtom);
	const filteredExperiences = get(experiencesFilteredByEnclosingURLAtom);

	const filteredExperienceIDs = filteredExperiences.map(
		(experience) => experience.observationLabel,
	);

	const filteredEventRecords = allEvents.filter(
		(eventRecord) =>
			eventRecord.data.experience_id &&
			filteredExperienceIDs.includes(eventRecord.data.experience_id),
	);

	return filteredEventRecords;
});

/** Function that will retrieve fresh analytics data from Supabase. */
export async function updateAnalyticsEvents(get: Getter, set: Setter) {
	const selectedDateRange = get(selectedDateRangeAtom);
	const supabaseClient = get(supabaseClientAtom);
	const selectedProject = get(currentAtlasProjectAtom);

	if (!selectedProject) {
		set(AS_eventObservationsAtom, []);
		return;
	}

	const eventRecords = await gatherRecords<
		AtlasEngineDatabase["public"]["Views"]["ie_event_mv"]["Row"]
	>(
		supabaseClient
			.from("ie_event_mv")
			.select("*")
			.order("event_timestamp")
			.eq("project_id", selectedProject.id)
			.gte("event_timestamp", selectedDateRange.from.toUTC())
			.lte("event_timestamp", selectedDateRange.to.toUTC()),
	);

	set(
		AS_eventObservationsAtom,
		convertToObservationsWithStringLabels(eventRecords, "event_id"),
	);
}

export * from "./nodeEntry";
export * from "./goalAchievement";
export * from "./userEnvironment";
