import { useAtomValue } from "jotai";
import { useMemo } from "react";

import {
	generateColorOffsetArray,
	mixColorFamilies,
	portalColors,
} from "../../../style/colors";
import { LS_EngagmentsByCourseAndDate } from "../../../data/learningSystem/engagement";
import { refreshLearningSystemDataAtom } from "../../../data/learningSystem";
import StackedBarChart from "../../charts/Bar/StackedBarChart";
import { LS_OrganizationalCoursesAtom } from "../../../data/learningSystem/courses";

export default function CoursesStartedByDate() {
	const dataRefreshing = useAtomValue(refreshLearningSystemDataAtom);
	const engagementsByStartDate = useAtomValue(LS_EngagmentsByCourseAndDate);
	const organizationCourses = useAtomValue(LS_OrganizationalCoursesAtom);

	const availableCourseDisplayNames = organizationCourses.map(
		(course) => course.data.display_name,
	);

	return (
		<StackedBarChart
			dataColors={generateColorOffsetArray(
				portalColors.blue.mid,
				35,
				availableCourseDisplayNames.length,
			)}
			observations={engagementsByStartDate}
			dataRefreshing={dataRefreshing}
			seriesNames={availableCourseDisplayNames}
			axes={{ observationLabel: "bottom" }}
			colorTheme={mixColorFamilies(portalColors.blue, portalColors.gray, 0.9)}
			chartTitle={{
				text: "Breakdown of courses started during the selected timeframe",
				callOut: "Courses Started By Date",
			}}
			tooltip={{ title: "Courses Started" }}
		/>
	);
}
