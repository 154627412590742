// import Browsers from "./Browsers";
import { css } from "@emotion/react";
import { mediaQueries } from "../../../style";
import Browsers from "./Browsers";
import MobileDevices from "./MobileDevices";
import MobileVsDesktop from "./MobileVsDesktop";
import MobileVsDesktopSummary from "./MobileVsDesktopSummary";
import OperatingSystems from "./OperatingSystems";
import { useAvailableWidth } from "../../../data/hooks/useAvailableWidth";

export type UserEnvironmentProps = {
	availableHeight: number;
};

export default function UserEnvironment({
	availableHeight,
}: UserEnvironmentProps) {
	const availableWidth = useAvailableWidth(60);

	return (
		<div
			css={{
				boxSizing: "border-box",
				display: "grid",
				gap: 20,
				marginTop: 10,
			}}
		>
			<div
				id="mobile-vs-desktop"
				css={{
					display: "grid",
					gap: 10,
					gridTemplateRows: `repeat(2, ${availableHeight * 0.5}px)`,
					gridTemplateColumns: `${availableWidth}px`,
					[mediaQueries[2]]: {
						gridTemplateRows: `${availableHeight * 0.5}px`,
						gridTemplateColumns: `${availableWidth * 0.33}px ${
							availableWidth * 0.66
						}px`,
					},
					[mediaQueries[3]]: {
						gridTemplateColumns: `${availableWidth * 0.25}px ${
							availableWidth * 0.75
						}px`,
					},
				}}
			>
				<MobileVsDesktopSummary />
				<MobileVsDesktop />
			</div>
			<div
				css={{
					display: "grid",
					gap: 10,
					gridTemplateColumns: `${availableWidth * 1}px`,
					gridTemplateRows: `${availableHeight * 0.5}px  ${
						availableHeight * 0.4
					}px ${availableHeight * 0.4}px`,
					[mediaQueries[2]]: {
						gridTemplateColumns: `${availableWidth * 0.5}px ${
							availableWidth * 0.5
						}px`,
						gridTemplateRows: `${availableHeight * 0.5}px ${
							availableHeight * 0.5
						}px`,
					},
				}}
			>
				<MobileDevices
					additionalCSS={{ [mediaQueries[2]]: { gridColumn: "span 2" } }}
				/>
				<Browsers />
				<OperatingSystems />
			</div>
		</div>
	);
}
