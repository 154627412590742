import { useMemo } from "react";
import random from "lodash/random";

import { darkGray } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";

import UserAvatar from "./UserAvatar";

import { WELCOME_MESSAGES } from "../../data/static/phrases";
import { useAtomValue } from "jotai";
import { supabaseUserAtom } from "../../data/analytics";

export default function UserGreeting() {
	const user = useAtomValue(supabaseUserAtom);

	const welcomeMessage = useMemo(
		() => WELCOME_MESSAGES[random(0, WELCOME_MESSAGES.length - 1)],
		[],
	);

	return (
		<div id="userGreeting">
			<UserAvatar />

			{/* <div css={{ paddingLeft: 30, paddingRight: 30 }}>
				<p
					css={{
						fontFamily: PRIMARY_FONT,
						fontSize: 24,
						fontWeight: 800,
						color: darkGray,
						marginTop: 15,
						marginBottom: 0,
					}}
				>
					Hi {user?.user_metadata.firstName}.
				</p>
				<p
					css={{
						fontFamily: PRIMARY_FONT,
						fontWeight: 500,
						fontSize: 14,
						color: darkGray,
						marginTop: 0,
						marginBottom: 0,
					}}
				>
					{welcomeMessage}
				</p>
			</div> */}
		</div>
	);
}
