import { useAtomValue } from "jotai";

import DataHighlightBox from "../../charts/DataHighlightBox";
import {
	AS_goalAchievementObservationsAtom,
	AS_percentOfExperiencesWithGoalCompletionsAtom,
} from "../../../data/analytics";
import { mixColorFamilies, portalColors } from "../../../style";

export default function GoalAchievementSummary({
	dataRefreshing,
}: {
	dataRefreshing: boolean;
}) {
	const goalAchievements = useAtomValue(AS_goalAchievementObservationsAtom);
	const percentageOfExperiencesWithGoalCompletions = useAtomValue(
		AS_percentOfExperiencesWithGoalCompletionsAtom,
	);

	return (
		<>
			<DataHighlightBox
				primaryText={goalAchievements.length}
				secondaryText={"Goal Achievements"}
				colorFamily={portalColors.red}
				dataRefreshing={dataRefreshing}
			/>
			<DataHighlightBox
				primaryText={`${percentageOfExperiencesWithGoalCompletions}%`}
				secondaryText={"Started Experiences with 1 or more completed goals"}
				colorFamily={mixColorFamilies(
					portalColors.red,
					portalColors.gray,
					0.66,
				)}
				dataRefreshing={dataRefreshing}
			/>
		</>
	);
}
