import { useAtomValue } from "jotai";

// Components
import ContinuousBarChart from "../../charts/Bar/ContinuousBarChart";

// Data
import { LS_UsersByEnrollmentDateAtom } from "../../../data/learningSystem/users";

import { refreshLearningSystemDataAtom } from "../../../data/learningSystem";

// Definitions
import { mixColorFamilies, portalColors } from "../../../style/colors";

export default function UserEnrollmentByDate() {
	const dataRefreshing = useAtomValue(refreshLearningSystemDataAtom);
	const enrollmentsByDate = useAtomValue(LS_UsersByEnrollmentDateAtom);

	return (
		<ContinuousBarChart
			dataColors={[portalColors.blue.mid, portalColors.green.mid]}
			observations={enrollmentsByDate}
			dataRefreshing={dataRefreshing}
			axes={{ observationLabel: "bottom" }}
			colorTheme={mixColorFamilies(portalColors.blue, portalColors.gray, 0.9)}
			chartTitle={{
				text: "Detail on new user enrollments during the selected timeframe",
				callOut: "User Enrollments",
			}}
			tooltip={{ title: "User Enrollments" }}
		/>
	);
}
