import { Suspense } from "react";

import { mixColorFamilies, portalColors } from "../../../style/colors";
import FinalNodeDistribution from "./FinalNodeDistribution";
import NodeDistribution from "./NodeDistributionByStep";
import { mediaQueries } from "../../../style";
import { useAvailableWidth } from "../../../data/hooks/useAvailableWidth";

export type NodeLevelDetailProps = {
	availableHeight: number;
};

export default function NodeLevelDetail({
	availableHeight,
}: NodeLevelDetailProps) {
	const availableWidth = useAvailableWidth(60);

	return (
		<Suspense fallback={<div />}>
			<div
				css={{
					boxSizing: "border-box",
					display: "grid",
					gap: 10,
					marginTop: 10,
					gridTemplateRows: `${availableHeight * 1.5}px ${
						availableHeight * 0.75
					}px`,
					gridTemplateColumns: availableWidth,
					[mediaQueries[2]]: {
						gridTemplateRows: `repeat(2, ${availableHeight * 0.75}px)`,
					},
				}}
			>
				<div
					css={{
						display: "grid",
						gap: 10,
						gridTemplateRows: "repeat(2 ,calc(50% - 5px))",
						gridTemplateColumns: "100%",
						[mediaQueries[2]]: {
							gridTemplateRows: "100%",
							gridTemplateColumns: "repeat(2 ,calc(50% - 5px))",
						},
					}}
				>
					<NodeDistribution
						colors={[
							portalColors.blue.mid,
							mixColorFamilies(portalColors.purple, portalColors.gray, 0.5).mid,
						]}
						staticStep={1}
						chartTitleProps={{
							text: "First Node View by Users",
							callOut: "Entry Node Breakdown",
							color: portalColors.blue.dark,
						}}
					/>
					<FinalNodeDistribution
						colors={[portalColors.green.dark, portalColors.yellow.mid]}
					/>
				</div>
				<NodeDistribution
					colors={[portalColors.red.mid, portalColors.orange.mid]}
				/>
			</div>
		</Suspense>
	);
}
