import { SpringValue, animated, useSpring } from "@react-spring/web";
import { Property } from "csstype";

type DividerProps = {
	animationSpring: { backgroundColor: SpringValue<Property.Color> };
};

export default function Divider({ animationSpring }: DividerProps) {
	return (
		<animated.div
			style={animationSpring}
			css={{ flexBasis: 15 }}
			id="divider"
		/>
	);
}
