import { useRef, useState } from "react";
import { SpringValue, animated } from "@react-spring/web";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Property } from "csstype";

import InputField from "../widgets/InputField";
import SquareEdgeButton from "../widgets/SquareEdgeButton";
import { mediaQueries } from "../../style/breakpoints";
import {
	isCurrentUserAdmin,
	supabaseAuthErrorAtom,
	supabaseClientAtom,
	supabaseSessionAtom,
	supabaseUserAtom,
	supabaseUserIsAdminAtom,
} from "../../data/analytics";
import { authenticationStateAtom } from "../../data/general";

type UserLoginProps = {
	animationSpring: { backgroundColor: SpringValue<Property.Color> };
};

export default function UserLogin({ animationSpring }: UserLoginProps) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loginInProgress, setLoginInProgress] = useState(false);

	const [authenticationState, setAuthenticationState] = useAtom(
		authenticationStateAtom,
	);
	const client = useAtomValue(supabaseClientAtom);
	const setUser = useSetAtom(supabaseUserAtom);
	const setUserAdmin = useSetAtom(supabaseUserIsAdminAtom);
	const setUserSession = useSetAtom(supabaseSessionAtom);
	const setSupabaseAuthError = useSetAtom(supabaseAuthErrorAtom);

	const attemptLogin = async () => {
		if (loginInProgress) return;

		setLoginInProgress(true);

		try {
			const response = await client.auth.signInWithPassword({
				email: username,
				password: password,
			});

			resetForm();

			if (response.data.user && response.data.session) {
				setUser(response.data.user);
				setUserSession(response.data.session);
				setAuthenticationState("launchNavigator");

				// Determine if the user is an admin or not.
				const userIsAdmin = await isCurrentUserAdmin();
				setUserAdmin(userIsAdmin);
			} else {
				setSupabaseAuthError(response.error?.message ?? "Unexpected error.");
			}
		} catch (error: any) {
			console.log(error);
			setSupabaseAuthError("Unexpected network error. Try again.");
		}

		setLoginInProgress(false);
	};

	const renderInputFields = () => (
		<form
			onSubmit={(event) => {
				event.preventDefault();
				attemptLogin();
			}}
			css={[
				{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					alignItems: "flex-start",
				},
			]}
		>
			<div
				css={{
					marginBottom: 20,
					display: "flex",
					flexDirection: "column",
					[mediaQueries[0]]: {
						flexDirection: "row",
						marginBottom: 15,
					},
				}}
			>
				<>
					<InputField
						label="Username / Email"
						containerStyles={{
							marginRight: 20,
							marginBottom: 10,
							[mediaQueries[0]]: {
								marginBottom: 0,
							},
						}}
						value={username}
						onValueChange={setUsername}
					/>
					<InputField
						type="password"
						label="Password"
						value={password}
						onValueChange={setPassword}
					/>
				</>
			</div>

			<SquareEdgeButton
				label="SUBMIT"
				animatedStyles={animationSpring}
				type="submit"
				disabled={!username || !password || loginInProgress}
			/>
		</form>
	);

	const resetForm = () => {
		setUsername("");
		setPassword("");
		setSupabaseAuthError(null);
	};

	return (
		<animated.div
			css={{
				flexDirection: "column",
				alignItems: "flex-start",
				justifyContent: "flex-end",
				boxSizing: "border-box",
				paddingBottom: 35,
				display: authenticationState === "login" ? "flex" : "none",
				opacity: authenticationState === "login" ? 1 : 0,
			}}
		>
			{renderInputFields()}
		</animated.div>
	);
}
