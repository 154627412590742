import { atom } from "jotai";

import { experiencesFilteredByEnclosingURLAtom } from ".";
import { groupByDate } from "../../../utils/groupBy";

/** Started experiences for the current project. */
export const startedExperiencesAtom = atom((get) => {
	const filteredExperiences = get(experiencesFilteredByEnclosingURLAtom);
	return filteredExperiences.filter((experience) => experience.data.started_at);
});

/** Loaded experiences grouped by date. */
export const startedExperiencesGroupedByDateAtom = atom((get) => {
	const startedExperiences = get(startedExperiencesAtom);
	return groupByDate(startedExperiences, "started_at");
});

/** What is the average engagement time for current set of experiences? */
export const averageExperienceEngagementTimeAtom = atom((get) => {
	const experienceSummaries = get(startedExperiencesAtom);

	// Get the total amount of verified engagment time across all records.
	const totalEngagementTime = experienceSummaries.reduce(
		(previous, next) => previous + (next.data.seconds_in_experience ?? 0),
		0,
	);

	return totalEngagementTime
		? totalEngagementTime / experienceSummaries.length / 60
		: 0;
});

/** What is the average # of nodes visited for current set of experiences? */
export const averageExperienceNodesVisitedAtom = atom((get) => {
	const experienceSummaries = get(startedExperiencesAtom);

	// Get the total amount of verified engagment time across all records.
	const nodesVisited = experienceSummaries.reduce(
		(previous, next) => previous + (next.data.nodes_visited ?? 0),
		0,
	);

	return nodesVisited ? nodesVisited / experienceSummaries.length : 0;
});
