import { useAtomValue } from "jotai";

import { mixColorFamilies, portalColors } from "../../../style/colors";
import DataHighlightBox from "../../charts/DataHighlightBox";

import {
	refreshAnalyticsSystemDataAtom,
	startedExperienceRatiosAtom,
} from "../../../data/analytics";

import { useMemo } from "react";
import { sum } from "lodash";
import { SerializedStyles, css } from "@emotion/react";
import { mediaQueries } from "../../../style";

type MobileDesktopCalloutsProps = {
	additionalCSS?: SerializedStyles;
};

/**
 * Provide the following summary project statistics:
 * 1. Average minutes of engagement for started experiences.
 * 2. Average nodes visited for started experiences.
 * 3. Total Number of Started Experiences
 * 4. Total Number of Goal Achievement Events
 */
export default function MobileDesktopCallouts({
	additionalCSS = css(),
}: MobileDesktopCalloutsProps) {
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);
	const startedExperienceRatios = useAtomValue(startedExperienceRatiosAtom);

	const { desktopStartRate, mobileStartRate } = useMemo(() => {
		const dailyStartRates = {
			desktop: [] as number[],
			mobile: [] as number[],
		};

		for (const dateObservation of startedExperienceRatios) {
			dailyStartRates.desktop.push(dateObservation.data.desktop);
			dailyStartRates.mobile.push(dateObservation.data.mobile);
		}

		return {
			desktopStartRate:
				sum(dailyStartRates.desktop) / dailyStartRates.desktop.length,
			mobileStartRate:
				sum(dailyStartRates.mobile) / dailyStartRates.mobile.length,
		};
	}, [startedExperienceRatios]);

	return (
		<div
			css={[
				{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gridTemplateRows: "1fr",
					gridAutoFlow: "row",
					gap: 10,
					height: "100%",
					width: "100%",
					boxSizing: "border-box",

					[mediaQueries[2]]: {
						gridTemplateColumns: "1fr",
						gridTemplateRows: "1fr 1fr",
					},
				},
				additionalCSS,
			]}
		>
			<DataHighlightBox
				primaryText={`${(desktopStartRate * 100).toFixed(0)}%`}
				secondaryText={"Desktop Start Rate"}
				colorFamily={mixColorFamilies(
					portalColors.blue,
					portalColors.gray,
					0.5,
				)}
				dataRefreshing={dataRefreshing}
			/>
			<DataHighlightBox
				primaryText={`${(mobileStartRate * 100).toFixed(0)}%`}
				secondaryText={"Mobile Start Rate"}
				colorFamily={mixColorFamilies(
					portalColors.orange,
					portalColors.gray,
					0.5,
				)}
				dataRefreshing={dataRefreshing}
			/>
		</div>
	);
}
