import { ReactNode, useMemo, useState } from "react";
import { animated, useSprings } from "@react-spring/web";

// Assets
import Info from "../assets/svg/icons/info.svg?react";

import { OBSERVATION_GENERIC } from "../types/charts";
import { CORE_CHART_PROPS } from "../types/charts/props";

import ChartTitle from "./charts/ChartTitle";
import { PRIMARY_FONT } from "../style";

export default function ChartContainer({
	colorTheme,
	margins,
	transparentBackground,
	chartTitle,
	chartExplanation,
	observer,
	titleObserver,
	chartActions = [],
	additionalCSS = {},
	children,
}: Pick<
	Required<CORE_CHART_PROPS<OBSERVATION_GENERIC>>,
	"colorTheme" | "margins" | "transparentBackground" | "chartTitle"
> &
	Pick<
		CORE_CHART_PROPS<OBSERVATION_GENERIC>,
		"chartExplanation" | "chartActions" | "additionalCSS"
	> & {
		observer: (element?: HTMLElement | null | undefined) => void;
		titleObserver: (element?: HTMLElement | null | undefined) => void;
		children: ReactNode;
	}) {
	const [explanationActive, setExplanationActive] = useState(false);

	const [explanationSpring, explanationSpringAPI] = useSprings(
		2,
		[
			{
				from: { transform: "scaleY(0)", opacity: 0 },
				to: explanationActive
					? { transform: "scaleY(1)", opacity: 1 }
					: { transform: "scaleY(0)", opacity: 0 },
				config: { friction: 15 },
			},
			{
				from: explanationActive ? { opacity: 0 } : { opacity: 1 },
				to: explanationActive ? { opacity: 1 } : { opacity: 0 },
				delay: explanationActive ? 500 : 0,
			},
		],
		[explanationActive],
	);

	const standardizedExplanations = useMemo(
		() =>
			chartExplanation === undefined
				? undefined
				: Array.isArray(chartExplanation)
				  ? chartExplanation
				  : [chartExplanation],
		[chartExplanation],
	);

	const renderExplanation = () =>
		standardizedExplanations && (
			<animated.div
				style={explanationSpring[0]}
				css={{
					margin: 0,
					fontFamily: PRIMARY_FONT,
					color: colorTheme.dark,
					fontWeight: 500,
					fontSize: 11,
					position: "absolute",
					right: margins.horizontal + 40,
					top: margins.vertical,
					maxWidth: "40%",
					backgroundColor: "white",
					padding: 10,
					borderRadius: 2,
					transformOrigin: "top",
					zIndex: 100,
					display: "grid",
					gap: 10,
				}}
			>
				{standardizedExplanations.map((paragraph, index) => (
					<animated.span
						key={`Chart Explanation ${index}`}
						style={explanationSpring[1]}
					>
						{paragraph}
					</animated.span>
				))}
			</animated.div>
		);

	const renderChartActions = () => {
		if (!chartActions.length) return null;

		return (
			<div css={{ display: "flex", marginTop: 10, gap: 7 }}>
				{chartActions.map((action) => (
					<div
						css={{
							fontFamily: PRIMARY_FONT,
							color: colorTheme.mid,
							backgroundColor: "white",
							padding: 7,
							borderRadius: 2,
							fontSize: 11,
							fontWeight: 600,
							cursor: "pointer",
							transition: "all .33s ease",
							":hover": {
								backgroundColor: colorTheme.mid,
								color: "white",
							},
							":active": {
								backgroundColor: colorTheme.dark,
								color: "white",
							},
						}}
						onClick={action.onClick}
					>
						{action.name}
					</div>
				))}
			</div>
		);
	};

	return (
		<div
			ref={observer}
			css={{
				backgroundColor: transparentBackground
					? "transparent"
					: colorTheme.light,
				paddingLeft: margins.horizontal,
				paddingRight: margins.horizontal,
				paddingTop: margins.vertical,
				paddingBottom: margins.vertical,
				boxSizing: "border-box",
				width: "100%",
				height: "100%",
				position: "relative",
				borderRadius: 5,
				...additionalCSS,
			}}
		>
			{renderExplanation()}
			<div
				id="chart-header"
				ref={titleObserver}
				css={{ paddingBottom: 20, boxSizing: "border-box" }}
			>
				<div css={{ display: "flex", justifyContent: "space-between" }}>
					<ChartTitle
						callOut={chartTitle.callOut}
						text={chartTitle.text}
						color={colorTheme.dark}
						containerCSS={{
							boxSizing: "border-box",
							paddingRight: 20,
							fontSize: 26,
							textTransform: "capitalize",
							flex: 1,
						}}
					/>
					{chartExplanation && (
						<Info
							width={32}
							height={32}
							onMouseOver={() => setExplanationActive(true)}
							onMouseOut={() => setExplanationActive(false)}
							css={{
								fill: colorTheme.mid,
								backgroundColor: colorTheme.light,
								padding: 5,
								borderRadius: 2,
								transition: "all .33s ease",
								":hover": {
									backgroundColor: colorTheme.mid,
									fill: "white",
								},
							}}
						/>
					)}
				</div>
				{renderChartActions()}
			</div>
			{children}
		</div>
	);
}
