import { atom } from "jotai";
import { createClient } from "@supabase/supabase-js";
import { AtlasEngineDatabase } from "@form-films/atlas-engine-shared-definitions";

const supabaseClientAtom = atom(
	createClient<AtlasEngineDatabase>(
		import.meta.env.VITE_SUPABASE_URL,
		import.meta.env.VITE_SUPABASE_ANON_KEY,
	),
);

export default supabaseClientAtom;
