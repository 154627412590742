import { css } from "@emotion/react";

// Components
import ExperiencesSummaryStatistics from "./ExperienceSummaryStatistics";
import StartingPercentageByDate from "./StartedPercentageByDate";
import LoadStartRatios from "./LoadStartRatios";
import LoadedStartedExperienceCounts from "./LoadToStartRatioByDate";
import MobileDesktopCallouts from "./MobileDesktopCallouts";

// Data & Definitions
import { mediaQueries } from "../../../style";
import { useAvailableWidth } from "../../../data/hooks/useAvailableWidth";
import { useAtomValue } from "jotai";
import { AS_userDecisionHierarchyAtom } from "../../../data/analytics/events/userDecision";
import type { FeatureProps } from "../../ClientNavigator/types";

export default function GeneralPerformance({
	contentAreaHeight,
}: FeatureProps) {
	const availableWidth = useAvailableWidth(60);

	return (
		<div css={{ boxSizing: "border-box" }}>
			<ExperiencesSummaryStatistics
				width={availableWidth}
				height={contentAreaHeight * 0.33}
			/>
			<div
				css={{
					display: "grid",
					gap: 10,
					marginTop: 20,
					boxSizing: "border-box",
					gridTemplateRows: `${contentAreaHeight * 0.75}px ${
						contentAreaHeight * 0.75
					}px ${contentAreaHeight * 1.3}px`,
					gridTemplateColumns: `${availableWidth}px`,

					[mediaQueries[2]]: {
						gridTemplateRows: `${contentAreaHeight * 0.6}px ${
							contentAreaHeight * 0.5
						}px ${contentAreaHeight * 0.5}px`,
					},
				}}
			>
				<LoadedStartedExperienceCounts />
				<StartingPercentageByDate />
				<div
					css={{
						display: "grid",
						gridTemplateColumns: availableWidth,
						gridTemplateRows: "80% 20%",
						gap: 10,
						[mediaQueries[2]]: {
							gridTemplateColumns: `${availableWidth * 0.3}px ${
								availableWidth * 0.7 - 10
							}px`,
							gridTemplateRows: "100%",
						},
						[mediaQueries[3]]: {
							gridTemplateColumns: `${availableWidth * 0.25}px ${
								availableWidth * 0.75 - 10
							}px`,
						},
						[mediaQueries[4]]: {
							gridTemplateColumns: `${availableWidth * 0.22}px ${
								availableWidth * 0.78 - 10
							}px`,
						},
					}}
				>
					<LoadStartRatios />
					<MobileDesktopCallouts
						additionalCSS={css({ [mediaQueries[2]]: { order: -1 } })}
					/>
				</div>
			</div>
		</div>
	);
}
