import { useLearningSystem } from "@form-films/atlas-engine-learning-system";
import { atom } from "jotai";
import { supabaseSessionAtom, supabaseUserAtom } from "../analytics";

import { updateLearningSystemCourses } from "./courses";
import { updateLearningSystemUsers } from "./users";
import { updateLearningSystemEngagements } from "./engagement";
import { updateLearningSystemEngagementDetails } from "./engagementDetail";
import { updateLearningSystemUserProfiles } from "./userProfiles";
import { updateLearningSystemModules } from "./modules";

export type LearningSystemState = ReturnType<
	typeof useLearningSystem
>["getState"];

export const learningSystemAtom = atom((get) => {
	const user = get(supabaseUserAtom);
	const session = get(supabaseSessionAtom);

	if (!user || !session) return undefined;

	const learningSystem = useLearningSystem();

	learningSystem
		.getState()
		.initialize(
			import.meta.env.VITE_SUPABASE_URL,
			import.meta.env.VITE_SUPABASE_ANON_KEY,
			[],
			[],
			undefined,
		);

	learningSystem.setState({ user, session });
	return learningSystem.getState;
});

export const refreshLearningSystemDataAtom = atom<boolean, [boolean], void>(
	false,
	async (get, set, value) => {
		const existingRefresh = get(refreshLearningSystemDataAtom);
		if (existingRefresh) return;
		console.debug("Navigator: Refreshing Learning System data");

		set(refreshLearningSystemDataAtom, true);
		await Promise.all([
			updateLearningSystemCourses(get, set),
			updateLearningSystemModules(get, set),
			updateLearningSystemUsers(get, set),
			updateLearningSystemUserProfiles(get, set),
		]);

		await updateLearningSystemEngagements(get, set);
		await updateLearningSystemEngagementDetails(get, set);
		set(refreshLearningSystemDataAtom, false);
	},
);
