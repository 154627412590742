import { useAvailableWidth } from "../../../data/hooks/useAvailableWidth";
import SummaryByGoal from "./GoalsByCategory";

export type GoalAchievementProps = {
	availableHeight: number;
};

export default function GoalAchievement({
	availableHeight,
}: GoalAchievementProps) {
	const availableWidth = useAvailableWidth(60);

	return (
		<div
			css={{
				boxSizing: "border-box",
				width: availableWidth,
				marginTop: 10,
				marginBottom: 50,
			}}
		>
			<SummaryByGoal width={availableWidth} height={availableHeight} />
		</div>
	);
}
