import { useEffect } from "react";
import { useAtomValue } from "jotai";
import { useSpring, animated } from "@react-spring/web";

import { darkGray, errorRED, portalColors } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";
import { mediaQueries } from "../../style/breakpoints";
import Billboard from "./Billboard";
import UserControlsPanel from "./UserControlsPanel";
import { supabaseAuthErrorAtom } from "../../data/analytics";
import { authenticationStateAtom } from "../../data/general";
import Divider from "./Divider";

export default function UserAuthenticationUI() {
	const authenticationState = useAtomValue(authenticationStateAtom);

	const [backgroundColorSprings, backgroundColorSpringsAPI] = useSpring(() => ({
		from: {
			backgroundColor: portalColors.orange.mid,
			backgroundColorLight: portalColors.orange.light,
		},
		to: [
			{
				backgroundColor: portalColors.purple.mid,
				backgroundColorLight: portalColors.purple.light,
			},
			{
				backgroundColor: portalColors.green.mid,
				backgroundColorLight: portalColors.green.light,
			},
			{
				backgroundColor: portalColors.magenta.mid,
				backgroundColorLight: portalColors.magenta.light,
			},
			{
				backgroundColor: portalColors.blue.mid,
				backgroundColorLight: portalColors.blue.light,
			},
			{
				backgroundColor: portalColors.orange.mid,
				backgroundColorLight: portalColors.orange.light,
			},
		],
		config: { friction: 75 },
		loop: true,
	}));

	const [componentAnim, componentAnimAPI] = useSpring(() => ({
		from: { opacity: 1 },
		config: { friction: 30, tension: 300, mass: 2 },
	}));

	useEffect(() => {
		if (authenticationState === "launchNavigator") {
			componentAnimAPI.start({ opacity: 0, delay: 1000 });
			backgroundColorSpringsAPI.stop();
		} else {
			componentAnimAPI.start({ opacity: 1 });
			backgroundColorSpringsAPI.start();
		}
	}, [authenticationState, componentAnimAPI]);

	const authError = useAtomValue(supabaseAuthErrorAtom);
	const [errorMessageAnim, errorMessageAnimAPI] = useSpring(() => ({
		from: { height: 0 },
	}));

	useEffect(() => {
		authError
			? errorMessageAnimAPI.start({
					height: 35,
			  })
			: errorMessageAnimAPI.start({ height: 0 });
	}, [authError, errorMessageAnimAPI]);

	return (
		<div
			css={{
				width: "100%",
				height: ["100vh", "-webkit-fill-available"],
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				boxSizing: "border-box",
				position: "absolute",
				zIndex: 0,
				pointerEvents:
					authenticationState === "launchNavigator" ? "none" : "all",
			}}
		>
			<animated.div
				id="authentication-dialog"
				style={componentAnim}
				css={{
					width: "100%",
					height: "100%",
					aspectRatio: "1/2",
					display: "flex",
					flexDirection: "column",
					backgroundColor: darkGray,
					justifyContent: "flex-end",
					overflow: "hidden",
					borderRadius: 2,
					[mediaQueries[1]]: {
						width: "75%",
						height: "unset",
						aspectRatio: "1/1",
						maxHeight: "80%",
					},
					[mediaQueries[2]]: {
						width: "60%",
					},
					[mediaQueries[4]]: {
						width: "40%",
					},
				}}
			>
				<Billboard />
				<animated.div
					id="errorDisplay"
					style={{
						...errorMessageAnim,
						backgroundColor: backgroundColorSprings.backgroundColorLight,
					}}
					css={{
						color: portalColors.gray.dark,
						paddingLeft: 30,
						paddingRight: 30,
						boxSizing: "border-box",
						fontFamily: PRIMARY_FONT,
						fontSize: 12,
						fontWeight: 600,
						display: "flex",
						alignItems: "center",
						textTransform: "uppercase",
					}}
				>
					{authError}
				</animated.div>

				<Divider animationSpring={backgroundColorSprings} />
				<UserControlsPanel animationSpring={backgroundColorSprings} />
			</animated.div>
		</div>
	);
}
