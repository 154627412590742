import { useCallback, useState } from "react";
import { useAtom } from "jotai";

// Assets
import Close from "../../../../../assets/svg/icons/close.svg?react";

// Data && Definitions
import {
	PRIMARY_FONT,
	mixColorFamilies,
	portalColors,
} from "../../../../../style";
import {
	EngagementFilter,
	engagementFiltersAtom,
} from "../../../../../data/learningSystem/engagement/filters";

// Utils
import { convertFromNamingConvention } from "../../../../../utils/text/namingConvention";

type TaskProps = {
	filter: EngagementFilter;
};

export default function Task({ filter }: TaskProps) {
	const [isHovered, setIsHovered] = useState(false);
	const [engagementFilters, setEngagmentFilters] = useAtom(
		engagementFiltersAtom,
	);

	const deleteFilter = useCallback(
		(filter: EngagementFilter) => {
			setEngagmentFilters((engagementFilters) =>
				engagementFilters.filter((engagementFilter) => {
					if (engagementFilter.entity === "task" && filter.entity === "task") {
						return engagementFilter.task.name === filter.task.name
							? false
							: true;
					}
					return true;
				}),
			);
		},
		[engagementFilters],
	);

	if (filter.entity !== "task") return null;
	return (
		<div
			css={{
				cursor: "default",
				background: mixColorFamilies(
					filter.task.completed ? portalColors.green : portalColors.orange,
					portalColors.gray,
					0.5,
				).light,
				padding: 10,
				borderRadius: 5,
				margin: 0,
				fontFamily: PRIMARY_FONT,
				position: "relative",
				display: "flex",
				flexDirection: "column",
				gap: 5,
				alignItems: "flex-start",
			}}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<Close
				role="button"
				fill={
					filter.task.completed
						? portalColors.green.dark
						: portalColors.orange.dark
				}
				width={10}
				height={10}
				viewBox="0 0 20 20"
				css={{
					position: "absolute",
					top: 5,
					right: 5,
					display: isHovered ? "initial" : "none",
					cursor: "pointer",
				}}
				onClick={() => deleteFilter(filter)}
			/>
			<div
				css={{
					color: filter.task.completed
						? portalColors.green.dark
						: portalColors.orange.dark,
					textTransform: "uppercase",
					fontSize: 11,
				}}
			>
				<span
					css={{
						color: filter.task.completed
							? portalColors.green.mid
							: portalColors.orange.mid,
						textTransform: "uppercase",
						fontWeight: 600,
						fontSize: 9,
						marginBottom: -1,
						display: "block",
					}}
				>
					Task
				</span>
				{`${convertFromNamingConvention(filter.task.name)}`}
			</div>
			<div
				css={{
					padding: 5,
					borderRadius: 2,
					color: "white",
					backgroundColor: filter.task.completed
						? portalColors.green.mid
						: portalColors.orange.mid,
					fontSize: 10,
				}}
			>
				{filter.task.completed ? "COMPLETE" : "INCOMPLETE"}
			</div>
		</div>
	);
}
