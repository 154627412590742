import { useMemo, useState } from "react";
import { Property } from "csstype";

import { useAtomValue } from "jotai";

import TreemapChart from "../../charts/Treemap";
import { AS_nodeEntryObservationAtom } from "../../../data/analytics/events";
import { ChartTitleProps } from "../../charts/ChartTitle";
import { PRIMARY_FONT } from "../../../style/fonts";
import { HIERARCHY_OBSERVATION } from "../../../types/charts";
import { refreshAnalyticsSystemDataAtom } from "../../../data/analytics";

export type NodeDistributionByStepProps = {
	colors: [Property.Color, Property.Color];
} & (
	| {
			staticStep: number;
			chartTitleProps: ChartTitleProps;
	  }
	| { staticStep?: never; chartTitleProps?: never }
);

export default function NodeDistributionByStep({
	colors,
	staticStep,
	chartTitleProps,
}: NodeDistributionByStepProps) {
	const nodeEntryEvents = useAtomValue(AS_nodeEntryObservationAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	const [selectedStep, setSelectedStep] = useState<"all" | number>(
		staticStep ?? "all",
	);

	const preparedData = useMemo<HIERARCHY_OBSERVATION[]>(() => {
		const preStratifiedData = nodeEntryEvents.reduce<HIERARCHY_OBSERVATION[]>(
			(previous, current) => {
				/**
				 * If the user has selected a specific experience step to review
				 * ignore data from all other steps.
				 * */
				if (selectedStep !== "all" && current.data.data.order !== selectedStep)
					return previous;

				/** Determine if the current browser already has a record. */
				const existingRecord = previous.find(
					(record) =>
						record.observationLabel ===
						current.data.associated_node_name?.replaceAll(/[_-]/g, " "),
				);

				// If there is an existing record, augment it's count.
				// Otherwise, create it.
				if (existingRecord) {
					const filteredRecords = [...previous].filter(
						(entry) =>
							entry.observationLabel !==
							current.data.associated_node_name?.replaceAll(/[_-]/g, " "),
					);
					filteredRecords.push({
						...existingRecord,
						data: {
							...existingRecord.data,
							count: (existingRecord.data.count ?? 0) + 1,
						},
					});
					return filteredRecords;
				}
				const newEntryReduction = [...previous];
				newEntryReduction.push({
					observationLabel: current.data.associated_node_name
						? current.data.associated_node_name.replaceAll(/[_-]/g, " ")
						: "Unknown",
					data: {
						parent: "root",
						count: 1,
					},
				});
				return newEntryReduction;
			},
			[],
		);

		// preStratifiedData.splice(15);

		const augmentedRootNode = [
			{ observationLabel: "root", data: { parent: null } },
			...preStratifiedData,
		];

		return augmentedRootNode;
	}, [nodeEntryEvents, selectedStep]);

	const incrementStep = () => {
		selectedStep === "all"
			? setSelectedStep(1)
			: setSelectedStep((previous) => (previous as number) + 1);
	};

	const decrementStep = () => {
		if (selectedStep === "all") return;
		selectedStep === 1
			? setSelectedStep("all")
			: setSelectedStep((previous) => (previous as number) - 1);
	};

	return (
		<>
			<TreemapChart
				dataRefreshing={dataRefreshing}
				dataColors={colors}
				observations={preparedData}
				depthToChart={1}
				chartTitle={
					staticStep
						? chartTitleProps
						: {
								callOut:
									typeof selectedStep === "string"
										? "Node Breakdown for All Steps"
										: `Node Breakdown: Step ${selectedStep}`,
								text:
									typeof selectedStep === "string"
										? "Relative Popularity of Nodes for Entire Experience"
										: "Relative Popularity of Nodes for this Step in the Experience",
								color: colors[0],
						  }
				}
			/>

			{!staticStep && (
				<div
					css={{ display: "flex", flexDirection: "row", alignItems: "center" }}
				>
					<button
						type="button"
						css={{
							fontFamily: PRIMARY_FONT,
							fontWeight: 700,
							fontSize: 14,
							backgroundColor: "transparent",
							border: "none",
							outline: "none",
							padding: 15,
						}}
						onClick={decrementStep}
					>
						{"<"}
					</button>
					<span>
						{selectedStep === "all" ? "All Steps" : `Step ${selectedStep}`}
					</span>
					<button
						type="button"
						css={{
							fontFamily: PRIMARY_FONT,
							fontWeight: 700,
							fontSize: 14,
							backgroundColor: "transparent",
							border: "none",
							outline: "none",
							padding: 15,
						}}
						onClick={incrementStep}
					>
						{">"}
					</button>
				</div>
			)}
		</>
	);
}
