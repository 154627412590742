import { AlbersUsa } from "@visx/geo";
import { geoCentroid } from "d3-geo";
import { scaleLinear } from "@visx/scale";
import { useTooltip } from "@visx/tooltip";
import { animated, useSprings } from "@react-spring/web";
import * as topojson from "topojson-client";

import usTopoJson from "../../../assets/geojson/usTopoJson.json";

import {
	GeoJsonCountyFeature,
	GeoJsonStateFeature,
} from "../../../assets/geojson/types";
import { Fragment, useEffect, useMemo, useState } from "react";

import { portalColors } from "../../../style";
import { COMMON_CHART_PROPS } from "../../../types/charts/props";
import { OBSERVATION_WITH_SINGLE_NUMERIC_VALUE } from "../../../types/charts";
import useObserveChartComponents from "../../../data/hooks/useObserveChartComponents";
import useSVGDimensions from "../../../hooks/useSVGDimensions";
import ChartContainer from "../../ChartContainer";
import useFlattenSeriesValues from "../../../data/hooks/useFlattenSeriesValues";
import Tooltip from "../../widgets/Tooltip";

interface FeatureShape {
	type: "Feature";
	id: string;
	geometry: { coordinates: [number, number][][]; type: "Polygon" };
	properties: { name: string };
}

export default function AlbersUsaChart({
	margins = { vertical: 25, horizontal: 25 },
	observations,
	dataRefreshing,
	dataColors,
	chartTitle,
	chartExplanation,
	chartActions,
	chartOptions,
	colorTheme = portalColors.gray,
	transparentBackground = false,
	dataFormatter = (data) => data.toString(),
	tooltip,
}: COMMON_CHART_PROPS<
	OBSERVATION_WITH_SINGLE_NUMERIC_VALUE,
	{
		detailLevel: {
			value: "state" | "county";
			updateTo: (newValue: "state" | "county") => void;
		};
		selectedState: {
			value: { name: string; id: string } | null;
			updateTo: (newValue: { name: string; id: string } | null) => void;
		};
	}
>) {
	// Tooltip Artifacts
	const {
		tooltipData,
		tooltipLeft,
		tooltipTop,
		tooltipOpen,
		showTooltip,
		hideTooltip,
	} = useTooltip<OBSERVATION_WITH_SINGLE_NUMERIC_VALUE>();

	const {
		observeComponent,
		componentWidth,
		componentHeight,
		observeTitle,
		titleHeight,
		legendHeight,
	} = useObserveChartComponents();

	// Determine the effective dimensions for the actual SVG element.
	const svgDimensions = useSVGDimensions({
		width: componentWidth,
		height: componentHeight,
		margins,
		heightSubstractors: [titleHeight, legendHeight],
	});

	const allSeriesValues = useFlattenSeriesValues(observations, [
		"value",
	]) as number[];

	const colorScale = useMemo(() => {
		return scaleLinear({
			domain: [Math.min(...allSeriesValues), Math.max(...allSeriesValues)],
			range: dataColors,
		});
	}, [dataColors, allSeriesValues]);

	const typeSafeTopology = usTopoJson as unknown as TopoJSON.Topology;

	const topology = topojson.feature(
		typeSafeTopology,
		chartOptions?.detailLevel.value === "county"
			? typeSafeTopology.objects.counties
			: typeSafeTopology.objects.states,
	) as {
		type: "FeatureCollection";
		features: FeatureShape[];
	};

	const finalTopology = useMemo(
		() =>
			chartOptions?.selectedState.value
				? {
						...topology,
						features: [
							...topology.features.filter((feature) =>
								feature.id.startsWith(chartOptions?.selectedState.value?.id!),
							),
						],
				  }
				: topology,
		[topology, chartOptions?.selectedState],
	);

	const [dataRefreshSpring, dataRefreshSpringAPI] = useSprings(
		finalTopology.features.length,
		(index) => ({
			from: { opacity: 0.2, filter: "saturate(0)" },
			delay: 1000,
		}),
		[finalTopology.features.length],
	);

	// Animations for Data Refreshing
	// biome-ignore lint/correctness/useExhaustiveDependencies: Intentional
	useEffect(() => {
		dataRefreshing
			? dataRefreshSpringAPI.start((springIndex) => ({
					to: { opacity: 0.2, filter: "saturate(0)" },
					delay: (1000 / finalTopology.features.length) * springIndex,
			  }))
			: dataRefreshSpringAPI.start((springIndex) => ({
					to: { opacity: 1.0, filter: "saturate(1)" },
					delay: (1000 / finalTopology.features.length) * springIndex,
			  }));
	}, [dataRefreshing]);

	// Animations for Filtering / View Switch
	// biome-ignore lint/correctness/useExhaustiveDependencies: Intentional
	useEffect(() => {
		!dataRefreshing &&
			dataRefreshSpringAPI.start((springIndex) => ({
				to: [
					{
						to: { opacity: 0.0, filter: "saturate(0)" },
						immediate: true,
					},
					{
						to: { opacity: 1, filter: "saturate(1)" },
						delay: (1000 / finalTopology.features.length) * springIndex + 1000,
					},
				],
			}));
	}, [observations.length]);

	return (
		<ChartContainer
			colorTheme={colorTheme}
			margins={margins}
			transparentBackground={transparentBackground}
			chartTitle={{ ...chartTitle }}
			chartExplanation={chartExplanation}
			observer={observeComponent}
			titleObserver={observeTitle}
			chartActions={chartActions}
		>
			{tooltipOpen && tooltipData && (
				<Tooltip
					positioning={{ top: tooltipTop, left: tooltipLeft }}
					title={tooltip?.title ?? "Record Details"}
					subtitle={tooltipData.observationLabel as string}
					additionalData={tooltipData.data}
					additionalDataFormatter={dataFormatter}
					observationColor={colorScale(tooltipData.data.value)}
				/>
			)}
			<svg
				viewBox={`0 0 ${svgDimensions[0]} ${svgDimensions[1]}`}
				css={{
					width: svgDimensions[0],
					height: svgDimensions[1],
				}}
			>
				<title>Geographical Distribution of U.S. Visitors</title>
				<AlbersUsa
					data={finalTopology.features}
					// @ts-expect-error Expect some missing properties that are not used.
					fitSize={[[svgDimensions[0], svgDimensions[1]], finalTopology]}
					translate={[svgDimensions[0] / 2, svgDimensions[1] / 2]}
				>
					{({ features }) =>
						features.map(({ feature, path, projection }, index) => {
							const observation = observations.find((o) => {
								return chartOptions?.detailLevel.value === "state"
									? o.observationLabel === feature.properties.name
									: o.observationLabel.toString() === feature.id;
							});

							const coords: [number, number] | null = projection(
								geoCentroid(feature),
							);

							return (
								<Fragment key={`map-feature-${index}`}>
									<animated.path
										style={dataRefreshSpring[index]}
										onMouseOver={() =>
											observation &&
											showTooltip({
												tooltipLeft: coords?.[0],
												tooltipTop: coords?.[1],
												tooltipData: {
													...observation,
													observationLabel: feature.properties.name,
												},
											})
										}
										onMouseOut={hideTooltip}
										onClick={() => {
											if (chartOptions?.detailLevel.value === "state") {
												hideTooltip();
												chartOptions?.selectedState.updateTo({
													name: feature.properties.name,
													id: feature.id,
												});
												chartOptions.detailLevel.updateTo("county");
											}
										}}
										key={`map-feature-${index}`}
										d={path || ""}
										fill={
											observation
												? colorScale(observation.data.value)
												: colorTheme.mid
										}
										stroke={colorTheme.light}
										strokeWidth={1}
									/>
								</Fragment>
							);
						})
					}
				</AlbersUsa>
			</svg>
		</ChartContainer>
	);
}
