import { css } from "@emotion/react";
import { useAtom } from "jotai";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import CalendarIcon from "../../assets/svg/icons/calendar.svg?react";
import { selectedDateRangeAtom } from "../../data/dateTime";
import { darkGray, midGray, PRIMARY_FONT } from "../../style";

export default function DateRangeSelector() {
	const [dateRange, setDateRange] = useAtom(selectedDateRangeAtom);
	const [fromDatePickerOpen, setFromDatePickerOpen] = useState(false);
	const [toDatePickerOpen, setToDatePickerOpen] = useState(false);

	const buttonCSS = css({
		border: "none",
		outline: "none",
		backgroundColor: "transparent",
		fontFamily: PRIMARY_FONT,
		color: darkGray,
		fontWeight: 600,
	});

	// Convert DateTime objects to React-Calendar compatible Dates
	const nativeDatesForCalendar = useMemo(() => {
		const maximumFromDateTime = dateRange.to.minus({ days: 1 });
		const minimumToDateTime = dateRange.from.plus({ days: 1 });
		const maximumToDateTime = DateTime.now().toUTC().endOf("day");

		return {
			currentFromDate: new Date(
				dateRange.from.year,
				dateRange.from.month - 1,
				dateRange.from.day,
			),
			maximumFromDate: new Date(
				maximumFromDateTime.year,
				maximumFromDateTime.month - 1,
				maximumFromDateTime.day,
			),
			currentToDate: new Date(
				dateRange.to.year,
				dateRange.to.month - 1,
				dateRange.to.day,
			),
			minimumToDate: new Date(
				minimumToDateTime.year,
				minimumToDateTime.month - 1,
				minimumToDateTime.day,
			),
			maximumToDate: new Date(
				maximumToDateTime.year,
				maximumToDateTime.month - 1,
				maximumToDateTime.day,
			),
		};
	}, [dateRange.from, dateRange.to]);

	return (
		<div
			id="datePickers"
			css={{
				display: "grid",
				position: "relative",
				gridTemplateColumns: "max-content max-content",
				zIndex: 200,
			}}
		>
			<div
				css={{
					display: "flex",
					alignItems: "center",
					backgroundColor: darkGray,
					padding: 5,
					paddingLeft: 10,
					paddingRight: 10,
					borderTopLeftRadius: 5,
					borderBottomLeftRadius: 5,
				}}
			>
				{/* @ts-ignore */}
				<CalendarIcon css={{ fill: "white" }} />
				<span
					css={[
						buttonCSS,
						{ fontSize: 11, color: "white", fontWeight: 600, marginLeft: 5 },
					]}
				>
					DATE RANGE
				</span>
			</div>
			<div
				css={{
					display: "flex",
					padding: 5,
					borderStyle: "solid",
					borderColor: midGray,
					borderWidth: 1,
					borderTopRightRadius: 5,
					borderBottomRightRadius: 5,
					alignItems: "center",
				}}
			>
				<button
					type="button"
					css={buttonCSS}
					onClick={() => {
						setFromDatePickerOpen(!fromDatePickerOpen);
						setToDatePickerOpen(false);
					}}
				>
					{dateRange.from.toLocaleString()}
				</button>
				<span css={buttonCSS}>{" - "}</span>
				<button
					type="button"
					css={buttonCSS}
					onClick={() => {
						setFromDatePickerOpen(false);
						setToDatePickerOpen(!toDatePickerOpen);
					}}
				>
					{dateRange.to.toLocaleString()}
				</button>
			</div>
			{fromDatePickerOpen && (
				<div
					css={{
						position: "absolute",
						top: 30,
						zIndex: 10,
						maxWidth: "85vw",
					}}
				>
					<Calendar
						calendarType="gregory"
						value={nativeDatesForCalendar.currentFromDate}
						maxDate={nativeDatesForCalendar.maximumFromDate}
						onChange={(value) => {
							setDateRange({
								...dateRange,
								from: DateTime.fromJSDate(value as Date).startOf("day"),
							});
							setFromDatePickerOpen(false);
						}}
					/>
				</div>
			)}
			{toDatePickerOpen && (
				<div css={{ position: "absolute", top: 30, zIndex: 10 }}>
					<Calendar
						value={nativeDatesForCalendar.currentToDate}
						minDate={nativeDatesForCalendar.minimumToDate}
						maxDate={nativeDatesForCalendar.maximumToDate}
						onChange={(value) =>
							setDateRange({
								...dateRange,
								to: DateTime.fromJSDate(value as Date).endOf("day"),
							})
						}
					/>
				</div>
			)}
		</div>
	);
}
