import { useEffect, useMemo } from "react";
import { useTooltip } from "@visx/tooltip";
import { scaleLinear } from "@visx/scale";
import { localPoint } from "@visx/event";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { LegendLinear } from "@visx/legend";
import { AxisRight } from "@visx/axis";
import { ScaleBand } from "d3-scale";
import { animated, useTrail } from "@react-spring/web";

// Components
import GridLines from "../GridLines";
import CustomDateTimeBottomAxis from "../CustomDateTimeBottomAxis";
import Tooltip from "../../widgets/Tooltip";
import ChartContainer from "../../ChartContainer";

// Data and Definitions
import { darkGray, portalColors } from "../../../style/colors";
import { PRIMARY_FONT } from "../../../style/fonts";
import { COMMON_CHART_PROPS } from "../../../types/charts/props";
import {
	OBSERVATION_WITH_SINGLE_NUMERIC_VALUE,
	areObservationsDateTimeLabeled,
} from "../../../types/charts";

// Hooks
import useSVGDimensions from "../../../hooks/useSVGDimensions";
import useBandScale from "../../../hooks/useBandScale";
import useLinearScale from "../../../hooks/useLinearScale";
import useFlattenSeriesValues from "../../../data/hooks/useFlattenSeriesValues";
import { DateTime } from "luxon";
import useObserveChartComponents from "../../../data/hooks/useObserveChartComponents";
import { isDateTime } from "../../../utils/datesAndTimes";

/**
 * Bar chart that is used to display a single series of
 * continous data observations.
 * */
export default function ContinuousBarChart({
	margins = { vertical: 25, horizontal: 25 },
	additionalCSS,
	observations,
	dataRefreshing,
	dataColors,
	chartTitle,
	axes,
	colorTheme = portalColors.gray,
	displayLegend = false,
	transparentBackground = false,
	gridLines = "rows",
	labelFormatter = (label) => label.toString(),
	dataFormatter = (data) => data.toString(),
	tooltip,
	chartExplanation,
	chartActions,
}: COMMON_CHART_PROPS<OBSERVATION_WITH_SINGLE_NUMERIC_VALUE>) {
	const {
		observeComponent,
		componentWidth,
		componentHeight,
		observeTitle,
		observeLegend,
		titleHeight,
		legendHeight,
	} = useObserveChartComponents();

	// Determine the effective dimensions for the actual SVG element.
	const svgDimensions = useSVGDimensions({
		width: componentWidth,
		height: componentHeight,
		margins,
		heightSubstractors: [titleHeight, legendHeight],
	});

	// scales, memoize for performance
	const xScale = useBandScale({
		domain: observations.map((observation) => observation.observationLabel),
		range: [0, svgDimensions[0] - 20],
	});

	const allSeriesValues = useFlattenSeriesValues(observations, [
		"value",
	]) as number[];

	const yScale = useLinearScale({
		domain: [0, Math.max(...allSeriesValues)],
		range: [svgDimensions[1] - (axes?.observationLabel ? 40 : 0), 0],
	});

	const heightScale = useLinearScale({
		domain: [0, Math.max(...allSeriesValues)],
		range: [0, svgDimensions[1] - (axes?.observationLabel ? 40 : 0)],
	});

	const colorScale = useMemo(() => {
		return scaleLinear({
			domain: [Math.min(...allSeriesValues), Math.max(...allSeriesValues)],
			// interpolate: "hsl",
			range: dataColors,
		});
	}, [dataColors, allSeriesValues]);

	const [trails, trailsAPI] = useTrail(observations.length, () => ({
		from: { scaleY: 0, opacity: 0, filter: "saturate(0%)" },
		config: { tension: 500, friction: 30 },
	}));

	useEffect(() => {
		dataRefreshing
			? trailsAPI.start({
					to: { scaleY: 0, opacity: 0, filter: "saturate(0%)" },
					immediate: true,
			  })
			: trailsAPI.start({
					to: { scaleY: 1, opacity: 1, filter: "saturate(100%)" },
					immediate: false,
			  });
	}, [dataRefreshing, trailsAPI]);

	// Tooltip Artifacts
	const {
		tooltipData,
		tooltipLeft,
		tooltipTop,
		tooltipOpen,
		showTooltip,
		hideTooltip,
	} = useTooltip<OBSERVATION_WITH_SINGLE_NUMERIC_VALUE>();

	return (
		<ChartContainer
			colorTheme={colorTheme}
			margins={margins}
			transparentBackground={transparentBackground}
			chartTitle={{ ...chartTitle }}
			chartExplanation={chartExplanation}
			observer={observeComponent}
			titleObserver={observeTitle}
			chartActions={chartActions}
			additionalCSS={additionalCSS}
		>
			{tooltipOpen && tooltipData && (
				<Tooltip
					positioning={{ top: tooltipTop, left: tooltipLeft }}
					title={tooltip?.title ?? "Record Details"}
					subtitle={
						isDateTime(tooltipData.observationLabel)
							? tooltipData.observationLabel.toISODate()!
							: tooltipData.observationLabel
					}
					additionalData={tooltipData.data}
					additionalDataFormatter={dataFormatter}
					observationColor={colorScale(tooltipData.data.value)}
				/>
			)}

			{svgDimensions[0] && svgDimensions[1] && (
				<svg
					viewBox={`0 0 ${svgDimensions[0]} ${svgDimensions[1]}`}
					css={{
						width: svgDimensions[0],
						height: svgDimensions[1],
						overflow: "unset",
					}}
				>
					<title>Continous Bar Chart</title>
					<GridLines
						lineOption={gridLines}
						lineColor={colorTheme.mid}
						xScale={xScale}
						yScale={yScale}
						width={svgDimensions[0] - margins.horizontal}
						height={svgDimensions[1]}
					/>
					<Group name="bars">
						{observations.map((observation, index) => {
							const barX = xScale(observation.observationLabel);
							const barY = yScale(observation.data.value);

							const barWidth = xScale.bandwidth();
							const barHeight = heightScale(observation.data.value);

							return (
								<animated.g
									key={`bar-${observation.observationLabel}`}
									style={trails[index]}
									css={{ transformOrigin: "bottom" }}
								>
									<Bar
										x={barX}
										y={barY}
										width={barWidth}
										height={barHeight}
										fill={colorScale(observation.data.value)}
										onMouseOver={(event) => {
											const coords = localPoint(
												// @ts-ignore
												event.target.ownerSVGElement,
												event,
											);

											showTooltip({
												tooltipLeft: barX!,
												tooltipTop: coords!.y,
												tooltipData: observation,
											});
										}}
										onMouseOut={hideTooltip}
									/>
								</animated.g>
							);
						})}
					</Group>
					<Group name="axes">
						<AxisRight
							left={svgDimensions[0] - 7}
							// top={5}
							scale={yScale}
							hideZero
							tickLength={0}
							stroke={"transparent"}
							tickFormat={dataFormatter}
							tickStroke={darkGray}
							tickLabelProps={() => ({
								fill: colorTheme.dark,
								fontSize: 11,
								// textAnchor: "end",
								verticalAnchor: "middle",
								x: -5,
							})}
						/>
						{axes?.observationLabel === "bottom" &&
						areObservationsDateTimeLabeled(observations) ? (
							<CustomDateTimeBottomAxis
								yPosition={svgDimensions[1] - 40}
								color={colorTheme.dark}
								dateScale={xScale as ScaleBand<DateTime>}
							/>
						) : null}
					</Group>
				</svg>
			)}

			{displayLegend && (
				<div ref={observeLegend} css={{ paddingTop: 5, paddingBottom: 5 }}>
					<LegendLinear
						scale={colorScale}
						direction="column"
						labelMargin="0 0px 0 2px"
						labelFormat={(label) => label.toString()}
						shapeHeight={10}
						shapeWidth={10}
						css={{
							fontSize: 11,
							fontFamily: PRIMARY_FONT,
							textTransform: "capitalize",
							fontWeight: 500,
							color: colorTheme.dark,
						}}
					/>
				</div>
			)}
		</ChartContainer>
	);
}
