import { DateTime } from "luxon";
import { OBSERVATION_GENERIC } from "../../types/charts";
import { useMemo } from "react";
import { MULTI_SERIES_CHART_PROPS } from "../../types/charts/props";
import { ObjectWithStringKeys } from "@form-films/atlas-engine-shared-definitions";
import { pick } from "lodash";

/**
 * Flatten the data series values from an array of observations.
 * The hook accepts two arguments:
 * 1. An array of observations.
 * 2. An array of series names.
 *
 * The hook will extract all the values for the specified series
 * from the array of observations, and then flatten them into a single array.
 */
export default function useFlattenSeriesValues<
	ObservationType extends OBSERVATION_GENERIC,
>(
	observations: ObservationType[],
	seriesNames: MULTI_SERIES_CHART_PROPS<ObservationType>["seriesNames"],
) {
	return useMemo(() => {
		return observations
			.map((observation) => Object.values(pick(observation.data, seriesNames)))
			.reduce((accumulatedData, currentSeriesData) => {
				return [...accumulatedData, ...currentSeriesData];
			}, []);
	}, [observations, seriesNames]);
}
