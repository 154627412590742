import { TooltipWithBounds } from "@visx/tooltip";
import { Property } from "csstype";
import { useSpring, animated } from "@react-spring/web";

import {
	SECONDARY_FONT,
	darkGray,
	mediaQueries,
	portalColors,
} from "../../style";
import { OBSERVATION_GENERIC } from "../../types/charts";
import { DateTime } from "luxon";
import { useMemo } from "react";
import Color from "color";
import { ScaleLinear, ScaleOrdinal } from "@visx/vendor/d3-scale";
import { convertFromNamingConvention } from "../../utils/text/namingConvention";
import { ObjectWithStringKeys } from "@form-films/atlas-engine-shared-definitions";

export type TooltipProps<ObservationType extends OBSERVATION_GENERIC> = {
	/** Where should the tooltip be positioned? */
	positioning: { top?: number; left?: number };
	/** Title of the tooltip. */
	title: string;
	/** Optional. Subtitle of the tooltip. */
	subtitle?: string;
	/** Optional. Indicate a color that is used a highlight on the tooltip. */
	observationColor?: Property.Color;
	/** Optional. Additional data passed to the tooltip for rendering. */
	additionalData?: ObjectWithStringKeys & { observationLabel?: never };
	/** Optional. Color scale to render data colors inside of tooltip for additional data. */
	colorScale?: ScaleLinear<any, any, any> | ScaleOrdinal<any, any, any>;
	// | ReturnType<typeof scaleOrdinal>;
	/** Optional. Formatting function to apply to the keys of the additional data provided. */
	additionalDataLabelFormatter?: (
		label: Extract<keyof ObservationType["data"], string>,
	) => string;
	/** Optional. Formatting function to apply to the values of the additional data provided. */
	additionalDataFormatter?: (data: any) => string;
	/** Optional. Disregard additional data with falsey values. */
	additionalDataDisregardFalseyValues?: boolean;
	animationDelay?: number;
};

export default function Tooltip<
	ObservationType extends OBSERVATION_GENERIC<DateTime | string>,
>({
	positioning,
	observationColor,
	title,
	subtitle,
	additionalData,
	additionalDataLabelFormatter: labelFormatter = (label) =>
		convertFromNamingConvention(label),
	additionalDataFormatter: dataFormatter = (data) =>
		typeof data === "string" ? data.toString() : data,
	additionalDataDisregardFalseyValues = false,
	animationDelay = 0,
	colorScale,
}: TooltipProps<ObservationType>) {
	const [animatedStyles, animationAPI] = useSpring(() => ({
		from: { opacity: 0 },
		to: { opacity: 1 },
		config: { friction: 25 },
		delay: animationDelay,
	}));

	const transformedAdditionalData = useMemo(
		() =>
			Object.entries(additionalData ?? {})
				// Filter out any falsey values if requested
				.filter((entry) =>
					additionalDataDisregardFalseyValues ? entry[1] : true,
				),
		[additionalData, additionalDataDisregardFalseyValues],
	);

	const renderAdditionalDatum = () => {
		return (
			<div
				css={{
					padding: 15,
					backgroundColor: observationColor
						? Color(observationColor).lightness(90).saturationl(25).hex()
						: portalColors.gray.light,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{transformedAdditionalData.map(([label, data], index) => {
					return (
						<div
							key={`tooltip-additional-data-${index}`}
							css={{
								fontFamily: SECONDARY_FONT,
								color: Color(observationColor).hex(),
								fontSize: 18,
								fontWeight: 700,
								[mediaQueries[4]]: {
									fontSize: 22,
								},
							}}
						>
							<span css={{ flex: 1 }}>{dataFormatter(data as any)}</span>
						</div>
					);
				})}
			</div>
		);
	};

	const renderAdditionalData = () => {
		return (
			<div
				css={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gridTemplateRows: `repeat(${Math.ceil(
						transformedAdditionalData.length / 2,
					)}, 1fr)`,
					gridAutoFlow: "column",
					gap: 5,
					columnGap: 15,
					backgroundColor: portalColors.gray.light,
					padding: 15,
				}}
			>
				{transformedAdditionalData.map(([label, data], index) => {
					const typedLabel = label as Extract<
						keyof ObservationType["data"],
						string
					>;

					const colorSquare = observationColor
						? Color(observationColor).hex()
						: colorScale
						  ? // @ts-ignore
							  colorScale(label)
						  : portalColors.gray.mid;

					return (
						<div
							key={label}
							css={{
								display: "grid",
								gridTemplateColumns: "minmax(40px, max-content) 1fr",
								alignItems: "center",
								gap: 5,
								fontFamily: SECONDARY_FONT,
								opacity:
									(typeof data === "number" && !data) ||
									(typeof data === "string" && !data.length)
										? 0.25
										: 1,
							}}
						>
							<span
								css={{
									fontWeight: 800,
									padding: 5,
									borderRadius: 3,
									backgroundColor: colorSquare,
									color: "white",
									textAlign: "center",
									fontSize: 11,
									[mediaQueries[4]]: {
										fontSize: 12,
									},
								}}
							>
								{dataFormatter(data as any)}
							</span>
							<label
								css={{
									fontWeight: 500,
									textTransform: "uppercase",
									color: portalColors.gray.dark,
									lineHeight: 0.9,
									fontSize: 9,
									[mediaQueries[4]]: {
										fontSize: 11,
									},
								}}
							>
								{labelFormatter(typedLabel)}
							</label>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<TooltipWithBounds
			// set this to random so it correctly updates with parent bounds
			// key={Math.random()}
			top={positioning.top}
			left={positioning.left}
			style={{
				backgroundColor: "transparent",
				pointerEvents: "none",
				color: darkGray,
			}}
			applyPositionStyle
		>
			<animated.div
				style={animatedStyles}
				css={{
					backgroundColor: "white",
					borderRadius: 5,
					overflow: "hidden",
					border: "white solid 3px",
					maxWidth: 400,
					display: "flex",
					flexDirection:
						transformedAdditionalData.length > 1 ? "column" : "row",
				}}
			>
				<div
					id="tooltip-header"
					css={{
						backgroundColor: observationColor ?? darkGray,
						padding: 15,
						color: "white",
					}}
				>
					<div
						css={{
							fontFamily: SECONDARY_FONT,
							textTransform: "capitalize",
							fontWeight: 700,
							fontSize: 14,
							lineHeight: 0.9,
							marginBottom: 5,
							[mediaQueries[4]]: {
								fontSize: 16,
							},
						}}
					>
						{title}
					</div>
					<div
						css={{
							fontFamily: SECONDARY_FONT,
							fontSize: 11,
							textTransform: "capitalize",
							[mediaQueries[4]]: {
								fontSize: 12,
							},
						}}
					>
						{subtitle}
					</div>
				</div>

				{transformedAdditionalData.length > 1
					? renderAdditionalData()
					: renderAdditionalDatum()}
			</animated.div>
		</TooltipWithBounds>
	);
}
