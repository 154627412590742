import { useAtomValue } from "jotai";
import { animated, useSpring } from "@react-spring/web";
import { useEffect } from "react";

import { userAvatarAtom } from "../../data/analytics";
import UserInfo from "./EditableUserInfo";
import { readyForNavigatorInteractionAtom } from "../../data/general";

export default function UserAvatar() {
	const userAvatar = useAtomValue(userAvatarAtom);
	const readyForInteraction = useAtomValue(readyForNavigatorInteractionAtom);

	const [springs, springsAPI] = useSpring(() => ({
		from: {
			primaryClipPath: "polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%)",
			secondaryClipPath: "polygon(100% 70%, 100% 100%, 100% 100%, 100% 60%)",
		},
	}));

	useEffect(() => {
		if (readyForInteraction) {
			springsAPI.start({
				to: [
					{
						primaryClipPath: "polygon(0% 0%, 100% 0%, 100% 91%, 0% 70%)",
						secondaryClipPath: "polygon(0% 70%, 0% 100%, 100% 100%, 100% 60%)",
					},
				],
				delay: 1500,
				config: { friction: 50 },
			});
		}
	}, [springsAPI, readyForInteraction]);

	return (
		<div css={{ position: "relative" }}>
			<animated.img
				src={
					userAvatar instanceof Blob
						? URL.createObjectURL(userAvatar)
						: userAvatar
				}
				style={{ clipPath: springs.primaryClipPath }}
				css={{
					width: 290,
					height: 198,
					objectFit: "cover",
					left: 30,
					position: "relative",
				}}
				alt="Avatar"
			/>
			<animated.img
				src={
					userAvatar instanceof Blob
						? URL.createObjectURL(userAvatar)
						: userAvatar
				}
				style={{ clipPath: springs.secondaryClipPath }}
				css={{
					width: 290,
					height: 198,
					borderRadius: 5,
					filter: "brightness(55%)",
					objectFit: "cover",
					position: "absolute",
					top: 0,
					left: 30,
				}}
				alt="Avatar"
			/>
			<UserInfo />
		</div>
	);
}
