import { useSpring, useTransition, animated } from "@react-spring/web";
import { Group } from "@visx/group";
import { Text } from "@visx/text";

import { ProvidedProps } from "@visx/shape/lib/shapes/Pie";
import { portalColors, PRIMARY_FONT } from "../../../style";
import { PIE_CHART_OBSERVATION } from "../../../types/charts";
import { COMMON_CHART_PROPS } from "../../../types/charts/props";

export default function SegmentGenerator({
	svgDimensions,
	dataColors,
	arcs,
	path,
}: ProvidedProps<PIE_CHART_OBSERVATION> & {
	svgDimensions: readonly [number, number];
	dataColors: COMMON_CHART_PROPS<PIE_CHART_OBSERVATION>["dataColors"];
}) {
	const transitions = useTransition(arcs, {
		exitBeforeEnter: true,
		from: (item, index) => ({
			opacity: 0,
			transform: "scale(3) rotate(180deg)",
			// filter: "grayscale(100%)",
			fill: dataColors[index],
		}),
		enter: (item, index) => ({
			opacity: 1,
			transform: "scale(1) rotate(0deg)",
			// filter: "grayscale(0%)",
			fill: dataColors[index],
		}),
		leave: (item, index) => ({
			opacity: 0,
			transform: "scale(0) rotate(-180deg)",
			// filter: "grayscale(100%)",
			fill: dataColors[index],
		}),
		config: { tension: 150, friction: 35 },
		// delay: 500,
		keys: (item) => item.data.data.percentage,
	});

	return (
		<Group top={svgDimensions[1] / 2} left={svgDimensions[0] / 2}>
			{transitions((styles, item, _, index) => {
				const [centroidX, centroidY] = path.centroid(item);
				const hasSpaceForLabel = item.endAngle - item.startAngle >= 0.1;

				return (
					<animated.g key={item.data.observationLabel} style={styles}>
						<animated.path
							d={
								path({
									...item,
								})!
							}
							// style={styles}
							// fill="red"
						/>
						{hasSpaceForLabel && (
							<Group>
								<Text
									css={{ textTransform: "uppercase" }}
									x={centroidX}
									y={centroidY}
									fontSize={Math.min(svgDimensions[0] / 17, 14)}
									fontFamily={PRIMARY_FONT}
									fill={"white"}
									fontWeight={600}
									textAnchor="middle"
									pointerEvents="none"
								>
									{`${item.data.observationLabel}`}
								</Text>
								<Text
									css={{ textTransform: "uppercase" }}
									x={centroidX}
									y={centroidY + Math.min(svgDimensions[0] / 17, 14)}
									fontSize={Math.min(svgDimensions[0] / 17, 14)}
									fontFamily={PRIMARY_FONT}
									fill={"white"}
									fontWeight={600}
									textAnchor="middle"
									pointerEvents="none"
								>
									{`${(item.data.data.percentage * 100).toFixed(1)}%`}
								</Text>
							</Group>
						)}
					</animated.g>
				);
			})}
		</Group>
	);
}
