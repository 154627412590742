import { useAtomValue } from "jotai";

import { mixColorFamilies, portalColors } from "../../../style/colors";
import DataHighlightBox from "../../charts/DataHighlightBox";

import {
  averageExperienceEngagementTimeAtom,
  averageExperienceNodesVisitedAtom,
  refreshAnalyticsSystemDataAtom,
} from "../../../data/analytics";
import StartedExperiencesSummary from "./StartedExperiencesSummary";
import GoalAchievementSummary from "./GoalAchievementSummary";
import { mediaQueries } from "../../../style";

type ExperiencesSummaryStatisticsProps = {
  width: number;
  height: number;
};

/**
 * Provide the following summary project statistics:
 * 1. Average minutes of engagement for started experiences.
 * 2. Average nodes visited for started experiences.
 * 3. Total Number of Started Experiences
 * 4. Total Number of Goal Achievement Events
 */
export default function ExperiencesSummaryStatistics({
  width,
  height,
}: ExperiencesSummaryStatisticsProps) {
  const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);
  const averageEngagementTime = useAtomValue(
    averageExperienceEngagementTimeAtom
  );
  const averageNodesVisited = useAtomValue(averageExperienceNodesVisitedAtom);

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr 1fr",
        gridAutoFlow: "row",
        gap: 10,
        maxWidth: width,
        minHeight: height,
        width: "100%",
        boxSizing: "border-box",
        overflow: "hidden",
        [mediaQueries[2]]: {
          gridAutoFlow: "column",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateRows: "1fr 1fr",
        },
      }}>
      <StartedExperiencesSummary dataRefreshing={dataRefreshing} />
      <GoalAchievementSummary dataRefreshing={dataRefreshing} />
      <DataHighlightBox
        primaryText={averageEngagementTime.toFixed(1)}
        secondaryText={"Average Minutes of Engagement"}
        colorFamily={portalColors.orange}
        dataRefreshing={dataRefreshing}
      />

      <DataHighlightBox
        primaryText={averageNodesVisited.toFixed(1)}
        secondaryText={"Average Number of Nodes Started"}
        colorFamily={mixColorFamilies(
          portalColors.orange,
          portalColors.gray,
          0.5
        )}
        dataRefreshing={dataRefreshing}
      />
    </div>
  );
}
