import { useAtomValue } from "jotai";
import { featureNavDimensionsAtom } from "../componentDimensions";

/**
 * Return the available width for the primary content area.
 *
 * Essentially, all window width not used by global UI.
 * */
export function useAvailableWidth(reducedBy = 0) {
	return useAtomValue(featureNavDimensionsAtom)[0] - reducedBy;
}
