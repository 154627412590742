import { useAvailableWidth } from "../../../data/hooks/useAvailableWidth";
import USGeolocation from "./USGeolocation";
import { mediaQueries } from "../../../style";

export type GeolocationProps = {
	availableHeight: number;
};

export default function Geolocation({ availableHeight }: GeolocationProps) {
	const availableWidth = useAvailableWidth(60);

	return (
		<div
			css={{
				boxSizing: "border-box",
				display: "grid",
				gap: 20,
				marginTop: 10,
				gridTemplateRows: availableHeight * 0.6,
				gridTemplateColumns: availableWidth,
				[mediaQueries[3]]: {
					gridTemplateRows: availableHeight * 0.85,
				},
				[mediaQueries[4]]: {
					gridTemplateColumns: `${availableWidth / 2}px ${
						availableWidth / 2
					}px`,
				},
			}}
		>
			<USGeolocation height={availableHeight} />
		</div>
	);
}
