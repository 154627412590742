import { useAtomValue } from "jotai";

import { portalColors } from "../../../style/colors";
import TreemapChart from "../../charts/Treemap";
import {
	browserBreakdownAtom,
	refreshAnalyticsSystemDataAtom,
} from "../../../data/analytics";
import { CSSObject, SerializedStyles, css } from "@emotion/react";

export default function Browsers({
	additionalCSS = {},
}: { additionalCSS?: CSSObject }) {
	const browsers = useAtomValue(browserBreakdownAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	return (
		<TreemapChart
			dataRefreshing={dataRefreshing}
			additionalCSS={additionalCSS}
			depthToChart={1}
			observations={browsers}
			dataColors={[portalColors.magenta.mid, portalColors.gray.dark]}
			chartTitle={{
				callOut: "Browsers",
				text: "Distribution of Browsers used to Access Experience",
			}}
		/>
	);
}
