import { useAtomValue } from "jotai";

import { startedExperienceRatiosAtom } from "../../../data/analytics/experience";
import { mixColorFamilies, portalColors } from "../../../style/colors";

import LineChart from "../../charts/Line";
import { refreshAnalyticsSystemDataAtom } from "../../../data/analytics";

export default function LoadStartRatios() {
	const startedExperienceRatios = useAtomValue(startedExperienceRatiosAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	return (
		<LineChart
			dataColors={[portalColors.blue.mid, portalColors.orange.mid]}
			observations={startedExperienceRatios}
			dataRefreshing={dataRefreshing}
			seriesNames={["desktop", "mobile"]}
			axes={{ observationLabel: "bottom" }}
			colorTheme={mixColorFamilies(portalColors.blue, portalColors.gray, 0.9)}
			chartTitle={{
				text: "Are there differences between the start rate between desktop and mobile devices?",
				callOut: "Desktop/Mobile Start Rates",
			}}
			displayLegend
			labelFormatter={(label) => label}
			dataFormatter={(data) => `${(+data * 100).toFixed()}%`}
			tooltip={{ title: "Desktop/Mobile Start Rates" }}
		/>
	);
}
