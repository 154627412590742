import { useSetAtom } from "jotai";

import UserAuthenticationUI from "./components/UserAuthenticationUI";
import useDimensions from "react-cool-dimensions";
import ClientNavigator from "./components/ClientNavigator";
import { currentScreenDimensionsAtom } from "./data/componentDimensions";

import useAlternativeAuthenticationMethods from "./hooks/authentication/useAlternativeAuthenticationMethods";
import { Suspense } from "react";

function App() {
	const storeScreenDimensions = useSetAtom(currentScreenDimensionsAtom);
	const { observe } = useDimensions({
		useBorderBoxSize: true,
		onResize: ({ width, height }) => {
			storeScreenDimensions([width, height]);
		},
	});

	useAlternativeAuthenticationMethods();

	return (
		<section
			ref={observe}
			id="main-content"
			css={{
				width: "100%",
				height: ["100vh", "-webkit-fill-available"],
				boxSizing: "border-box",
				overflow: "hidden",
			}}
		>
			<Suspense fallback={null}>
				<UserAuthenticationUI />
				<ClientNavigator />
			</Suspense>
		</section>
	);
}

export default App;
