import { useMemo } from "react";
import { useAtomValue } from "jotai";

import {
	loadedExperiencesGroupedByDateAtom,
	startedExperiencesGroupedByDateAtom,
} from "../../../data/analytics/experience";
import { mixColorFamilies, portalColors } from "../../../style/colors";
import { OBSERVATION_WITH_DATE_LABEL } from "../../../types/charts";

import GroupedBarChart from "../../charts/Bar/GroupedBarChart";
import { allDatesInUserSelectionAtom } from "../../../data/dateTime";
import { refreshAnalyticsSystemDataAtom } from "../../../data/analytics";

/** Provide a comparison of loaded to started experiences for the selected dates. */
export default function LoadedStartedExperienceCounts() {
	const loadedExperiences = useAtomValue(loadedExperiencesGroupedByDateAtom);
	const startedExperiences = useAtomValue(startedExperiencesGroupedByDateAtom);
	const selectedDates = useAtomValue(allDatesInUserSelectionAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	const preparedObservations = useMemo<
		OBSERVATION_WITH_DATE_LABEL<{
			loaded: number;
			started: number;
		}>[]
	>(() => {
		// Generate an observation for each date in range.
		const observations = selectedDates.map((date) => {
			const dateAsString = date.toISODate();

			if (!dateAsString)
				throw new Error(`Could not create string from ${date}`);

			return {
				observationLabel: date,
				data: {
					loaded: loadedExperiences[dateAsString]?.length ?? 0,
					started: startedExperiences[dateAsString]?.length ?? 0,
				},
			};
		});

		return observations;
	}, [loadedExperiences, startedExperiences, selectedDates]);

	return (
		<GroupedBarChart
			dataColors={[portalColors.orange.mid, portalColors.blue.mid]}
			observations={preparedObservations}
			dataRefreshing={dataRefreshing}
			seriesNames={["loaded", "started"]}
			axes={{ observationLabel: "bottom" }}
			colorTheme={mixColorFamilies(portalColors.blue, portalColors.gray, 0.9)}
			chartTitle={{
				text: "Breakdown of how many experiences were loaded/started each day",
				callOut: "Loaded and Started Experiences",
			}}
			displayLegend
			labelFormatter={(label) => label}
			dataFormatter={(data) => `${(Number(data) * 100).toFixed()}%`}
			tooltip={{ title: "Loaded/Started Experiences" }}
		/>
	);
}
