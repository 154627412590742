import { useMemo } from "react";
import { useAtomValue } from "jotai";

import { MEDIA_BREAKPOINTS } from "../style";
import { currentScreenDimensionsAtom } from "../data/componentDimensions";

export default function useUserOnSmallScreen() {
  const screenDimensions = useAtomValue(currentScreenDimensionsAtom);

  return useMemo(
    () => (screenDimensions[0] > MEDIA_BREAKPOINTS[0] ? false : true),
    [screenDimensions]
  );
}
