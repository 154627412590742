import { useMemo } from "react";
import { animated, useTrail } from "@react-spring/web";
import { useTooltip } from "@visx/tooltip";
import { scaleLinear, scaleBand, scaleOrdinal } from "@visx/scale";
import { localPoint } from "@visx/event";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { Legend } from "@visx/legend";
import { AxisLeft } from "@visx/axis";

// Components
import GridLines from "../GridLines";
import ChartContainer from "../../ChartContainer";

// Data
import { portalColors } from "../../../style/colors";
import { SECONDARY_FONT } from "../../../style/fonts";

// Definitions
import { ObjectWithStringKeys } from "@form-films/atlas-engine-shared-definitions";
import { OBSERVATION_WITH_STRING_LABEL } from "../../../types/charts";
import { COMMON_CHART_PROPS } from "../../../types/charts/props";
import Tooltip from "../../widgets/Tooltip";
import { Property } from "csstype";

import useObserveChartComponents from "../../../data/hooks/useObserveChartComponents";
import useSVGDimensions from "../../../hooks/useSVGDimensions";
import { mediaQueries } from "../../../style";

/** Standard bar chart with many different options. */
export default function CategoricalBarChart({
	observations,
	chartTitle,
	displayLegend = false,
	dataColors,
	gridLines,
	colorTheme = portalColors.gray,
	transparentBackground = false,
	dataRefreshing,
	margins = { vertical: 20, horizontal: 20 },
	labelFormatter = (label) => label.toString(),
	dataFormatter = (data) => data.toString(),
	tooltip,
	chartExplanation,
	chartActions,
}: COMMON_CHART_PROPS<OBSERVATION_WITH_STRING_LABEL<ObjectWithStringKeys>>) {
	const {
		observeComponent,
		componentWidth,
		componentHeight,
		observeTitle,
		observeLegend,
		titleHeight,
		legendHeight,
	} = useObserveChartComponents();

	// Determine the effective dimensions for the actual SVG element.
	const svgDimensions = useSVGDimensions({
		width: componentWidth,
		height: componentHeight,
		margins,
		heightSubstractors: [titleHeight, legendHeight],
	});

	// scales, memoize for performance
	const xScale = useMemo(() => {
		const scale = scaleBand({
			domain: observations.map((datum) => datum.observationLabel),
			range: [0, svgDimensions[0] - 25],
			round: true,
			padding: 0.1,
			paddingOuter: 0.05,
		});

		return scale;
	}, [svgDimensions[0], observations]);

	/**
	 * Remember that you are calculating the Y-coordinate value, not the height
	 * here. Entries will lower domain values should have bars that start lower
	 * on the screen.
	 *
	 * This is why you have the strange looking inversion of values in range.
	 * */
	const yScale = useMemo(
		() =>
			scaleLinear<number>({
				domain: [
					0,
					Math.max(...observations.map((datum) => datum.data.count as number)),
				],
				range: [svgDimensions[1], 0],
				round: true,
			}),
		[svgDimensions[1], observations],
	);

	const barHeightScale = useMemo(
		() =>
			scaleLinear<number>({
				domain: [
					0,
					Math.max(...observations.map((datum) => datum.data.count as number)),
				],
				range: [0, svgDimensions[1]],
				round: true,
			}),
		[svgDimensions[1], observations],
	);

	// Determine which visx scale to use
	const colorScale = useMemo(() => {
		return scaleOrdinal({
			domain: observations.map((datum) => datum.observationLabel),
			range: dataColors,
		});
	}, [observations, dataColors]);

	const [trails, api] = useTrail(
		observations.length,
		() => ({
			from: { scaleY: 0, opacity: 0, filter: "saturate(0%)" },
			to: { scaleY: 1, opacity: 1, filter: "saturate(100%)" },
			config: { tension: 250, friction: 25 },
			delay: 500,
		}),
		[observations.length],
	);

	const {
		tooltipData,
		tooltipLeft,
		tooltipTop,
		tooltipOpen,
		showTooltip,
		hideTooltip,
	} = useTooltip<{
		title: string;
		text: string;
		color: Property.Color;
	}>();

	return (
		<ChartContainer
			colorTheme={colorTheme}
			margins={margins}
			transparentBackground={transparentBackground}
			chartTitle={{ ...chartTitle }}
			chartExplanation={chartExplanation}
			observer={observeComponent}
			titleObserver={observeTitle}
			chartActions={chartActions}
		>
			{dataRefreshing ? null : (
				<>
					{tooltipOpen && tooltipData ? (
						<Tooltip
							positioning={{ top: tooltipTop, left: tooltipLeft }}
							subtitle={"Goal Details"}
							title={tooltipData.title}
							observationColor={tooltipData.color}
							additionalData={{
								"total events": tooltipData.text,
							}}
						/>
					) : null}
					{svgDimensions[0] && svgDimensions[1] && (
						<svg
							viewBox={`0 0 ${svgDimensions[0]} ${svgDimensions[1]}`}
							css={{
								width: svgDimensions[0],
								height: svgDimensions[1],
								overflow: "unset",
							}}
						>
							<title>Categorical Bar Chart</title>
							<GridLines
								lineOption={gridLines}
								lineColor={portalColors.tan.mid}
								xScale={xScale}
								yScale={yScale}
								width={svgDimensions[0] - 25}
								height={svgDimensions[1]}
							/>
							<Group>
								{observations.map((observation, index) => {
									const barWidth = xScale.bandwidth();
									const barX = xScale(observation.observationLabel);

									return (
										<animated.g
											style={trails[index]}
											css={{ transformOrigin: "bottom" }}
											key={`category-bar-${index}`}
										>
											<Bar
												key={`bar-${observation.observationLabel}`}
												x={barX}
												y={yScale(observation.data.count as number)}
												width={barWidth}
												height={barHeightScale(
													observation.data.count as number,
												)}
												fill={colorScale(observation.observationLabel)}
												onMouseOver={(event) => {
													const coords = localPoint(
														// @ts-ignore
														event.target.ownerSVGElement,
														event,
													);

													showTooltip({
														tooltipLeft: barX!,
														tooltipTop: coords!.y,
														tooltipData: {
															title: observation.observationLabel,
															text: (
																observation.data.count as number
															).toString(),
															color: colorScale(observation.observationLabel),
														},
													});
												}}
												onMouseOut={hideTooltip}
											/>
										</animated.g>
									);
								})}

								<AxisLeft
									left={svgDimensions[0] + 5}
									scale={yScale}
									hideZero
									hideAxisLine
									hideTicks
									tickLength={0}
									tickStroke={colorTheme.dark}
									tickLabelProps={() => ({
										fill: colorTheme.dark,
										fontSize: 11,
										textAnchor: "end",
										verticalAnchor: "middle",
										x: -5,
									})}
								/>
							</Group>
						</svg>
					)}
					{displayLegend && (
						<Legend scale={colorScale}>
							{(labels) => (
								<div
									ref={observeLegend}
									css={{
										display: "grid",
										gridTemplateColumns:
											"repeat(2, minmax(100px, max-content))",
										gridAutoFlow: "column",
										gridTemplateRows: `repeat(${Math.ceil(
											labels.length / 2,
										)}, 1fr)`,
										columnGap: 20,
										rowGap: 2,
										paddingTop: 5,
										paddingBottom: 5,
										[mediaQueries[2]]: {
											gridTemplateColumns:
												"repeat(3, minmax(100px, max-content))",
											gridAutoFlow: "column",
											gridTemplateRows: `repeat(${Math.ceil(
												labels.length / 3,
											)}, 1fr)`,
										},
									}}
								>
									{labels.map((label, i) => (
										<div
											key={`legend-quantile-${i}`}
											css={{ display: "flex", alignItems: "center" }}
										>
											<svg width={10} height={10}>
												<rect fill={label.value} width={10} height={10} />
											</svg>
											<span
												css={{
													marginLeft: 5,
													fontSize: 12,
													fontFamily: SECONDARY_FONT,
													textTransform: "capitalize",
													color: colorTheme.dark,
													fontWeight: 500,
												}}
											>
												{label.text}
											</span>
										</div>
									))}
								</div>
							)}
						</Legend>
					)}
				</>
			)}
		</ChartContainer>
	);
}
