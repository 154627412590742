import { useAtom, useAtomValue } from "jotai";

// Components
import FeatureButton from "./FeatureButton";
import FeatureMenuOptions from "./FeatureMenu";

// Data
import { currentNavigatorFeatureAtom } from "../../data/feature";
import { LS_SelectedCoursesAtom } from "../../data/learningSystem/courses";
import { AS_AddCartItemEvents } from "../../data/commerce/addItem";

// Definitions

export default function CurrentFeatures() {
	const [currentFeature, setCurrentFeature] = useAtom(
		currentNavigatorFeatureAtom,
	);

	const courses = useAtomValue(LS_SelectedCoursesAtom);
	const addCartItemEvents = useAtomValue(AS_AddCartItemEvents);

	return (
		<div id="currentlyAvailableFeatures" css={{ marginTop: 25 }}>
			<div key={"analytics-feature"}>
				<FeatureButton
					label={"Analytics"}
					onPress={() => setCurrentFeature("Analytics")}
					active={currentFeature === "Analytics"}
				/>
				<FeatureMenuOptions feature={"Analytics"} />
			</div>
			<div
				key={"commerce-feature"}
				css={{
					transition: "all .5s ease",
					...(addCartItemEvents.length
						? {}
						: { pointerEvents: "none", opacity: 0.33 }),
				}}
			>
				<FeatureButton
					label="Commerce"
					onPress={() => setCurrentFeature("Commerce")}
					active={currentFeature === "Commerce"}
				/>
				<FeatureMenuOptions feature={"Commerce"} />
			</div>
			<div
				key={"learning_system_feature"}
				css={{
					transition: "all .5s ease",
					...(courses.length ? {} : { pointerEvents: "none", opacity: 0.33 }),
				}}
			>
				<FeatureButton
					label={"Learning System"}
					onPress={() => setCurrentFeature("Learning System")}
					active={currentFeature === "Learning System"}
				/>
				<FeatureMenuOptions feature={"Learning System"} />
			</div>
		</div>
	);
}
