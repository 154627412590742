import { useEffect, useMemo, useState } from "react";
import { useTrail, animated } from "@react-spring/web";
import { useTooltip } from "@visx/tooltip";
import { scaleOrdinal } from "@visx/scale";
import { localPoint } from "@visx/event";
import { BarGroup } from "@visx/shape";
import { Group } from "@visx/group";
import { LegendOrdinal } from "@visx/legend";
import { AxisRight } from "@visx/axis";
import { ScaleBand } from "d3-scale";
import { DateTime } from "luxon";

// Components
import GridLines from "../GridLines";
import CustomDateTimeBottomAxis from "../CustomDateTimeBottomAxis";
import ChartTitle from "../ChartTitle";
import Tooltip from "../../widgets/Tooltip";

// Data and Definitions
import { PRIMARY_FONT } from "../../../style/fonts";
import { darkGray, portalColors } from "../../../style/colors";
import {
	OBSERVATION_GENERIC,
	areObservationsDateTimeLabeled,
} from "../../../types/charts";
import { MULTI_SERIES_CHART_PROPS } from "../../../types/charts/props";

// Hooks
import useSVGDimensions from "../../../hooks/useSVGDimensions";
import useBandScale from "../../../hooks/useBandScale";
import useLinearScale from "../../../hooks/useLinearScale";
import useFlattenSeriesValues from "../../../data/hooks/useFlattenSeriesValues";
import useObserveChartComponents from "../../../data/hooks/useObserveChartComponents";

// Utils
import { convertFromNamingConvention } from "../../../utils/text/namingConvention";
import { isDateTime } from "../../../utils/datesAndTimes";

/**
 * A bar chart which displays series data side-by-side
 * as separate bars for each observation.
 */
export default function GroupedBarChart<
	ObservationType extends OBSERVATION_GENERIC,
>({
	margins = { vertical: 20, horizontal: 20 },
	observations,
	dataRefreshing,
	seriesNames,
	axes,
	dataColors,
	chartTitle,
	colorTheme = portalColors.gray,
	displayLegend = false,
	transparentBackground = false,
	gridLines = "rows",
	labelFormatter = (label) => label.toString(),
	dataFormatter = (data) => data.toString(),
	tooltip,
}: MULTI_SERIES_CHART_PROPS<ObservationType>) {
	const [currentObservationIndex, setCurrentObservationIndex] = useState<
		number | null
	>(null);

	const {
		observeComponent,
		componentWidth,
		componentHeight,
		observeTitle,
		observeLegend,
		titleHeight,
		legendHeight,
	} = useObserveChartComponents();

	// Determine the effective dimensions for the actual SVG element.
	const svgDimensions = useSVGDimensions({
		width: componentWidth,
		height: componentHeight,
		margins,
		heightSubstractors: [titleHeight, legendHeight],
	});

	// Scale to position each bar group
	const groupXScale = useBandScale({
		domain: observations.map((datum) => datum.observationLabel),
		range: [0, svgDimensions[0] - 20],
	});

	// Scale to position each bar within a group.
	const individualBarXScale = useBandScale({
		domain: seriesNames,
		range: [0, groupXScale.bandwidth()],
	});

	const allSeriesValues = useFlattenSeriesValues(
		observations,
		seriesNames,
	) as number[];

	const yScale = useLinearScale({
		domain: [0, Math.max(...allSeriesValues)],
		// range: [svgDimensions[1] - (axes?.observationLabel ? 40 : 0), 0],
		range: [svgDimensions[1] - (axes?.observationLabel ? 40 : 0), 0],
	});

	const colorScale = useMemo(
		() =>
			scaleOrdinal({
				domain: seriesNames,
				range: dataColors,
			}),
		[dataColors, seriesNames],
	);

	const [trails, trailsAPI] = useTrail(observations.length, () => ({
		from: { scaleY: 0, opacity: 0, filter: "saturate(0%)" },
		// to: { scaleY: 1, opacity: 1 },
		config: { tension: 500, friction: 30 },
	}));

	useEffect(() => {
		dataRefreshing
			? trailsAPI.start({
					to: { scaleY: 0, opacity: 0, filter: "saturate(0%)" },
					immediate: true,
			  })
			: trailsAPI.start({
					to: { scaleY: 1, opacity: 1, filter: "saturate(100%)" },
					immediate: false,
			  });
	}, [dataRefreshing, trailsAPI]);

	// Tooltip Artifacts
	const {
		tooltipData,
		tooltipLeft,
		tooltipTop,
		tooltipOpen,
		showTooltip,
		hideTooltip,
	} = useTooltip<ObservationType>();

	const renderTooltip = (observation: ObservationType) => {
		return (
			<Tooltip
				positioning={{ top: tooltipTop, left: tooltipLeft }}
				title={tooltip?.title ?? "Record Details"}
				subtitle={
					isDateTime(observation.observationLabel)
						? // biome-ignore lint/style/noNonNullAssertion: In this case, we know the value will not be null.
						  observation.observationLabel.toISODate()!
						: observation.observationLabel
				}
				additionalData={observation.data}
				animationDelay={200}
				colorScale={colorScale}
			/>
		);
	};

	return (
		<div
			ref={observeComponent}
			css={{
				backgroundColor: transparentBackground
					? "transparent"
					: colorTheme.light,
				paddingLeft: margins.horizontal,
				paddingRight: margins.horizontal,
				paddingTop: margins.vertical,
				paddingBottom: margins.vertical,
				boxSizing: "border-box",
				width: "100%",
				height: "100%",
				position: "relative",
				borderRadius: 5,
			}}
		>
			<div>
				{tooltipOpen && tooltipData && renderTooltip(tooltipData)}
				<div ref={observeTitle}>
					<ChartTitle
						callOut={chartTitle.callOut}
						text={chartTitle.text}
						color={colorTheme.dark}
						containerCSS={{
							boxSizing: "border-box",
							paddingBottom: 20,
							paddingRight: 20,
							fontSize: 26,
							textTransform: "capitalize",
						}}
					/>
				</div>
				{svgDimensions[0] && svgDimensions[1] && (
					<svg
						viewBox={`0 0 ${svgDimensions[0]} ${svgDimensions[1]}`}
						css={{
							width: svgDimensions[0],
							height: svgDimensions[1],
							overflow: "unset",
						}}
					>
						<title>Grouped Bar Chart</title>
						<GridLines
							lineOption={gridLines}
							lineColor={colorTheme.mid}
							xScale={groupXScale}
							yScale={yScale}
							width={svgDimensions[0] - margins.horizontal}
							height={svgDimensions[1]}
						/>
						<BarGroup
							/* Start HERE - flatten an observation */
							data={observations.map((observation) => ({
								...observation,
								...observation.data,
							}))}
							keys={seriesNames}
							height={yScale.range()[0]}
							x0={(d) => d.observationLabel}
							x0Scale={groupXScale}
							x1Scale={individualBarXScale}
							yScale={yScale}
							color={colorScale}
						>
							{(barGroups) => {
								return barGroups.map((barGroup, barGroupIndex) => {
									return (
										<animated.g
											key={barGroupIndex}
											style={trails[barGroupIndex]}
											css={{ transformOrigin: "bottom" }}
											onMouseLeave={() => {
												hideTooltip();
												setCurrentObservationIndex(null);
											}}
											onMouseMove={(event) => {
												const eventSvgCoords = localPoint(event);

												showTooltip({
													tooltipData: observations[barGroupIndex],
													tooltipTop: eventSvgCoords?.y,
													tooltipLeft:
														barGroup.x0 + groupXScale.bandwidth() + 15,
												});
												setCurrentObservationIndex(barGroupIndex);
											}}
										>
											{barGroup.bars.map((bar, index) => {
												return (
													<g key={index}>
														<rect
															key={`bar-group-${barGroup.index}-${bar.index}-${bar.key}-${index}`}
															x={bar.x + barGroup.x0}
															y={bar.y}
															width={bar.width}
															height={bar.height}
															fill={bar.color}
														/>
														<rect
															css={{
																transition: "all .5s ease",
																transitionDelay: "150ms",
																opacity:
																	currentObservationIndex === null
																		? 0
																		: currentObservationIndex === barGroupIndex
																		  ? 0
																		  : 0.25,
																pointerEvents: "none",
															}}
															key={`bar-group-${barGroup.index}-${bar.index}-${bar.key}`}
															x={bar.x + barGroup.x0}
															y={bar.y}
															width={bar.width}
															height={bar.height}
															fill={"black"}
														/>
													</g>
												);
											})}
										</animated.g>
									);
								});
							}}
						</BarGroup>
						<Group name="axes">
							<AxisRight
								left={svgDimensions[0] - 7}
								// top={5}
								scale={yScale}
								hideZero
								tickLength={0}
								stroke={"transparent"}
								tickStroke={darkGray}
								tickLabelProps={() => ({
									fill: colorTheme.dark,
									fontSize: 11,
									// textAnchor: "end",
									verticalAnchor: "middle",
									x: -5,
								})}
							/>
							{axes?.observationLabel === "bottom" &&
							areObservationsDateTimeLabeled(observations) ? (
								<CustomDateTimeBottomAxis
									yPosition={svgDimensions[1] - 40}
									color={colorTheme.dark}
									dateScale={groupXScale as ScaleBand<DateTime>}
								/>
							) : null}
						</Group>
					</svg>
				)}

				{displayLegend && (
					<div ref={observeLegend} css={{ paddingTop: 5, paddingBottom: 5 }}>
						<LegendOrdinal
							scale={colorScale}
							direction="column"
							labelMargin="0 0px 0 2px"
							labelFormat={(item) => convertFromNamingConvention(item)}
							shapeHeight={10}
							shapeWidth={10}
							css={{
								fontSize: 11,
								fontFamily: PRIMARY_FONT,
								textTransform: "capitalize",
								fontWeight: 500,
								color: colorTheme.dark,
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
