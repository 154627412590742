import { atom, getDefaultStore } from "jotai";

import { selectedOrganizationAtom } from "./organization";

import supabaseClientAtom from "./supabaseClient";
import { isCurrentUserAdmin, supabaseUserAtom } from "./user";
import { refreshAnalyticsSystemDataAtom } from "./analytics";

type SimplifiedAtlasProject = {
	client: string;
	created_at: string | null;
	id: string;
	name: string;
	extensions: (string | null | undefined)[];
	domains: string[];
};

export const atlasProjectsAtom = atom<Promise<SimplifiedAtlasProject[]>>(
	async (get) => {
		const client = get(supabaseClientAtom);
		const user = get(supabaseUserAtom);

		if (!user) return [];

		const { data: ie_projects, error } = await client
			.from("ie_projects")
			.select(
				"*, project_domain(*), project_extension(extension_id, extension(name))",
			);

		if (!ie_projects) return [];

		const simplifiedProjects = ie_projects.map((project) => ({
			client: project.client,
			created_at: project.created_at,
			id: project.id,
			name: project.name,
			extensions: project.project_extension.map(
				(extension) => extension.extension?.name,
			),
			domains: project.project_domain.map((domain) => domain.domain),
		}));

		return simplifiedProjects;
	},
);

/** All available projects for the selected organization. */
export const atlasOrganizationProjectsAtom = atom(async (get) => {
	const allAvailableProjects = await get(atlasProjectsAtom);
	const selectedOrganization = get(selectedOrganizationAtom);

	if (!selectedOrganization) return [];

	const organizationProjects = allAvailableProjects.filter(
		(project) => project.client === selectedOrganization.id,
	);

	getDefaultStore().set(currentAtlasProjectAtom, organizationProjects[0]);
	return organizationProjects ?? [];
});

/**
 * The currently selected project.
 *
 * SIDE EFFECT: When changed, will send a signal
 * to kick off data refreshes.
 * */
export const currentAtlasProjectAtom = atom<
	SimplifiedAtlasProject | null,
	[SimplifiedAtlasProject | null],
	void
>(null, (get, set, value) => {
	if (value) {
		set(currentAtlasProjectAtom, value);
		set(refreshAnalyticsSystemDataAtom, true);
	}
});
