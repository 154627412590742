import { useAtomValue } from "jotai";
import { useMemo, useState } from "react";

import AlbersUsaChart from "../../charts/geographic/AlbersUSA";
import { AS_geolocationEventsAtom } from "../../../data/analytics/events/geolocation";
import { OBSERVATION_WITH_SINGLE_NUMERIC_VALUE } from "../../../types/charts";
import { mixColorFamilies, portalColors } from "../../../style";
import useDataRefreshing from "../../../data/hooks/useDataRefreshing";

export default function USGeolocation({ height }: { height: number }) {
	const geolocationEvents = useAtomValue(AS_geolocationEventsAtom).map(
		(event) => event.data,
	);

	const dataRefreshing = useDataRefreshing("Analytics");

	const [detailLevel, setDetailLevel] = useState<"state" | "county">("state");
	const [selectedState, setSelectedState] = useState<{
		name: string;
		id: string;
	} | null>(null);

	const observations = useMemo<OBSERVATION_WITH_SINGLE_NUMERIC_VALUE[]>(() => {
		const dataAsObject = geolocationEvents.reduce(
			(previous, current) => {
				const existingLocationCount =
					detailLevel === "state"
						? previous[current.data.region_name]
						: previous[current.data.county_code ?? "0000"];

				return detailLevel === "state"
					? {
							...previous,
							[current.data.region_name]: existingLocationCount
								? previous[current.data.region_name] + 1
								: 1,
					  }
					: current.data.county_code
					  ? {
								...previous,
								[current.data.county_code]: existingLocationCount
									? previous[current.data.county_code] + 1
									: 1,
						  }
					  : { ...previous };
			},
			{} as { [key: string]: number },
		);

		return Object.entries(dataAsObject).map((entry) => ({
			observationLabel: entry[0],
			data: { value: entry[1] },
		}));
	}, [geolocationEvents, detailLevel]);

	return (
		<AlbersUsaChart
			chartTitle={{
				callOut: selectedState?.name ? selectedState.name : "United States",
				text: `Geographic distributions of visitors in ${
					selectedState?.name ?? "the united states"
				}`,
			}}
			chartExplanation="Geolocation data is obtained by cross-referencing the IP addresses of visitors with industry leading location databases."
			observations={observations}
			dataRefreshing={dataRefreshing}
			dataColors={[
				mixColorFamilies(portalColors.orange, portalColors.gray, 0.9).mid,
				portalColors.orange.mid,
			]}
			chartActions={
				detailLevel === "county"
					? [
							{
								name: "Exit State Detail",
								onClick: () => {
									setSelectedState(null);
									setDetailLevel("state");
								},
							},
					  ]
					: undefined
			}
			chartOptions={{
				detailLevel: { value: detailLevel, updateTo: setDetailLevel },
				selectedState: { value: selectedState, updateTo: setSelectedState },
			}}
		/>
	);
}
