import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Suspense, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import useDimensions from "react-cool-dimensions";

// Components
import UserGreeting from "../UserGreeting";
import CurrentFeatures from "../CurrentFeatures";
import ProjectSelector from "../ProjectSelector";
import SquareEdgeButton from "../../widgets/SquareEdgeButton";
import NavigatorIcon from "../../../assets/svg/navigatorLogo.svg?react";
import OrganizationSelector from "../OrganizationSelector";

// Data
import {
	currentNavigatorFeatureAtom,
	navigatorFeatures,
} from "../../../data/feature";
import {
	currentScreenDimensionsAtom,
	globalNavDimensionsAtom,
} from "../../../data/componentDimensions";
import useLogoutUser from "../../../hooks/useLogoutUser";
import { globalNavOpenAtom } from "../../../data/navigation";
import { userAvatarAtom } from "../../../data/analytics";

// Definitions
import { darkGray, midGray } from "../../../style/colors";
import Changelog from "../Changelog";

export default function GlobalNavMobile() {
	const [globalNavOpen, setGlobalNavOpen] = useAtom(globalNavOpenAtom);
	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);
	const userAvatar = useAtomValue(userAvatarAtom);

	const windowDimensions = useAtomValue(currentScreenDimensionsAtom);
	const storeComponentDimensions = useSetAtom(globalNavDimensionsAtom);
	const { observe } = useDimensions({
		useBorderBoxSize: true,
		onResize: ({ width, height }) => storeComponentDimensions([width, height]),
	});

	const logoutUser = useLogoutUser();

	const [bottomBarStyles, bottomBarStylesAPI] = useSpring(() => ({
		from: { backgroundColor: "white" },
	}));

	const [menuStyles, menuStylesAPI] = useSpring(() => ({
		from: { scaleY: 0, itemOpacity: 0, backgroundColor: darkGray },
	}));

	useEffect(() => {
		globalNavOpen
			? bottomBarStylesAPI.start({ to: { backgroundColor: darkGray } })
			: bottomBarStylesAPI.start({ to: { backgroundColor: "white" } });

		globalNavOpen
			? menuStylesAPI.start({
					to: [{ scaleY: 1, backgroundColor: "white" }, { itemOpacity: 1 }],
				})
			: menuStylesAPI.start({
					to: { scaleY: 0, backgroundColor: darkGray, itemOpacity: 0 },
				});
	}, [globalNavOpen, bottomBarStylesAPI, menuStylesAPI]);

	return (
		<div
			id="global-nav-mobile"
			css={{
				position: "fixed",
				zIndex: 1000,
				top: 0,
				bottom: 0,
				left: 0,
				width: "100%",
				height: "100%",
				backgroundColor: "transparent",
				boxSizing: "border-box",
				justifyContent: "space-between",
				display: "flex",
				flexDirection: "column",
				pointerEvents: globalNavOpen ? "auto" : "none",
			}}
		>
			<animated.div
				style={menuStyles}
				css={{
					transformOrigin: "bottom",
					boxSizing: "border-box",
					flexBasis: windowDimensions[1] - 90,
					height: windowDimensions[1] - 90,
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					overflow: "scroll",
				}}
			>
				<animated.div style={{ opacity: menuStyles.itemOpacity }}>
					<div css={{ padding: 35, paddingBottom: 0 }}>
						<UserGreeting />
						<ProjectSelector />
						<OrganizationSelector />
					</div>
					<CurrentFeatures />
				</animated.div>

				<animated.div
					style={{ opacity: menuStyles.itemOpacity }}
					css={{ display: "flex", flexDirection: "column", margin: 35 }}
				>
					{/* <Changelog /> */}
					<SquareEdgeButton
						label="logout"
						onPress={logoutUser}
						additionalStyles={{ marginTop: 10, height: 45 }}
					/>
				</animated.div>
			</animated.div>

			<animated.div
				id="globalNavStaticContent"
				ref={observe}
				style={bottomBarStyles}
				css={{
					pointerEvents: "all",
					borderTopColor: navigatorFeatures[currentFeature].colors.mid,
					borderTopWidth: 5,
					borderTopStyle: "solid",
					flexBasis: 90,
					height: 90,
					width: "100%",
					paddingTop: 15,
					paddingLeft: 30,
					paddingRight: 30,
					paddingBottom: 20,
					boxSizing: "border-box",
					justifyContent: "space-between",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
				onClick={() => {
					setGlobalNavOpen(!globalNavOpen);
				}}
			>
				<NavigatorIcon
					fill={globalNavOpen ? "white" : darkGray}
					css={{ height: "4rem" }}
				/>
				<img
					css={{
						borderRadius: 5,
						objectFit: "cover",
						height: 35,
						width: 50,
					}}
					src={
						userAvatar instanceof Blob
							? URL.createObjectURL(userAvatar)
							: userAvatar
					}
					alt="User Avatar"
				/>
			</animated.div>
		</div>
	);
}
