import { useAtomValue } from "jotai";
import {
	ColorFamily,
	mixColorFamilies,
	portalColors,
} from "../../../../../style";
import {
	LS_EngagementDetailsAtom,
	LS_FinishedEngagementDetailsAtom,
} from "../../../../../data/learningSystem/engagementDetail";
import { useMemo } from "react";
import SectionLabel from "../SectionLabel";
import UserResponse from "./UserResponse";
import { LS_Engagement } from "../../../../../data/learningSystem/types";

export default function UserResponses({
	observation,
	colorTheme,
}: {
	observation: LS_Engagement;
	colorTheme: ColorFamily;
}) {
	const engagementDetails = useAtomValue(LS_FinishedEngagementDetailsAtom);

	// Get the engagement details that have user response metadata
	const engagementUserResponses = useMemo(
		() =>
			engagementDetails
				.filter(
					(detail) =>
						detail.data.engagement_id === observation.observationLabel,
				)
				.filter((detail) => detail.data.metadata.questionResponses)
				.map((detail) => detail.data.metadata.questionResponses),
		[observation, engagementDetails],
	);

	return !engagementUserResponses.length ? null : (
		<div css={{ marginTop: 15 }}>
			<SectionLabel text="User Responses" colorTheme={colorTheme} />
			{engagementUserResponses.map((userResponses) => (
				<div css={{ display: "flex", gap: 5, flexWrap: "wrap", marginTop: 5 }}>
					{userResponses?.map((userResponse) => (
						<UserResponse
							userResponse={userResponse}
							colorTheme={mixColorFamilies(colorTheme, portalColors.gray, 0.6)}
						/>
					))}
				</div>
			))}
		</div>
	);
}
