import { cloneDeep, set } from "lodash";
import { useEffect, useState } from "react";

// Assets
import CheckboxEmpty from "../../../../../assets/svg/icons/checkboxEmpty.svg?react";
import CheckboxFilled from "../../../../../assets/svg/icons/checkboxFilled.svg?react";
import Trashcan from "../../../../../assets/svg/icons/trashCan.svg?react";
import ArrowUp from "../../../../../assets/svg/icons/arrowUp.svg?react";
import ArrowDown from "../../../../../assets/svg/icons/arrowDown.svg?react";

import { ColorFamily } from "../../../../../style";

import {
	_LS_EngagementsBaseAtom,
	updateEngagementMetadata,
} from "../../../../../data/learningSystem/engagement";
import { convertFromNamingConvention } from "../../../../../utils/text/namingConvention";
import { LearningSystemEngagementMetadata } from "@form-films/atlas-engine-shared-definitions";
import { LS_Engagement } from "../../../../../data/learningSystem/types";

type TaskProps = {
	observation: LS_Engagement;
	task: NonNullable<LearningSystemEngagementMetadata["tasks"]>[number];
	colorTheme: ColorFamily;
	toggleTask: (
		task: NonNullable<LearningSystemEngagementMetadata["tasks"]>[number],
	) => void;
	deleteTask: (
		task: NonNullable<LearningSystemEngagementMetadata["tasks"]>[number],
	) => void;
	reorderTask: (
		task: NonNullable<LearningSystemEngagementMetadata["tasks"]>[number],
		direction: "up" | "down",
	) => void;
	canMoveUp: boolean;
	canMoveDown: boolean;
};

/** Representation of a single engagement task. */
export default function Task({
	observation,
	task,
	colorTheme,
	toggleTask,
	deleteTask,
	reorderTask,
	canMoveUp,
	canMoveDown,
}: TaskProps) {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div
			key={`${observation.observationLabel}-${task.name}`}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			css={{
				display: "grid",
				gap: 10,
				gridTemplateColumns: "1fr max-content",
				width: "100%",
				...(task.complete
					? {
							backgroundColor: colorTheme.light,
							fill: colorTheme.dark,
							color: colorTheme.dark,
							paddingTop: 10,
							paddingBottom: 10,
							padding: 10,
							marginBottom: 5,
					  }
					: {
							backgroundColor: "transparent",
							fill: colorTheme.mid,
							color: colorTheme.mid,
							paddingTop: 5,
							paddingBottom: 5,
							paddingLeft: 0,
							paddingRight: 5,
					  }),
				border: "none",
				alignItems: "center",
				justifyContent: "space-between",
				transition: "padding-top 0.25s ease, padding-bottom 0.25s ease",
				cursor: "pointer",
				textTransform: "uppercase",
				fontSize: 11,
				fontWeight: 600,
				borderRadius: 5,
				...(isHovered && {
					":hover": {
						backgroundColor: colorTheme.mid,
						color: "white",
						fill: "white",
						paddingLeft: 10,
					},
				}),
			}}
			onClick={() => {
				if (isHovered) {
					toggleTask(task);
				}
			}}
		>
			<div
				css={{
					display: "flex",
					alignItems: "center",
					gap: 5,
				}}
			>
				{task.complete ? (
					<CheckboxFilled width={20} height={20} />
				) : (
					<CheckboxEmpty width={20} height={20} />
				)}
				<span css={{ flex: 1, wordBreak: "break-word" }}>
					{convertFromNamingConvention(task.name)}
				</span>
			</div>

			<div
				id="misc-controls"
				css={{
					opacity: isHovered ? 1 : 0,
					display: "flex",
					gap: 5,
				}}
			>
				{task.deletable && (
					<Trashcan
						width={21}
						height={21}
						role="button"
						onClick={(event) => {
							if (isHovered) {
								event.stopPropagation();
								deleteTask(task);
							}
						}}
						css={{
							padding: 2,
							borderRadius: 2,
							flex: 1,
							":hover": {
								backgroundColor: "white",
								fill: colorTheme.mid,
							},
						}}
					/>
				)}
				<ArrowUp
					width={21}
					height={21}
					onClick={(event) => {
						if (isHovered) {
							setIsHovered(false);
							event.stopPropagation();
							canMoveUp && reorderTask(task, "up");
						}
					}}
					css={{
						padding: 2,
						borderRadius: 2,
						flex: 1,
						...(canMoveUp
							? {
									":hover": {
										backgroundColor: "white",
										fill: colorTheme.mid,
									},
							  }
							: {}),
					}}
				/>
				<ArrowDown
					width={21}
					height={21}
					onClick={(event) => {
						if (isHovered) {
							event.stopPropagation();
							setIsHovered(false);
							canMoveDown && reorderTask(task, "down");
						}
					}}
					css={{
						padding: 2,
						borderRadius: 2,
						flex: 1,
						...(canMoveDown
							? {
									":hover": {
										backgroundColor: "white",
										fill: colorTheme.mid,
									},
							  }
							: {}),
					}}
				/>
			</div>
		</div>
	);
}
