import { useMemo, useState } from "react";
import { css } from "@emotion/react";
import useDimensions from "react-cool-dimensions";
import { useAtom, useAtomValue } from "jotai";

import { ColorFamily, PRIMARY_FONT, portalColors } from "../../../../style";
import { allTagsForSelectedEngagementsAtom } from "../../../../data/learningSystem/engagement/tags";
import { convertToNamingConvention } from "../../../../utils/text/namingConvention";

import {
	EngagementFilter,
	engagementFiltersAtom,
} from "../../../../data/learningSystem/engagement/filters";
import Dropdown from "../Dropdown";
import { allTaskNamesForSelectedEngagementsAtom } from "../../../../data/learningSystem/engagement/tasks";

type NewFilterProps = {
	colorTheme: ColorFamily;
};

export default function NewFilter({ colorTheme }: NewFilterProps) {
	const allTags = useAtomValue(allTagsForSelectedEngagementsAtom);
	const allTasks = useAtomValue(allTaskNamesForSelectedEngagementsAtom);
	const [engagementFilters, setEngagmentFilters] = useAtom(
		engagementFiltersAtom,
	);

	const { observe, height: observedHeight } = useDimensions({
		useBorderBoxSize: true,
	});

	const [filterEntity, setFilterEntity] = useState<
		EngagementFilter["entity"] | null
	>(null);

	const [filterBehavior, setFilterBehavior] =
		useState<EngagementFilter["behavior"]>("inclusion");

	const [tagCategory, setTagCategory] = useState<string | null>(null);
	const [tagName, setTagName] = useState<string | null>(null);

	const [taskName, setTaskName] = useState<string | null>(null);
	const [taskCompletionStatus, setTaskCompletionStatus] = useState<
		boolean | null
	>(null);

	const disableSubmission = useMemo(
		() =>
			(tagCategory && tagName) || (taskName && taskCompletionStatus !== null)
				? false
				: true,
		[tagCategory, tagName, taskName, taskCompletionStatus],
	);

	const resetComponent = () => {
		setFilterEntity(null);
		setTagCategory(null);
		setTagName(null);
		setTaskName(null);
		setTaskCompletionStatus(null);
	};

	const addEngagementFilter = () => {
		if (
			(filterEntity === "tag" && tagCategory && tagName) ||
			(filterEntity === "task" && taskName && taskCompletionStatus !== null)
		) {
			// Remove potential duplicate filter
			const dedupedFilters = engagementFilters.filter((filter) => {
				if (filter.entity === "tag") {
					return (
						filter.tag.category !== tagCategory ||
						(filter.tag.category === tagCategory && filter.tag.name !== tagName)
					);
				} else {
					return filter.task.name !== taskName;
				}
			});

			filterEntity === "tag"
				? setEngagmentFilters([
						...dedupedFilters,
						{
							entity: "tag",
							tag: {
								category: convertToNamingConvention(tagCategory!),
								name: convertToNamingConvention(tagName!),
							},
							behavior: filterBehavior,
						},
				  ])
				: setEngagmentFilters([
						...dedupedFilters,
						{
							entity: "task",
							task: {
								name: taskName!,
								completed: taskCompletionStatus!,
							},
							behavior: filterBehavior,
						},
				  ]);
		}
		resetComponent();
	};

	const renderEntityButton = (
		name: string,
		selected: boolean,
		action: () => void,
	) => {
		return (
			<button
				type="button"
				onClick={action}
				css={{
					transition: "all .33s ease",
					flex: 1,
					border: "none",
					outline: "none",
					padding: 10,
					fontFamily: PRIMARY_FONT,
					fontSize: 11,
					textTransform: "uppercase",
					fontWeight: 700,
					borderRadius: 2,
					...(selected
						? {
								backgroundColor: colorTheme.mid,
								color: "white",
						  }
						: {
								backgroundColor: colorTheme.light,
								color: colorTheme.mid,
						  }),
				}}
			>
				{name}
			</button>
		);
	};

	const renderTagFilter = () => {
		return (
			<div>
				<Dropdown
					title="Select Category"
					options={Object.keys(allTags)}
					value={tagCategory}
					onChange={(value) => {
						setTagCategory(value);
						setTagName(null);
					}}
					colorTheme={colorTheme}
					containerCSS={css({ marginTop: 5 })}
					initiallyOpen={tagCategory === null}
				/>
				<Dropdown
					title="Select Option"
					options={tagCategory ? (allTags[tagCategory] as string[]) : []}
					value={tagName}
					onChange={setTagName}
					colorTheme={colorTheme}
					containerCSS={css({
						marginTop: 5,
						display: tagCategory ? "block" : "none",
					})}
					initiallyOpen={tagCategory !== null}
				/>
				<div
					css={{
						gap: 5,
						marginTop: 5,
						display: tagCategory && tagName ? "flex" : "none",
					}}
				>
					{renderEntityButton(
						"Include Records that match",
						filterBehavior === "inclusion",
						() => setFilterBehavior("inclusion"),
					)}
					{renderEntityButton(
						"Exclude Records that don't match",
						filterBehavior === "exclusion",
						() => setFilterBehavior("exclusion"),
					)}
				</div>
			</div>
		);
	};

	const renderTaskFilter = () => {
		return (
			<div>
				<Dropdown
					title="Task Name"
					options={allTasks}
					value={taskName}
					onChange={(value) => {
						setTaskName(value);
						setTaskCompletionStatus(null);
					}}
					colorTheme={colorTheme}
					containerCSS={css({ marginTop: 5 })}
					initiallyOpen={taskName === null}
				/>
				<Dropdown
					title="Task Completed"
					options={[true, false]}
					value={taskCompletionStatus}
					onChange={setTaskCompletionStatus}
					colorTheme={colorTheme}
					containerCSS={css({
						marginTop: 5,
						display: taskName ? "block" : "none",
					})}
					initiallyOpen={taskName !== null && taskCompletionStatus === null}
				/>
				<div
					css={{
						gap: 5,
						marginTop: 5,
						display:
							taskName && typeof taskCompletionStatus === "boolean"
								? "flex"
								: "none",
					}}
				>
					{renderEntityButton(
						"Include Records that match",
						filterBehavior === "inclusion",
						() => setFilterBehavior("inclusion"),
					)}
					{renderEntityButton(
						"Exclude Records that don't match",
						filterBehavior === "exclusion",
						() => setFilterBehavior("exclusion"),
					)}
				</div>
			</div>
		);
	};

	const renderControls = () => {
		return (
			<div
				css={{
					display: "flex",
					maxHeight: filterEntity ? "initial" : 0,
					overflow: "hidden",
					opacity: filterEntity ? 1 : 0,
					justifyContent: "flex-end",
					paddingTop: 10,
					gap: 5,
					transition: "all .5s ease",
				}}
			>
				<button
					type="button"
					disabled={disableSubmission}
					css={{
						fontFamily: PRIMARY_FONT,
						outline: "none",
						borderColor: colorTheme.mid,
						borderWidth: 0,
						borderStyle: "solid",
						borderRadius: 2,
						textTransform: "uppercase",
						fontWeight: 700,
						fontSize: 11,
						padding: 5,
						backgroundColor: "transparent",
						color: colorTheme.dark,
						transition: "all .33s ease",
						"&:not(:disabled):hover": {
							color: "white",
							backgroundColor: colorTheme.mid,
						},
						"&:disabled": {
							opacity: 0.5,
						},
					}}
					onClick={() => addEngagementFilter()}
				>
					Submit
				</button>
				<button
					type="button"
					css={{
						fontFamily: PRIMARY_FONT,
						outline: "none",
						borderWidth: 0,
						borderColor: colorTheme.mid,
						borderStyle: "solid",
						borderRadius: 2,
						color: colorTheme.dark,
						textTransform: "uppercase",
						backgroundColor: "transparent",
						fontWeight: 700,
						fontSize: 11,
						transition: "all .33s ease",
						":hover": {
							color: "white",
							backgroundColor: colorTheme.mid,
						},
					}}
					onClick={resetComponent}
				>
					Cancel
				</button>
			</div>
		);
	};

	return (
		<div
			id="add-filter-widget"
			css={{
				marginTop: 10,
				marginBottom: 10,
				height:
					observedHeight + (filterEntity || engagementFilters.length ? 60 : 40),
				transition: "all .33s ease",
				borderRadius: 5,
			}}
		>
			<h4
				css={{
					fontFamily: PRIMARY_FONT,
					color: portalColors.gray.dark,
					margin: 0,
					marginBottom: 5,
				}}
			>
				New Filter
			</h4>
			<div id="filter-entity-selection" css={{ display: "flex", gap: 5 }}>
				{renderEntityButton("tag", filterEntity === "tag", () =>
					setFilterEntity("tag"),
				)}
				{renderEntityButton("task", filterEntity === "task", () =>
					setFilterEntity("task"),
				)}
			</div>

			<div ref={observe}>
				{filterEntity === "tag"
					? renderTagFilter()
					: filterEntity === "task"
					? renderTaskFilter()
					: null}

				{renderControls()}
			</div>
		</div>
	);
}
