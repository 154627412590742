import { atom, getDefaultStore } from "jotai";
import type { Session, User } from "@supabase/supabase-js";
import { v4 as uuidv4 } from "uuid";

import supabaseClientAtom from "./supabaseClient";

const defaultAvatar = new URL(
	"../assets/images/defaultAvatar.jpg",
	import.meta.url,
).toString();

const supabaseUserAtom = atom<User | undefined>(undefined);
const supabaseSessionAtom = atom<Session | undefined>(undefined);
const supabaseUserIsAdminAtom = atom<boolean>(false);

const supabaseAuthErrorAtom = atom<string | null>(null);

const _userAvatarAtom = atom<Blob | string>(defaultAvatar);

/** Reference to user's current avatar image. */
const userAvatarAtom = atom(
	async (get) => {
		const client = get(supabaseClientAtom);
		const user = get(supabaseUserAtom);
		const _userAvatar = get(_userAvatarAtom);

		if (_userAvatar instanceof Blob) return _userAvatar;

		const userFiles = await client.storage.from("user-content").list(user?.id, {
			limit: 100,
			offset: 0,
			sortBy: { column: "name", order: "asc" },
		});

		const avatarImageRef = userFiles.data?.find((file) =>
			file.name.includes("avatar"),
		);

		if (avatarImageRef) {
			const downloadedAvatar = await client.storage
				.from("user-content")
				.download(`${user?.id}/${avatarImageRef!.name}`, {
					transform: { width: 300, height: 200, resize: "cover" },
				});

			return downloadedAvatar.data ? downloadedAvatar.data : _userAvatar;
		} else {
			return _userAvatar;
		}
	},
	async (get, set, arg: File) => {
		const client = get(supabaseClientAtom);
		const user = get(supabaseUserAtom);

		if (!user) return;

		// Step 1: Find and delete existing avatar files.
		const userFiles = await client.storage.from("user-content").list(user.id, {
			limit: 100,
			offset: 0,
			sortBy: { column: "name", order: "asc" },
		});

		const avatarImageRef = userFiles.data?.find((file) =>
			file.name.includes("avatar"),
		);

		if (avatarImageRef) {
			client.storage
				.from("user-content")
				.remove([`${user?.id}/${avatarImageRef!.name}`]);
		}

		// Step 2: Upload New Image
		const upload = await client.storage
			.from("user-content")
			.upload(`${user!.id}/avatar${uuidv4()}`, arg, {
				cacheControl: "3600",
				upsert: false,
			});

		if (upload.data) {
			const download = await client.storage
				.from("user-content")
				.download(upload.data.path, {
					transform: { width: 100, height: 75, resize: "cover" },
				});

			if (download.data) set(_userAvatarAtom, download.data);
		}
	},
);

/** Is the current user an admininstrator? */
const isCurrentUserAdmin = async (): Promise<boolean> => {
	const jotai = getDefaultStore();
	const client = jotai.get(supabaseClientAtom);
	const user = jotai.get(supabaseUserAtom);

	if (!user) return false;

	return (await client.rpc("is_current_user_admin", {})).data as boolean;
};

export {
	supabaseUserAtom,
	supabaseUserIsAdminAtom,
	supabaseSessionAtom,
	supabaseAuthErrorAtom,
	isCurrentUserAdmin,
	userAvatarAtom,
	_userAvatarAtom,
};
