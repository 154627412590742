export const WELCOME_MESSAGES = [
  "Hey, I know you! What’s new?",
  "What is it the kids say these days? ‘Sup dawg?’",
  "Whoa! Hey everyone, our favorite person is here!",
  "Welcome to the party! We were wondering when you’d be back.",
  "Congrats on remembering your password! I forget mine all the time…",
  "Oh, hey there! We missed you!",
  "It’s time to analyze the analytics.",
  "Yeah, you should get another cup of coffee. You deserve it.",
  "What’s your favorite dashboard? Mine is this one.",
  "With great analytics comes great responsibility.",
  "The first rule of the Form Films Portal is: you do not talk about the Form Films Portal.",
];
