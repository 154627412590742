import { animated, useSpring } from "@react-spring/web";
import { useEffect } from "react";

import { mediaQueries } from "../../../style/breakpoints";
import { ColorFamily, portalColors } from "../../../style/colors";
import { PRIMARY_FONT } from "../../../style/fonts";
import { CSSObject, SerializedStyles, css } from "@emotion/react";

type DataHighlightBoxProps = {
	primaryText: string | number;
	secondaryText: string;
	colorFamily: ColorFamily;
	dataRefreshing: boolean;
	prefix?: string;
	postfix?: string;
	primaryTextStyles?: CSSObject;
	secondaryTextStyles?: CSSObject;
	additionalStyles?: CSSObject;
};

export default function DataHighlightBox({
	primaryText,
	secondaryText,
	colorFamily,
	dataRefreshing,
	prefix,
	postfix,
	primaryTextStyles = {},
	secondaryTextStyles = {},
	additionalStyles = {},
}: DataHighlightBoxProps) {
	const [styles, animationAPI] = useSpring(() => ({
		from: {
			backgroundColor: portalColors.gray.light,
			textHeightTransform: 1,
		},
		config: { tension: 150, friction: 15 },
	}));

	useEffect(() => {
		if (dataRefreshing) {
			animationAPI.start({
				to: {
					backgroundColor: portalColors.gray.light,
					textHeightTransform: 0,
				},
				immediate: true,
				delay: 0,
			});
		} else {
			animationAPI.start({
				to: {
					backgroundColor: colorFamily.light,
					textHeightTransform: 1,
				},
				delay: 0,
			});
		}
	}, [dataRefreshing, animationAPI, colorFamily]);

	useEffect(() => {
		if (primaryText !== 0 && primaryText !== "0" && !dataRefreshing) {
			animationAPI.start({
				to: { textHeightTransform: 1 },
				delay: 250,
			});
		}

		return () => {
			animationAPI.start({
				to: { textHeightTransform: 0 },
				immediate: true,
			});
		};
	}, [primaryText, animationAPI, dataRefreshing]);

	return (
		<animated.div
			style={{ backgroundColor: styles.backgroundColor }}
			css={[
				{
					padding: 20,
					boxSizing: "border-box",
					transformOrigin: "left bottom",
					borderRadius: 5,
					pointerEvents: "none",
					height: "100%",
					width: "100%",
				},
				additionalStyles,
			]}
		>
			<div
				css={{
					fontFamily: PRIMARY_FONT,
					color: colorFamily.dark,
				}}
			>
				<div
					id="primary-text"
					css={{ display: "flex", alignItems: "flex-start", marginBottom: -15 }}
				>
					{prefix && (
						<animated.span
							style={{ scaleY: styles.textHeightTransform, translateY: "25%" }}
							css={[
								{
									fontWeight: "600",
									fontSize: "3vw",
									margin: 0,
									lineHeight: 1.2,
									[mediaQueries[0]]: {},
								},
								primaryTextStyles,
							]}
						>
							{prefix}
						</animated.span>
					)}
					<animated.h1
						style={{ scaleY: styles.textHeightTransform }}
						css={[
							{
								fontWeight: "700",
								fontSize: "6vw",
								margin: 0,
								lineHeight: 1.2,
								[mediaQueries[0]]: {},
							},
							primaryTextStyles,
						]}
					>
						{primaryText}
					</animated.h1>
					{postfix && (
						<animated.span
							style={{ scaleY: styles.textHeightTransform, translateY: "25%" }}
							css={[
								{
									fontWeight: "600",
									fontSize: "3vw",
									margin: 0,
									lineHeight: 1.2,
									[mediaQueries[0]]: {},
								},
								primaryTextStyles,
							]}
						>
							{postfix}
						</animated.span>
					)}
				</div>
				<animated.p
					style={{ scaleY: styles.textHeightTransform }}
					css={[
						{
							fontSize: 12,
							fontWeight: 500,
							textTransform: "uppercase",
							margin: 0,
						},
						secondaryTextStyles,
					]}
				>
					{secondaryText}
				</animated.p>
			</div>
		</animated.div>
	);
}
