import { useAtomValue } from "jotai";
import { CSSObject, SerializedStyles, css } from "@emotion/react";

import { portalColors } from "../../../style/colors";
import TreemapChart from "../../charts/Treemap";
import {
	osBreakdownAtom,
	refreshAnalyticsSystemDataAtom,
} from "../../../data/analytics";

type OperatingSystemsProps = {
	additionalCSS?: CSSObject;
};

// {
//   os: { name: "iOS", version: "16.3" },
//   device: { model: "iPhone", mobile: true, vendor: "Apple" },
//   browser: { name: "Instagram", version: "275", supported: true },
//   urlParameters: {},
// };

export default function OperatingSystems({
	additionalCSS = {},
}: OperatingSystemsProps) {
	const browsers = useAtomValue(osBreakdownAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	return (
		<TreemapChart
			dataRefreshing={dataRefreshing}
			additionalCSS={additionalCSS}
			depthToChart={1}
			observations={browsers}
			dataColors={[portalColors.yellow.dark, portalColors.gray.dark]}
			chartTitle={{
				callOut: "Operating Systems",
				text: "Distribution of Operating Systems used to Access Experience",
			}}
		/>
	);
}
