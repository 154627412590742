import { Suspense } from "react";
import useDimensions from "react-cool-dimensions";

import { midGray, portalColors } from "../../../style/colors";
import UserGreeting from "../UserGreeting";
import NavigatorLogo from "../../../assets/svg/navigatorLogo.svg?react";

import ProjectSelector from "../ProjectSelector";

import CurrentFeatures from "../CurrentFeatures";
import { useSetAtom } from "jotai";
import { globalNavDimensionsAtom } from "../../../data/componentDimensions";
import SquareEdgeButton from "../../widgets/SquareEdgeButton";
import useLogoutUser from "../../../hooks/useLogoutUser";
import Changelog from "../Changelog";
import OrganizationSelector from "../OrganizationSelector";

export default function GlobalNav() {
	const storeComponentDimensions = useSetAtom(globalNavDimensionsAtom);
	const { observe } = useDimensions({
		useBorderBoxSize: true,
		onResize: ({ width, height }) => storeComponentDimensions([width, height]),
	});

	const logoutUser = useLogoutUser();

	return (
		<div
			ref={observe}
			css={{
				width: 350,
				borderRightColor: midGray,
				borderRightWidth: 1,
				borderRightStyle: "solid",
				// padding: 30,
				boxSizing: "border-box",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<UserGreeting />
			<div
				css={{
					justifyContent: "space-between",
					display: "flex",
					flexDirection: "column",
					flex: 1,
					padding: 30,
					paddingTop: 0,
				}}
			>
				<div>
					<Suspense fallback={null}>
						<OrganizationSelector />
						<ProjectSelector />
					</Suspense>
					<CurrentFeatures />
				</div>
				<div
					css={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					{/* <Changelog /> */}
					<div css={{ height: 15 }} />

					<div
						css={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "flex-end",
						}}
					>
						<NavigatorLogo css={{ height: "4rem" }} />
						<SquareEdgeButton label="logout" onPress={logoutUser} />
					</div>
				</div>
			</div>
		</div>
	);
}
