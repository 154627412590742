import NavigatorLogo from "../../assets/svg/navigatorLogo.svg?react";
import { darkGray } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";
import { mediaQueries } from "../../style";

const jurassicPark = new URL("../../assets/images/ww-2.webp", import.meta.url);

export default function NoAssignedRoles() {
	return (
		<div
			css={{
				width: "100%",
				padding: 25,
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				boxSizing: "border-box",
				overflow: "hidden",
				[mediaQueries[1]]: {
					padding: 50,
				},
			}}
		>
			<div css={{ paddingTop: 25 }}>
				<div>
					<img
						src={jurassicPark.toString()}
						alt="Jurassic Park Meme"
						css={{
							width: "100%",
							height: "auto",
							[mediaQueries[1]]: {
								maxWidth: 450,
							},
						}}
					/>
					<h1
						css={{
							color: darkGray,
							fontFamily: PRIMARY_FONT,
							fontSize: 48,
							fontWeight: 800,
							maxWidth: 425,
							lineHeight: 0.7,
							marginBottom: 20,
						}}
					>
						Hello, <br />
						<span css={{ fontSize: 32 }}>Dreamer of Dreams.</span>
					</h1>
				</div>
				<h2
					css={{
						color: darkGray,
						fontFamily: PRIMARY_FONT,
						fontSize: 18,
						fontWeight: 500,
						maxWidth: 425,
					}}
				>
					Your account has been created successfully.
				</h2>
				<h3
					css={{
						color: darkGray,
						fontFamily: PRIMARY_FONT,
						fontSize: 14,
						fontWeight: 500,
						maxWidth: 425,
					}}
				>
					We manually assign data access roles to safeguard the data belonging
					to each of our clients.
				</h3>
				<h3
					css={{
						color: darkGray,
						fontFamily: PRIMARY_FONT,
						fontSize: 14,
						fontWeight: 500,
						maxWidth: 425,
					}}
				>
					As soon as we assign your permissions, we will send you an email and
					then you'll be able to taste all the sweets of this chocolate factory.
				</h3>
			</div>
			<div css={{ marginBottom: 0 }}>
				<NavigatorLogo css={{ height: "5rem" }} />
			</div>
		</div>
	);
}
