import { useMemo } from "react";
import { scaleLinear } from "@visx/scale";

/**
 * Return a linear scale for the given domain and range.
 * A linear scale maps a number to a numeric range.
 * */
export default function useLinearScale({
  domain,
  range,
}: {
  domain: number[];
  range: [number, number];
}) {
  return useMemo(() => {
    const scale = scaleLinear({
      domain,
      range,
      nice: true,
      clamp: true,
    });

    return scale;
  }, [domain, range]);
}
