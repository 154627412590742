import { CSSObject } from "@emotion/react";
import { useAtomValue } from "jotai";
import { animated } from "@react-spring/web";

import { darkGray } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";
import {
	currentNavigatorFeatureAtom,
	navigatorFeatures,
} from "../../data/feature";
import { SpringValue } from "@react-spring/web";
import { useState } from "react";

type SquareEdgeButtonProps = {
	label: string;
	onPress?: () => void;
	disabled?: boolean;
	animatedStyles?: { [key: string]: SpringValue };
	additionalStyles?: CSSObject;
	type?: HTMLButtonElement["type"];
};

export default function SquareEdgeButton({
	label,
	onPress = () => null,
	disabled = false,
	animatedStyles = {},
	additionalStyles = {},
	type = "button",
}: SquareEdgeButtonProps) {
	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);
	const [isHovered, setIsHovered] = useState(false);

	return (
		<animated.button
			onMouseOver={() => setIsHovered(true)}
			onMouseOut={() => setIsHovered(false)}
			type={type}
			style={
				isHovered && !disabled && Object.keys(animatedStyles).length
					? animatedStyles
					: {}
			}
			css={{
				transition: "all .25s ease",
				color: "white",
				paddingTop: 10,
				paddingBottom: 10,
				paddingLeft: 30,
				paddingRight: 30,
				backgroundColor: darkGray,
				outline: "none",
				border: "none",
				textTransform: "uppercase",
				fontFamily: PRIMARY_FONT,
				fontSize: 12,
				fontWeight: 600,
				opacity: disabled ? 0.5 : 1,
				borderRadius: 2,

				"&:hover": {
					backgroundColor: disabled
						? darkGray
						: navigatorFeatures[currentFeature].colors.mid,
				},
				...additionalStyles,
			}}
			onClick={onPress}
			disabled={disabled}
		>
			{label}
		</animated.button>
	);
}
