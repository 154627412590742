import Color from "color";

import { updateEngagementMetadata } from "../../../../../data/learningSystem/engagement";
import { PRIMARY_FONT, midTones } from "../../../../../style";
import { convertFromNamingConvention } from "../../../../../utils/text/namingConvention";
import StringData from "../StringData";

import Close from "../../../../../assets/svg/icons/close.svg?react";
import { useState } from "react";
import { cloneDeep, omit, set } from "lodash";
import { tagColor } from "../../../../../data/learningSystem/engagement/tags";
import { LS_Engagement } from "../../../../../data/learningSystem/types";

type TagProps = {
	observation: LS_Engagement;
	category: string;
	categoryIndex: number;
	name: string;
};

export default function Tag({
	observation,
	category,
	categoryIndex,
	name,
}: TagProps) {
	const [isHovered, setIsHovered] = useState(false);

	/**
	 * Delete a single tag from a category, and the entire
	 * category if no other tags remain.
	 */
	const deleteTag = async (category: string, name: string) => {
		const categoryTags = observation.data.metadata.tags?.[category];
		if (!categoryTags?.length) return;

		const updatedCategoryTags = categoryTags.filter((tag) => tag !== name);

		const updatedTaskMetadata = updatedCategoryTags.length
			? set(
					cloneDeep(observation.data.metadata),
					`tags[${category}]`,
					updatedCategoryTags,
			  )
			: omit(cloneDeep(observation.data.metadata), `tags[${category}]`);

		await updateEngagementMetadata(observation, updatedTaskMetadata);
	};

	return (
		<div
			key={`${category}-${name}`}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			css={{
				flex: 1,
				cursor: "default",
				backgroundColor: tagColor(category),
				padding: 10,
				borderRadius: 5,
				fontFamily: PRIMARY_FONT,
				position: "relative",
			}}
		>
			<span
				css={{
					color: "white",
					textTransform: "uppercase",
					fontWeight: 600,
					fontSize: 9,
					marginBottom: -1,
					display: "block",
				}}
			>
				{convertFromNamingConvention(category)}
			</span>
			<Close
				role="button"
				fill="white"
				width={14}
				height={14}
				viewBox="0 0 20 20"
				css={{
					position: "absolute",
					top: 7,
					right: 7,
					display: isHovered ? "initial" : "none",
					cursor: "pointer",
				}}
				onClick={() => deleteTag(category, name)}
			/>

			<StringData
				text={convertFromNamingConvention(name.toString())}
				colorTheme={{
					dark: "#FFFFFF",
					mid: "#FFFFFF",
					light: "#FFFFFF",
				}}
				size="small"
			/>
		</div>
	);
}
