import { useEffect, useMemo } from "react";
import { Property } from "csstype";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { localPoint } from "@visx/event";
import { Treemap, hierarchy, treemapBinary, stratify } from "@visx/hierarchy";
import { useTooltip } from "@visx/tooltip";
import { scaleLinear } from "@visx/scale";
import { animated, useTrail } from "@react-spring/web";
import { css } from "@emotion/react";
import type { HierarchyNode } from "d3-hierarchy";

import { PRIMARY_FONT } from "../../../style/fonts";
import { HIERARCHY_OBSERVATION } from "../../../types/charts";
import { portalColors } from "../../../style";
import { HIERARCHICAL_CHART_PROPS } from "../../../types/charts/props";
import { useAtomValue } from "jotai";
import { selectableEnclosingURLsAtom } from "../../../data/analytics";
import Tooltip from "../../widgets/Tooltip";
import useObserveChartComponents from "../../../data/hooks/useObserveChartComponents";
import useSVGDimensions from "../../../hooks/useSVGDimensions";
import ChartContainer from "../../ChartContainer";

export type TreemapTooltipData = {
	name: string;
	count: number;
	totalCount: number;
	percentage: string;
	highlightColor: Property.Color;
};

export default function TreemapChartV2({
	data,
	dataRefreshing,
	chartTitle,
	chartExplanation,
	colorTheme = portalColors.gray,
	transparentBackground = false,
	dataColors,
	chartDepth,
	margins = { horizontal: 20, vertical: 20 },
	additionalCSS = {},
}: HIERARCHICAL_CHART_PROPS) {
	const selectedPageEmbeds = useAtomValue(selectableEnclosingURLsAtom);

	const {
		observeComponent,
		componentWidth,
		componentHeight,
		observeTitle,
		titleHeight,
	} = useObserveChartComponents();

	// Determine the effective dimensions for the actual SVG element.
	const svgDimensions = useSVGDimensions({
		width: componentWidth,
		height: componentHeight,
		margins,
		heightSubstractors: [titleHeight],
	});

	const xMax = useMemo(() => svgDimensions[0] - 20, [svgDimensions[0]]);
	const yMax = useMemo(
		() => svgDimensions[1] - 20 - titleHeight,
		[svgDimensions[1], titleHeight],
	);

	const colorScale = useMemo(() => {
		const allValues = data
			.leaves()
			.filter((observation) => {
				return Object.hasOwn(observation.data.data, "count");
			})
			.map((observation) => observation.data.data.count) as Array<number>;

		return scaleLinear({
			domain: [Math.max(...allValues), Math.min(...allValues)],
			range: dataColors,
		});
	}, [data, dataColors]);

	const root = useMemo(
		() => hierarchy(data).sort((a, b) => (b.value || 0) - (a.value || 0)),
		[data],
	);

	const [trails, api] = useTrail(data.leaves().length, () => ({
		from: { scaleY: 0, opacity: 0 },
		to: { scaleY: 1, opacity: 1 },
		config: { tension: 250, friction: 25 },
	}));

	useEffect(() => {
		dataRefreshing
			? api.start({ to: { scaleY: 0, opacity: 0 }, immediate: true })
			: api.start({ to: { scaleY: 1, opacity: 1 } });
	}, [dataRefreshing, api]);

	useEffect(() => {
		api.start({ to: { scaleY: 1, opacity: 1 } });
		return () => {
			api.start({ to: { scaleY: 0, opacity: 0 }, immediate: true });
		};
	}, [selectedPageEmbeds, api.start]);

	const {
		tooltipData,
		tooltipLeft,
		tooltipTop,
		tooltipOpen,
		showTooltip,
		hideTooltip,
	} = useTooltip<TreemapTooltipData>();

	return (
		<ChartContainer
			colorTheme={colorTheme}
			margins={margins}
			transparentBackground={transparentBackground}
			observer={observeComponent}
			chartTitle={chartTitle}
			titleObserver={observeTitle}
			chartExplanation={chartExplanation}
		>
			<div
				css={{ position: "relative" }}
				onMouseOut={hideTooltip}
				onBlur={hideTooltip}
			>
				{tooltipOpen && tooltipData ? (
					<Tooltip
						title={tooltipData.name}
						subtitle={"Details"}
						positioning={{ top: tooltipTop, left: tooltipLeft }}
						observationColor={tooltipData.highlightColor}
						additionalData={{
							count: `${tooltipData.count}/${tooltipData.totalCount} Records`,
							percentage: tooltipData.percentage,
						}}
					/>
				) : null}

				<svg width={svgDimensions[0]} height={svgDimensions[1]}>
					<Treemap<HierarchyNode<HIERARCHY_OBSERVATION>>
						root={root}
						size={[svgDimensions[0], svgDimensions[1]]}
						tile={treemapBinary}
						round
						padding={0}
					>
						{(treemap) => (
							<Group>
								{treemap
									.descendants()
									// .reverse()
									.map((node, index) => {
										const nodeWidth = node.x1 - node.x0;
										const nodeHeight = node.y1 - node.y0;

										const nodeValue = node.data.value ?? 77;
										const rootValue = root.data.value ?? 144;

										return dataRefreshing ? null : (
											<Group
												top={node.y0}
												left={node.x0}
												key={`node-${index}-${Math.random()}`}
											>
												{node.depth === chartDepth && (
													<animated.g
														style={trails[index]}
														css={{ transformOrigin: "bottom" }}
													>
														<rect
															width={nodeWidth}
															height={nodeHeight}
															css={{}}
															stroke={"white"}
															strokeWidth={2}
															fill={colorScale(node.value || 0)}
															onMouseMove={(event) => {
																const coords = localPoint(
																	// @ts-ignore
																	event.target.ownerSVGElement,
																	event,
																);
																showTooltip({
																	tooltipLeft: coords?.x,
																	tooltipTop: coords?.y,
																	tooltipData: {
																		name: node.data.id ?? "Unknown",
																		count: node.data.value ?? 77,
																		totalCount: root.data.value ?? 144,
																		percentage: `${(
																			(nodeValue / rootValue) *
																			100
																		).toFixed(1)}%`,
																		highlightColor: colorScale(node.value || 0),
																	},
																});
															}}
															onMouseOut={hideTooltip}
															onBlur={hideTooltip}
															onMouseLeave={hideTooltip}
														/>
														<Text
															fill="white"
															fontFamily={PRIMARY_FONT}
															fontWeight={700}
															fontSize={14}
															verticalAnchor="start"
															dx={15}
															dy={15}
															css={{
																pointerEvents: "none",
															}}
														>
															{`${((nodeValue / rootValue) * 100).toFixed(1)}%`}
														</Text>
														<Text
															fill="white"
															fontFamily={PRIMARY_FONT}
															fontWeight={500}
															fontSize={nodeWidth - 25 > 100 ? 13 : 10}
															dx={15}
															dy={30}
															width={nodeWidth - 25}
															verticalAnchor="start"
															// scaleToFit
															css={{
																pointerEvents: "none",
																textTransform: "uppercase",
															}}
														>
															{node.data.id}
														</Text>
													</animated.g>
												)}
											</Group>
										);
									})}
							</Group>
						)}
					</Treemap>
				</svg>
			</div>
		</ChartContainer>
	);
}
