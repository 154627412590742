import { css } from "@emotion/react";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";

// Assets
import GlobeIcon from "../../assets/svg/icons/globe.svg?react";

// Components
import MultipleChoiceOption from "./MultipleChoiceOption";
import SquareEdgeButton from "./SquareEdgeButton";

// Data & Definitions
import {
	darkGray,
	midGray,
	portalColors,
	PRIMARY_FONT,
	SECONDARY_FONT,
} from "../../style";
import { selectableEnclosingURLsAtom } from "../../data/analytics";
import { every } from "lodash";

type EnclosingURL = {
	url: string;
	domain: string;
	extension: string;
	selected: boolean;
};

export default function EnclosingURLSelector() {
	// Define the states for the URLs
	const [unmutableCurrentEnclosingURLs, setUnmutableCurrentEnclosingURLs] =
		useAtom(selectableEnclosingURLsAtom);

	// Store pending selections updates.
	// We execute updates in batches to cut down on data reloading.
	const [mutableEnclosingURLs, setMutableEnclosingURLs] = useState<
		EnclosingURL[]
	>(unmutableCurrentEnclosingURLs);

	// Widget active state
	const [widgetActive, setWidgetActive] = useState(false);

	// Whenever Jotai storage changes, sync the local data.
	useEffect(() => {
		setMutableEnclosingURLs(unmutableCurrentEnclosingURLs);
	}, [unmutableCurrentEnclosingURLs]);

	// Reduce the URLs to be indexed by domain
	const enclosingURLsReduction = useMemo(() => {
		return mutableEnclosingURLs.reduce(
			(previous, current) => ({
				...previous,
				[current.domain]: previous[current.domain]
					? [
							...previous[current.domain],
							{
								extension: current.extension,
								url: current.url,
								selected: current.selected,
							},
					  ]
					: [
							{
								extension: current.extension,
								url: current.url,
								selected: current.selected,
							},
					  ],
			}),
			{} as {
				[key: string]: Array<{
					extension: string;
					url: string;
					selected: boolean;
				}>;
			},
		);
	}, [mutableEnclosingURLs]);

	// Define the handlers for select all and deselect all URLs
	const selectAllURLs = () => {
		setMutableEnclosingURLs(
			unmutableCurrentEnclosingURLs.map((url) => ({ ...url, selected: true })),
		);
	};

	const deselectAllURLs = () => {
		setMutableEnclosingURLs(
			unmutableCurrentEnclosingURLs.map((url) => ({ ...url, selected: false })),
		);
	};

	// Disallow updates that would result in no page urls being selected.
	const updateDisabled = useMemo(
		() => every(mutableEnclosingURLs, (url) => url.selected === false),
		[mutableEnclosingURLs],
	);

	// Define the value to be displayed
	const displayValue = useMemo(() => {
		const activeURLs = unmutableCurrentEnclosingURLs.filter(
			(url) => url.selected,
		);

		if (activeURLs.length === unmutableCurrentEnclosingURLs.length) {
			return "All Embedding Pages";
		} else if (activeURLs.length > 1) {
			return "Multiple Pages";
		} else {
			return activeURLs[0].url;
		}
	}, [unmutableCurrentEnclosingURLs]);

	// Define CSS for the buttons
	const buttonCSS = css({
		border: "none",
		outline: "none",
		backgroundColor: "transparent",
		fontFamily: PRIMARY_FONT,
		color: darkGray,
		fontWeight: 600,
	});

	return (
		<div
			id="enclosing-url-selector"
			css={{
				zIndex: 100,
				position: "relative",
			}}
		>
			<div
				onClick={() => setWidgetActive((currentValue) => !currentValue)}
				css={{
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
				}}
			>
				<div
					css={{
						display: "flex",
						alignItems: "center",
						backgroundColor: !widgetActive ? darkGray : portalColors.orange.mid,
						transition: "background-color .33s ease",
						padding: 5,
						paddingLeft: 10,
						paddingRight: 10,
						borderStyle: "solid",
						borderColor: widgetActive ? portalColors.orange.mid : midGray,
						borderWidth: 1,
						boxSizing: "border-box",
						borderTopLeftRadius: 5,
						borderBottomLeftRadius: 5,
						borderRightWidth: 0,
					}}
				>
					<GlobeIcon css={{ fill: "white" }} />
					<span
						css={[
							buttonCSS,
							{ fontSize: 11, color: "white", fontWeight: 600, marginLeft: 5 },
						]}
					>
						PAGE URL
					</span>
				</div>
				<span
					css={{
						flex: 1,
						borderStyle: "solid",
						borderColor: widgetActive ? portalColors.orange.mid : midGray,
						borderWidth: 1,
						boxSizing: "border-box",
						borderLeftWidth: 0,
						borderTopRightRadius: 5,
						borderBottomRightRadius: 5,
						padding: 10,
						fontSize: 11,
						fontFamily: PRIMARY_FONT,
						fontWeight: 600,
						textTransform: "uppercase",
						fontStyle: "italic",
						color: portalColors.gray.dark,
					}}
				>
					{displayValue}
				</span>
			</div>
			{widgetActive && (
				<div
					css={{
						position: "absolute",
						top: 36,
						backgroundColor: "white",
						borderStyle: "solid",
						borderColor: midGray,
						borderWidth: 1,
						borderTopWidth: 5,
						borderTopColor: portalColors.orange.dark,
						padding: 10,
						// width: "100%",
						boxSizing: "border-box",
					}}
				>
					{Object.entries(enclosingURLsReduction).map((domain) => (
						<div css={{ marginBottom: 15 }} key={domain[0]}>
							<p
								css={{
									fontFamily: SECONDARY_FONT,
									fontSize: 12,
									fontWeight: 600,
									color: portalColors.gray.dark,
									marginBottom: 2,
									marginTop: 0,
								}}
							>
								{domain[0]}
							</p>
							<div
								css={{
									display: "grid",
									gridTemplateColumns: "1fr",
									rowGap: 5,
									marginLeft: 5,
								}}
							>
								{domain[1].map((page) => (
									<MultipleChoiceOption
										key={page.url}
										text={page.extension}
										selected={page.selected}
										onClick={() =>
											setMutableEnclosingURLs((updates) => {
												const newSelectionUpdates = updates.filter(
													(update) => update.url !== page.url,
												);
												newSelectionUpdates.push({
													domain: domain[0],
													...page,
													selected: !page.selected,
												});
												newSelectionUpdates.sort((a, b) =>
													a.url.localeCompare(b.url),
												);
												return newSelectionUpdates;
											})
										}
									/>
								))}
							</div>
						</div>
					))}
					<div
						css={{
							display: "grid",
							gridTemplateColumns: "135px 135px",
							gap: 5,
							marginBottom: 5,
						}}
					>
						<SquareEdgeButton label="Select All" onPress={selectAllURLs} />
						<SquareEdgeButton label="Clear All" onPress={deselectAllURLs} />
					</div>
					<SquareEdgeButton
						label="Update Data"
						disabled={updateDisabled}
						additionalStyles={{ width: "100%", height: 50 }}
						onPress={() => {
							setWidgetActive(false);
							setUnmutableCurrentEnclosingURLs(mutableEnclosingURLs);
						}}
					/>
				</div>
			)}
		</div>
	);
}
