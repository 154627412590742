import { atom } from "jotai";

import { AS_eventsObservationsFilteredByEnclosingURLsAtom } from "../analytics/events";
import { TransferCartEvent } from "./types";
import {
	startedExperiencesAtom,
	startedExperiencesGroupedByDateAtom,
} from "../analytics/experience";
import {
	_makeObservation,
	_makeStringBasedObservation,
} from "../../utils/observation";
import { OBSERVATION_WITH_SINGLE_NUMERIC_VALUE } from "../../types/charts";
import { DateTime } from "luxon";

/**
 * Obtain all transfer_cart_data events.
 * Filtered by the currently selected enclosing URLs.
 * */
export const AS_TransferCartEvents = atom((get) => {
	const allEvents = get(AS_eventsObservationsFilteredByEnclosingURLsAtom);

	const transferCartEvents = allEvents.filter(
		(event) => event.data.event_name === "transfer_cart_data",
	) as TransferCartEvent[];

	return transferCartEvents;
});

/** Total cart value transfered to a commerce platform. */
export const AS_AllCartsValue = atom((get) => {
	const transferCartEvents = get(AS_TransferCartEvents);

	const sumOfAllCarts = transferCartEvents.reduce((allCartsTotal, current) => {
		const currentCartTotal = current.data.data.reduce(
			(currentCartTotal, currentItem) =>
				currentCartTotal +
				(typeof currentItem.price === "object"
					? currentItem.price.final
					: currentItem.price),
			0,
		);

		return allCartsTotal + currentCartTotal;
	}, 0);

	return _makeObservation(
		{ label: "sumOfAllTransferedCarts", value: +sumOfAllCarts.toFixed(0) },
		"label",
	);
});

/** Total cart value transferred to a commerce platform. */
export const AS_AverageCartValue = atom((get) => {
	const transferCartEvents = get(AS_TransferCartEvents);
	const allCartsValue = get(AS_AllCartsValue);

	return _makeObservation(
		{
			label: "averageTransferredCartValue",
			value: +(allCartsValue.data.value / transferCartEvents.length).toFixed(0),
		},
		"label",
	);
});

/** What percentage of started experiences transferred cart data? */
export const AS_TransferCartPercentage = atom((get) => {
	const startedExperiences = get(startedExperiencesAtom);
	const transferCartEvents = get(AS_TransferCartEvents);

	const uniqueExperiencesWithTransferCartEvents = [
		...new Set(transferCartEvents.map((event) => event.data.experience_id)),
	];

	const overallPercentage = _makeStringBasedObservation(
		{
			label: "overallPercentage",
			value:
				uniqueExperiencesWithTransferCartEvents.length /
				startedExperiences.length,
		},
		"label",
	);
	return overallPercentage;
});

/** Percentage of started experiences that transferred cart data grouped by date. */
export const AS_TransferCartDailyPercentage = atom((get) => {
	const startedExperiencesByDate = get(startedExperiencesGroupedByDateAtom);
	const transferCartEvents = get(AS_TransferCartEvents);

	const uniqueExperiencesWithTransferCartEvents = [
		...new Set(transferCartEvents.map((event) => event.data.experience_id)),
	];

	/**
	 * Determine the percentage of started experiences that
	 * have transferCart events by date.
	 * */
	const dailyPercentages = Object.entries(startedExperiencesByDate).reduce<
		Array<OBSERVATION_WITH_SINGLE_NUMERIC_VALUE>
	>((previous, current) => {
		const dailyCount = current[1].reduce((previous, current) => {
			return uniqueExperiencesWithTransferCartEvents.includes(
				current.observationLabel,
			)
				? previous + 1
				: previous;
		}, 0);

		return [
			...previous,
			{
				observationLabel: DateTime.fromISO(current[0]),
				data: { value: dailyCount / current[1].length },
			},
		];
	}, []);

	return dailyPercentages;
});
