import { useMemo } from "react";

/**  Determine the effective dimensions for the actual SVG element. */
export default function useSVGDimensions({
	width,
	height,
	margins,
	widthSubstractors = [],
	heightSubstractors = [],
}: {
	width: number;
	height: number;
	margins: {
		vertical: number;
		horizontal: number;
	};
	widthSubstractors?: number[];
	heightSubstractors?: number[];
}) {
	/**
	 * The effective dimensions of an SVG are determined by taking it's
	 * enclosing component's width and height, and then reducing these
	 * values by specified margins and/or other "subtractors".
	 *
	 * Note that we prevent the returned dimensions from being negative
	 * values, which can occur during component initialization.
	 * */
	return useMemo(
		() =>
			[
				Math.max(
					0,
					widthSubstractors.reduce(
						(a, b) => a - b,
						width - margins.horizontal * 2,
					),
				),
				Math.max(
					0,
					heightSubstractors.reduce(
						(a, b) => a - b,
						height - margins.vertical * 2,
					),
				),
			] as const,
		[width, widthSubstractors, height, heightSubstractors, margins],
	);
}
