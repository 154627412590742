import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type EngagementFilter = { behavior: "inclusion" | "exclusion" } & (
	| {
			entity: "tag";
			tag: { category: string; name: string };
	  }
	| {
			entity: "task";
			task: { name: string; completed: boolean };
	  }
);

export const engagementFiltersAtom = atomWithStorage<EngagementFilter[]>(
	"engagementFilters",
	[],
);
export const separatedEngagementFiltersAtom = atom((get) => {
	const engagementFilters = get(engagementFiltersAtom);

	return {
		inclusionTags: engagementFilters.filter(
			(filter) => filter.behavior === "inclusion" && filter.entity === "tag",
		),
		inclusionTasks: engagementFilters.filter(
			(filter) => filter.behavior === "inclusion" && filter.entity === "task",
		),
		exclusionTags: engagementFilters.filter(
			(filter) => filter.behavior === "exclusion" && filter.entity === "tag",
		),
		exclusionTasks: engagementFilters.filter(
			(filter) => filter.behavior === "exclusion" && filter.entity === "task",
		),
	};
});
