import Color from "color";
import { Property } from "csstype";

export type ColorFamily = {
  dark: Property.Color;
  mid: Property.Color;
  light: Property.Color;
};

export const offWhite = "#FFF8F3";
export const midGray = "#DCDEDE";
export const darkGray = "#4C4E56";

export const ffBlack = "#101020";
export const ffOrange = "#ED8B00";

export const errorRED = "#C02F2F";

export const portalColors: {
  [key in
    | "gray"
    | "red"
    | "orange"
    | "yellow"
    | "tan"
    | "green"
    | "cyan"
    | "blue"
    | "purple"
    | "magenta"]: ColorFamily;
} = {
  gray: {
    dark: "#7F7F7F",
    mid: "#A7A7A7",
    light: "#EDEDED",
  },
  red: {
    dark: "#B91515",
    mid: "#CF2727",
    light: "#F3C9C9",
  },
  orange: {
    dark: "#E55700",
    mid: ffOrange,
    light: "#FBE2BF",
  },
  yellow: {
    dark: "hsl(50, 100%, 35%)",
    mid: "hsl(50, 90%, 45%)",
    light: "hsl(50, 90%, 85%)",
  },
  tan: {
    dark: "#979268",
    mid: "#B7B497",
    light: "#EDECE5",
  },
  green: {
    dark: "#2CAB27",
    mid: "#50C546",
    light: "#D3F1D1",
  },
  cyan: {
    dark: "#00C2C2",
    mid: "#00D5D5",
    light: "#BFF5F5",
  },
  blue: {
    dark: "#0088BE",
    mid: "#00ADD2",
    light: "#BFEAF4",
  },
  purple: {
    dark: "#8210FF",
    mid: "#A91EFF",
    light: "#E9C7FF",
  },
  magenta: {
    dark: "#B5336A",
    mid: "#CC5C98",
    light: "#F2D6E5",
  },
};

export const mixColorFamilies = (
  colorFamilyOne: ColorFamily,
  colorFamilyTwo: ColorFamily,
  mixPercentage: number
): ColorFamily => {
  return {
    light: Color(colorFamilyOne.light)
      .mix(Color(colorFamilyTwo.light), mixPercentage)
      .hex(),
    mid: Color(colorFamilyOne.mid)
      .mix(Color(colorFamilyTwo.mid), mixPercentage)
      .hex(),
    dark: Color(colorFamilyOne.dark)
      .mix(Color(colorFamilyTwo.dark), mixPercentage)
      .hex(),
  };
};

export const generateColorOffsetArray = (
  startingColor: Color | Property.Color,
  offset: number,
  numberOfColors: number
) => {
  const colors = [];

  let currentColor = Color(startingColor);

  for (let i = 0; i < numberOfColors; i++) {
    // Push the current color into the array
    colors.push(currentColor.hex());

    // Adjust the hue by the offset
    currentColor = currentColor.rotate(offset);
  }

  return colors;
};

export const midTones = [
  portalColors.blue.mid,
  portalColors.cyan.mid,
  portalColors.green.mid,
  portalColors.yellow.mid,
  portalColors.orange.mid,
  portalColors.red.mid,
  portalColors.magenta.mid,
  portalColors.purple.mid,
];
