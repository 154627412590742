import { CSSProperties } from "react";
import { Group } from "@visx/group";
import { AxisBottom } from "@visx/axis";
import { Text } from "@visx/text";
import { ScaleBand } from "d3-scale";
import { DateTime } from "luxon";

type CustomBottomAxisProps = {
	yPosition: number;
	dateScale: ScaleBand<DateTime>;
	color: CSSProperties["color"];
};

export default function CustomDateTimeBottomAxis({
	yPosition: chartHeight,
	dateScale,
	color,
}: CustomBottomAxisProps) {
	return (
		<AxisBottom
			top={chartHeight}
			scale={dateScale}
			tickFormat={(value, index, allValues) => {
				if (
					index &&
					allValues[index].value.month === allValues[index - 1].value.month
				) {
					return `${value.toFormat("d")}`;
				}

				return `${value.toFormat("d")}-${value.toFormat("LLL")}`;
			}}
			stroke={color}
			numTicks={dateScale.domain().length}
			tickStroke="transparent"
			tickLabelProps={(value, index) => ({
				fill: color,
				fontSize: 11,
				textAnchor: "middle",
			})}
			tickComponent={(props) => {
				const { formattedValue, ...remainingProps } = props;
				const splitParts = props.formattedValue?.split("-");

				return (
					<Group>
						{splitParts?.map((part, index) => (
							<Text
								key={part}
								{...remainingProps}
								fill={color}
								// dx={(xScale.bandwidth() / 2) * -1}
								dy={index * 12}
								fontWeight={index === 1 ? 600 : undefined}
							>
								{part}
							</Text>
						))}
					</Group>
				);
			}}
		/>
	);
}
