import { atom, getDefaultStore } from "jotai";
import { AtlasEngineDatabase } from "@form-films/atlas-engine-shared-definitions";

import supabaseClientAtom from "./supabaseClient";
import {
	learningSystemAtom,
	refreshLearningSystemDataAtom,
} from "./learningSystem";
import { readyForNavigatorInteractionAtom } from "./general";
import { supabaseUserAtom } from "./user";

/**
 * What clients is the current user associated with.
 * RLS in place on database side.
 * */
export const userOrganizationsAtom = atom(async (get) => {
	const learningSystem = get(learningSystemAtom);
	const user = get(supabaseUserAtom);
	const readyForNavigatorInteraction = get(readyForNavigatorInteractionAtom);
	if (!learningSystem || !user || !readyForNavigatorInteraction) return [];

	const userOrganizations = await learningSystem().currentUserOrganizations();

	if (userOrganizations.length) {
		const avoidCaliK9IfPossible = userOrganizations.find(
			(organization) => organization.name === "CaliK9",
		);

		getDefaultStore().set(
			selectedOrganizationAtom,
			avoidCaliK9IfPossible && userOrganizations.length > 1
				? userOrganizations[1]
				: userOrganizations[0],
		);
	}

	return userOrganizations;
});

/**
 * The currently selected organization.
 *
 * SIDE EFFECT: When changed, will send a signal
 * to kick off data refreshes.
 * */
export const selectedOrganizationAtom = atom<
	AtlasEngineDatabase["public"]["Tables"]["clients"]["Row"] | null,
	[AtlasEngineDatabase["public"]["Tables"]["clients"]["Row"] | null],
	void
>(null, async (get, set, value) => {
	if (value) {
		set(selectedOrganizationAtom, value);
		set(refreshLearningSystemDataAtom, true);
	}
});

/**
 * What clients is the current user associated with.
 * RLS in place on database side.
 * */
export const userOrganizationIDsAtom = atom(async (get) => {
	const userOrganizations = await get(userOrganizationsAtom);
	return userOrganizations.map((organization) => organization.id);
});
