import { NavigatorFeatureNames, navigatorFeatures } from "../../data/feature";
import useUserOnSmallScreen from "../../hooks/useUserOnSmallScreen";
import { darkGray } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";

type FeatureButtonProps = {
	label: NavigatorFeatureNames;
	onPress: () => void;
	active: boolean;
};

export default function FeatureButton({
	label,
	onPress,
	active,
}: FeatureButtonProps) {
	const Icon = navigatorFeatures[label].icon;
	const smallScreen = useUserOnSmallScreen();

	return (
		<div
			css={{
				height: active ? 50 : 35,
				display: "flex",
				alignItems: "center",
				position: "relative",
				cursor: "default",
				borderRadius: 2,
				overflow: "hidden",
			}}
			onClick={onPress}
		>
			<div
				css={{
					width: smallScreen ? 20 : 15,
					height: active ? 50 : 35,
					backgroundColor: navigatorFeatures[label].colors.mid,
					position: "relative",
					zIndex: 1,
					transition: "all .2s",
					transitionTimingFunction: "ease-out",
				}}
			/>
			<div
				css={{
					width: active ? "100%" : 0,
					height: active ? 50 : 35,
					backgroundColor: navigatorFeatures[label].colors.light,
					position: "absolute",
					zIndex: 0,
					transition: "all .2s ease-out",
					opacity: 0.5,
				}}
			/>

			<div
				css={{
					position: "relative",
					left: 15,
					display: "flex",
					alignItems: "center",
					zIndex: 5,
				}}
			>
				<Icon width={24} height={24} fill={darkGray} />
				<span
					css={{
						fontFamily: PRIMARY_FONT,
						color: darkGray,
						fontSize: 12,
						fontWeight: active ? 700 : 500,
						marginLeft: 5,
						textTransform: "uppercase",
					}}
				>
					{label}
				</span>
			</div>
		</div>
	);
}
