import { Getter, Setter, atom } from "jotai";

import { learningSystemAtom } from ".";
import { convertToObservationsWithStringLabels } from "../../utils/observation";
import { LS_UserProfile } from "./types";

export const LS_userProfiles = atom<LS_UserProfile[]>([]);

/** Update the available courses from the database. */
export async function updateLearningSystemUserProfiles(
	get: Getter,
	set: Setter,
) {
	const learningSystem = get(learningSystemAtom);

	if (!learningSystem || !learningSystem().supabaseClient) return;

	const { data, error } =
		await learningSystem().supabaseClient!.rpc("user_profiles");

	if (error) throw Error(`Could not retrive user profiles: ${error}`);

	set(LS_userProfiles, convertToObservationsWithStringLabels(data, "id"));
}
