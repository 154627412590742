import { type Getter, type Setter, atom } from "jotai";
import type { AtlasEngineDatabase } from "@form-films/atlas-engine-shared-definitions";

import { selectableEnclosingURLsAtom } from "./enclosingURL";
import { gatherRecords } from "../../utils/utilities";
import { selectedDateRangeAtom } from "../../dateTime";
import supabaseClientAtom from "../../supabaseClient";
import { currentAtlasProjectAtom } from "../../project";
import { convertToObservationsWithStringLabels } from "../../../utils/observation";
import type { OBSERVATION_WITH_STRING_LABEL } from "../../../types/charts";

export type AtlasExperienceObservation = OBSERVATION_WITH_STRING_LABEL<
	Omit<
		AtlasEngineDatabase["public"]["Views"]["ie_experience_summary_mv"]["Row"],
		"experience_id"
	>
>;

/**
 * All experiences for the current project retrieved from the database.
 * */
export const analyticsExperiencesAtom = atom<AtlasExperienceObservation[]>([]);

export async function updateAnalyticsExperiences(get: Getter, set: Setter) {
	const selectedDateRange = get(selectedDateRangeAtom);
	const supabaseClient = get(supabaseClientAtom);
	const selectedProject = get(currentAtlasProjectAtom);

	if (!selectedProject) {
		set(analyticsExperiencesAtom, []);
		return;
	}

	const records = await gatherRecords<
		AtlasEngineDatabase["public"]["Views"]["ie_experience_summary_mv"]["Row"]
	>(
		supabaseClient
			.from("ie_experience_summary_mv")
			.select("*")
			.order("created_at")
			.eq("project_id", selectedProject.id)
			.gte("created_at", selectedDateRange.from.toUTC())
			.lte("created_at", selectedDateRange.to.toUTC()),
	);

	const observations = convertToObservationsWithStringLabels(
		records,
		"experience_id",
	);

	set(analyticsExperiencesAtom, observations);
}

/**
 * All experiences for the current project that were embedded
 * on pages selected by the user.
 *
 * This will form the foundation of many other queries.
 * */
export const experiencesFilteredByEnclosingURLAtom = atom((get) => {
	const allExperiences = get(analyticsExperiencesAtom);
	const enclosingURLs = get(selectableEnclosingURLsAtom);

	const selectedEnclosingURLs = enclosingURLs
		.filter((enclosingURL) => enclosingURL.selected)
		.map((enclosingURL) => enclosingURL.url);

	const filteredExperiences = allExperiences.filter((experience) => {
		const experienceEnclosingUrl = experience.data.enclosing_url ?? "unknown";

		let enclosingUrlSelected = false;

		for (const selectedEnclosingURL of selectedEnclosingURLs) {
			const prepareSpecialCharacters = selectedEnclosingURL.replace(
				/[?&]/g,
				"\\$&",
			);
			const regex = new RegExp(
				`^(?:https?:\/\/)?${prepareSpecialCharacters}[\/]?$`,
			);

			if (experienceEnclosingUrl.match(regex)) {
				enclosingUrlSelected = true;
			}
		}

		return enclosingUrlSelected;
	});

	return filteredExperiences;
});

export * from "./enclosingURL";
export * from "./mobileDesktopComparisons";
export * from "./loadedExperiences";
export * from "./startedExperiences";
