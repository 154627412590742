import { Getter, Setter, atom } from "jotai";

import { supabaseClientAtom, supabaseUserAtom } from "../analytics";
import { selectedOrganizationAtom } from "../organization";

import {
	OBSERVATION_WITH_STRING_LABEL,
	OBSERVATION_WITH_SINGLE_NUMERIC_VALUE,
} from "../../types/charts";
import { allDatesInUserSelectionAtom } from "../dateTime";
import { groupByDate } from "../../utils/groupBy";
import { convertToObservationsWithStringLabels } from "../../utils/observation";
import { learningSystemAtom } from ".";
import { Json } from "@form-films/atlas-engine-shared-definitions/dist/database";

type UserObservation = OBSERVATION_WITH_STRING_LABEL<
	Omit<
		{
			user_id: string;
			user_creation_timestamp: string;
			email: string;
			user_meta_data: Json;
			organization_name: string;
			organization_id: string;
			roles: Array<{
				name: string;
				id: string;
			}>;
		},
		"user_id"
	>
>;

export async function updateLearningSystemUsers(get: Getter, set: Setter) {
	const user = get(supabaseUserAtom);
	const currentOrganization = get(selectedOrganizationAtom);
	const learningSystem = get(learningSystemAtom);

	if (!user || !currentOrganization || !learningSystem) return [];

	try {
		const organizationUsers = await learningSystem().organizationUsers({
			organizationID: currentOrganization.id,
		});

		const asObservations = convertToObservationsWithStringLabels(
			organizationUsers,
			"user_id",
		);

		set(LS_UsersAtom, asObservations);
	} catch (error: any) {
		throw Error("Navigator: Could not retrieve organization users.", error);
	}
}

/**
 * All organization users for currently selected organization
 * that the authenticated user has privileges to see.
 */
export const LS_UsersAtom = atom<UserObservation[]>([]);

export const LS_UsersByEnrollmentDateAtom = atom<
	OBSERVATION_WITH_SINGLE_NUMERIC_VALUE[]
>((get) => {
	const learningSystemUsers = get(LS_UsersAtom);
	const selectedDateRange = get(allDatesInUserSelectionAtom);

	const usersByCreationDate = groupByDate(
		learningSystemUsers,
		"user_creation_timestamp",
	);

	return selectedDateRange.map((date) => {
		const dateAsString = date.toISODate();

		if (!dateAsString) throw new Error(`Could not convert ${date} to string.`);

		return {
			observationLabel: date,
			data: {
				value: usersByCreationDate[dateAsString]
					? usersByCreationDate[dateAsString].length
					: 0,
			},
		};
	});
});
