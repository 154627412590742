import { Getter, Setter, atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { LearningSystemCourse } from "@form-films/atlas-engine-learning-system";

import { learningSystemAtom } from ".";
import { selectedOrganizationAtom } from "../organization";
import { differenceWith } from "lodash";
import { OBSERVATION_WITH_STRING_LABEL } from "../../types/charts";
import { convertToObservationsWithStringLabels } from "../../utils/observation";

type CourseObservation = OBSERVATION_WITH_STRING_LABEL<
	Omit<LearningSystemCourse<string>, "id">
>;

/**
 * Get all available courses for the authenticated user.
 *
 * RLS policies will only allow the user to SELECT courses
 * that belong to organizations the user is also associated with.
 *
 * Will update whenever the current organization is updated.
 * */
export const LS_CoursesAtom = atom<Array<CourseObservation>>([]);

const _LS_SelectedCoursesBaseAtom = atomWithStorage<Array<CourseObservation>>(
	"selectedCourses",
	[],
);

/**
 * Courses that have been selected by the user.
 *
 * Defaults to all organization courses whenever the organization updates.
 * */
export const LS_SelectedCoursesAtom = atom<
	Array<CourseObservation>,
	[Array<CourseObservation>],
	void
>(
	(get) => {
		/**
		 * I need to determine the circumstances which should return the default
		 * value instead of the overridden value.
		 *
		 * 1. When the current value is an empty array.
		 * 2. When one or more of the currently selected courses
		 * 		cannot be found in the default - this means the data
		 * 		from the database has been updated.
		 */

		const defaultValue = get(LS_OrganizationalCoursesAtom);
		const currentValue = get(_LS_SelectedCoursesBaseAtom);

		return !currentValue.length ||
			differenceWith(
				currentValue,
				defaultValue,
				(a, b) => a.observationLabel === b.observationLabel,
			).length
			? defaultValue
			: currentValue;
	},
	(get, set, newValues) => {
		set(_LS_SelectedCoursesBaseAtom, newValues);
	},
);

/** Available courses that belong to currently selected organization. */
export const LS_OrganizationalCoursesAtom = atom<Array<CourseObservation>>(
	(get) => {
		const selectedOrganization = get(selectedOrganizationAtom);

		if (!selectedOrganization) return [];

		return get(LS_CoursesAtom).filter((course) => {
			// Get a list of all organizations associated with the course.
			const courseOrganizationIDs = Array.isArray(
				course.data.learning_system_organization_course,
			)
				? course.data.learning_system_organization_course.map(
						(course) => course.organization_id,
				  )
				: [];

			// Return only courses that belong to the currently selected organization.
			return courseOrganizationIDs.includes(selectedOrganization.id);
		});
	},
);

/** Update the available courses from the database. */
export async function updateLearningSystemCourses(get: Getter, set: Setter) {
	const learningSystem = get(learningSystemAtom);

	if (!learningSystem) return [];

	const courses = await learningSystem().getAvailableCourses();
	courses.sort((a, b) => a.display_name.localeCompare(b.display_name));

	set(LS_CoursesAtom, convertToObservationsWithStringLabels(courses, "id"));
}
