import { ColorFamily } from "../../../../style";

export type StringDataProps = {
	text: string | undefined;
	colorTheme: ColorFamily;
	size?: "small" | "medium" | "large";
};

export default function StringData({
	text,
	colorTheme,
	size = "medium",
}: StringDataProps) {
	return (
		<p
			css={{
				margin: 0,
				fontSize: size === "small" ? 12 : 14,
				color: colorTheme.dark,
				fontWeight: 600,
				textTransform: "capitalize",
			}}
		>
			{text}
		</p>
	);
}
