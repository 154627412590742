import { atom } from "jotai";

import { OBSERVATION_WITH_DATE_LABEL } from "../../../types/charts";
import {
	AtlasExperienceObservation,
	loadedExperiencesAtom,
	startedExperiencesAtom,
} from ".";
import { AS_environmentEventsAtom } from "../events";
import { selectedDateRangeAtom } from "../../dateTime";
import { groupByDate } from "../../../utils/groupBy";
import { getDateRange } from "../../../utils/datesAndTimes";
import { refreshAnalyticsSystemDataAtom } from "..";
import { RecordEnvironmentEvent } from "../events/types";

function separateExperienceByDeviceType(
	experiences: Array<AtlasExperienceObservation>,
	environmentData: Array<RecordEnvironmentEvent>,
	dataReloading: boolean,
) {
	const dataContainer = {
		mobile: [] as typeof experiences,
		desktop: [] as typeof experiences,
	};

	return dataReloading
		? dataContainer
		: experiences.reduce((previous, current) => {
				const environment = environmentData.find(
					(event) => event.data.experience_id === current.observationLabel,
				);

				const isDeviceMobile = environment?.data.data.device.mobile;

				if (isDeviceMobile === undefined) {
					console.warn(
						"Experience record has missing device data.",
						current.observationLabel,
						environment,
					);
					return { ...previous };
				}
				return isDeviceMobile
					? {
							desktop: [...previous.desktop],
							mobile: [...previous.mobile, current],
					  }
					: {
							desktop: [...previous.desktop, current],
							mobile: [...previous.mobile],
					  };
		  }, dataContainer);
}

/** Loaded experiences separated by device type. */
export const loadedExperiencesByDeviceTypeAtom = atom((get) => {
	const loadedExperiences = get(loadedExperiencesAtom);
	const environmentEvents = get(AS_environmentEventsAtom);
	const dataReloading = get(refreshAnalyticsSystemDataAtom);

	const loadedExperiencesByDeviceType = separateExperienceByDeviceType(
		loadedExperiences,
		environmentEvents,
		dataReloading,
	);

	return loadedExperiencesByDeviceType;
});

/** Started experiences separated by device type. */
export const startedExperiencesByDeviceTypeAtom = atom((get) => {
	const startedExperiences = get(startedExperiencesAtom);
	const environmentEvents = get(AS_environmentEventsAtom);
	const dataReloading = get(refreshAnalyticsSystemDataAtom);

	const startedExperiencesByDeviceType = separateExperienceByDeviceType(
		startedExperiences,
		environmentEvents,
		dataReloading,
	);

	return startedExperiencesByDeviceType;
});

/**
 * What percentage of experiences were started? Segment by desktop/mobile/combined.
 * */
export const startedExperienceRatiosAtom = atom<
	OBSERVATION_WITH_DATE_LABEL<{ desktop: number; mobile: number }>[]
>((get) => {
	const loadedExperiences = get(loadedExperiencesByDeviceTypeAtom);
	const startedExperiences = get(startedExperiencesByDeviceTypeAtom);
	const selectedDateRange = get(selectedDateRangeAtom);
	const dataReloading = get(refreshAnalyticsSystemDataAtom);

	if (dataReloading) return [];

	// Group desktop experiences by date.
	const loadedDesktopExperiencesByDate = groupByDate(
		loadedExperiences.desktop,
		"ready_at",
	);
	const startedDesktopExperiencesByDate = groupByDate(
		startedExperiences.desktop,
		"started_at",
	);

	// Group mobile experiences by date.
	const loadedMobileExperiencesByDate = groupByDate(
		loadedExperiences.mobile,
		"ready_at",
	);
	const startedMobileExperiencesByDate = groupByDate(
		startedExperiences.mobile,
		"started_at",
	);

	/**
	 * Now that we have the various groups of observations by date,
	 * we need to combine them into date-based observations.
	 *
	 * First, we need to get an array of all the dates for which
	 * we are going to general observations.
	 */
	const allDatesInRange = getDateRange(
		selectedDateRange.from,
		selectedDateRange.to,
	);

	const data = allDatesInRange.map((date) => {
		const dateAsString = date.toISODate()!;
		const loadedDesktopExperiences =
			dateAsString in loadedDesktopExperiencesByDate
				? loadedDesktopExperiencesByDate[dateAsString].length
				: undefined;
		const loadedMobileExperiences =
			dateAsString in loadedMobileExperiencesByDate
				? loadedMobileExperiencesByDate[dateAsString].length
				: undefined;

		const startedDesktopExperiences =
			dateAsString in startedDesktopExperiencesByDate
				? startedDesktopExperiencesByDate[dateAsString].length
				: 0;
		const startedMobileExperiences =
			dateAsString in startedMobileExperiencesByDate
				? startedMobileExperiencesByDate[dateAsString].length
				: 0;

		const desktopStartPercentage = loadedDesktopExperiences
			? startedDesktopExperiences / loadedDesktopExperiences
			: 0;

		const mobileStartPercentage = loadedMobileExperiences
			? startedMobileExperiences / loadedMobileExperiences
			: 0;

		return {
			observationLabel: date,
			data: {
				desktop: desktopStartPercentage,
				mobile: mobileStartPercentage,
			},
		};
	});

	return data;
});
