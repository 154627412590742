import { useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";
import { authenticationStateAtom } from "../data/general";
import {
	supabaseUserAtom,
	supabaseClientAtom,
	supabaseSessionAtom,
	supabaseUserIsAdminAtom,
} from "../data/analytics";

/** Log user out and return application to loading screen. */
export default function useLogoutUser() {
	const setUserSession = useSetAtom(supabaseSessionAtom);
	const setUser = useSetAtom(supabaseUserAtom);
	const setAuthenticationState = useSetAtom(authenticationStateAtom);
	const setUserAdmin = useSetAtom(supabaseUserIsAdminAtom);
	const supabaseClient = useAtomValue(supabaseClientAtom);

	return useCallback(() => {
		setAuthenticationState(undefined);
		supabaseClient.auth.signOut();
		setUserSession(undefined);
		setUser(undefined);
		setUserAdmin(false);
	}, [
		supabaseClient,
		setUser,
		setUserSession,
		setAuthenticationState,
		setUserAdmin,
	]);
}
