import { useMemo, useState } from "react";

import { ColorFamily } from "../../../../../style";
import {
	_LS_EngagementsBaseAtom,
	updateEngagementMetadata,
} from "../../../../../data/learningSystem/engagement";

import SectionLabel from "../SectionLabel";
import Task from "./Task";
import NewTask from "./NewTask";
import { cloneDeep, set } from "lodash";
import { LearningSystemEngagementMetadata } from "@form-films/atlas-engine-shared-definitions";
import { LS_Engagement } from "../../../../../data/learningSystem/types";

/** Allow users to interact with engagement tasks and create new ones. */
export default function Tasks({
	observation,
	colorTheme,
	engagementState,
}: {
	observation: LS_Engagement;
	colorTheme: ColorFamily;
	engagementState: "in-progress" | "complete";
}) {
	const [addTaskActive, setAddTaskActive] = useState(false);

	// Filter out tasks that don't pass the required engagement state filter.
	const filteredTasks = useMemo(
		() =>
			Array.isArray(observation.data.metadata.tasks)
				? observation.data.metadata.tasks
						?.filter(
							(task) =>
								task.engagementState === "all" ||
								task.engagementState === engagementState,
						)
						.sort((a, b) => a.order - b.order)
				: [],
		[observation, engagementState],
	);

	/** Toggle the `complete` attribute on a task. */
	const toggleTaskCompletion = async (
		task: NonNullable<LearningSystemEngagementMetadata["tasks"]>[number],
	) => {
		const cloneOfExistingMetadata = cloneDeep(observation.data.metadata);
		set(
			cloneOfExistingMetadata,
			"tasks",
			observation.data.metadata.tasks?.map((currentTask) =>
				currentTask.name === task.name
					? { ...currentTask, complete: !currentTask.complete }
					: { ...currentTask },
			),
		);
		await updateEngagementMetadata(observation, cloneOfExistingMetadata);
	};

	/** Delete a task from an engagement record. */
	const deleteTask = async (
		task: NonNullable<LearningSystemEngagementMetadata["tasks"]>[number],
	) => {
		const cloneOfExistingMetadata = cloneDeep(observation.data.metadata);
		const updatedTaskList = observation.data.metadata.tasks
			?.filter(
				// Remove the deleted task.
				(currentTask) => currentTask.name !== task.name,
			)
			.map(
				// Update the order of the remaining tasks
				(task, index) => ({ ...task, order: index }),
			);

		set(cloneOfExistingMetadata, "tasks", updatedTaskList);
		await updateEngagementMetadata(observation, cloneOfExistingMetadata);
	};

	const reorderTask = async (
		task: NonNullable<LearningSystemEngagementMetadata["tasks"]>[number],
		direction: "up" | "down",
	) => {
		if (!filteredTasks?.length) return;

		const cloneOfExistingMetadata = cloneDeep(observation.data.metadata);

		/**
		 * Essentially, this operation requires switching the
		 * `order` attribute of the task that invokes this method
		 * and the task immediately before or after it in `filteredTasks`
		 */
		const taskIndex = filteredTasks.findIndex(
			(currentTask) => currentTask.name === task.name,
		);
		const previousTask = filteredTasks[taskIndex - 1];
		const nextTask = filteredTasks[taskIndex + 1];

		const updatedTaskList =
			direction === "up" && previousTask !== undefined
				? cloneOfExistingMetadata.tasks?.map((iterationTask) => ({
						...iterationTask,
						order:
							iterationTask.order === task.order
								? previousTask.order
								: iterationTask.order === previousTask.order
								  ? task.order
								  : iterationTask.order,
				  }))
				: direction === "down" && nextTask !== undefined
				  ? cloneOfExistingMetadata.tasks?.map((iterationTask) => ({
							...iterationTask,
							order:
								iterationTask.order === task.order
									? nextTask.order
									: iterationTask.order === nextTask.order
									  ? task.order
									  : iterationTask.order,
					  }))
				  : undefined;

		set(cloneOfExistingMetadata, "tasks", updatedTaskList);
		await updateEngagementMetadata(observation, cloneOfExistingMetadata);
	};

	return (
		<div css={{ paddingTop: 15 }}>
			<SectionLabel
				text={"TASKS"}
				colorTheme={colorTheme}
				action={{
					label: "New Task",
					onClick: () => setAddTaskActive((value) => !value),
				}}
			/>
			<NewTask
				active={addTaskActive}
				setActive={setAddTaskActive}
				colorTheme={colorTheme}
				observation={observation}
			/>
			{!Array.isArray(filteredTasks) ? null : (
				<div
					css={{
						marginTop: 5,
						gap: 0,
						display: "grid",
						gridTemplateColumns: "100%",
					}}
				>
					{filteredTasks
						.sort((a, b) => a.order - b.order)
						.map((entry, index) => {
							return (
								<Task
									observation={observation}
									task={entry}
									colorTheme={colorTheme}
									toggleTask={toggleTaskCompletion}
									deleteTask={deleteTask}
									reorderTask={reorderTask}
									canMoveUp={index !== 0}
									canMoveDown={index < filteredTasks.length - 1}
								/>
							);
						})}
				</div>
			)}
		</div>
	);
}
