import { atom } from "jotai";
import type { ComponentType, SVGProps } from "react";

import { type ColorFamily, portalColors } from "../style/colors";

import AnalyticsIcon from "../assets/svg/icons/analytics.svg?react";
import CommerceIcon from "../assets/svg/icons/piggyBank.svg?react";
import DocumentsIcon from "../assets/svg/icons/documents.svg?react";

export type NavigatorFeatureNames =
	| "Analytics"
	| "Commerce"
	| "Learning System";

export type NavigatorFeatureMenuOptions = {
	Analytics:
		| "General Performance"
		| "Goal Achievement"
		| "Node Level Detail"
		| "User Technology"
		| "Geolocation Data"
		| "User Decision";
	Commerce: "General Performance";
	"Learning System": "All Courses Summary" | "Course Level Detail";
};

export const navigatorFeatures: {
	[key in NavigatorFeatureNames]: {
		colors: ColorFamily;
		icon: ComponentType<SVGProps<SVGSVGElement>>;
		menu: Array<NavigatorFeatureMenuOptions[key]>;
		projectLevelDetail: boolean;
	};
} = {
	"Learning System": {
		colors: portalColors.blue,
		icon: DocumentsIcon,
		menu: ["All Courses Summary", "Course Level Detail"],
		projectLevelDetail: false,
	},
	Commerce: {
		colors: portalColors.green,
		icon: CommerceIcon,
		menu: ["General Performance"],
		projectLevelDetail: false,
	},
	Analytics: {
		colors: portalColors.orange,
		icon: AnalyticsIcon,
		menu: [
			"General Performance",
			"Goal Achievement",
			"User Decision",
			"Node Level Detail",
			"User Technology",
			"Geolocation Data",
		],
		projectLevelDetail: true,
	},
};

// What is the currently selected feature?
export const currentNavigatorFeatureAtom =
	atom<NavigatorFeatureNames>("Analytics");

// What are the available options for the current feature?
export const currentFeatureOptionsAtom = atom(
	(get) => navigatorFeatures[get(currentNavigatorFeatureAtom)].menu,
);

export const selectedOptionForEachFeatureAtom = atom<{
	Analytics: NavigatorFeatureMenuOptions["Analytics"];
	Commerce: NavigatorFeatureMenuOptions["Commerce"];
	"Learning System": NavigatorFeatureMenuOptions["Learning System"];
}>({
	Analytics: navigatorFeatures.Analytics.menu[0],
	Commerce: navigatorFeatures.Commerce.menu[0],
	"Learning System": navigatorFeatures["Learning System"].menu[0],
});
