// Components
import DataCallouts from "./DataCallouts";
import UserEnrollmentByDate from "./UserEnrollmentByDate";
import CoursesStartedByDate from "./CoursesStartedByDate";

// Hooks
import { useAvailableWidth } from "../../../data/hooks/useAvailableWidth";

export type GeneralPerformanceProps = {
	availableHeight: number;
};

export default function GeneralPerformance({
	availableHeight,
}: GeneralPerformanceProps) {
	const availableWidth = useAvailableWidth(60);

	return (
		<div css={{ boxSizing: "border-box" }}>
			<div
				css={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "space-between",
					boxSizing: "border-box",
				}}
			>
				<DataCallouts width={availableWidth} height={availableHeight * 0.25} />
				<div
					css={{
						display: "grid",
						gridTemplateColumns: `${availableWidth}px`,
						gridTemplateRows: `${availableHeight * 0.6}px ${
							availableHeight * 0.4
						}px`,
						gap: 10,
						marginTop: 20,
					}}
				>
					<CoursesStartedByDate />
					<UserEnrollmentByDate />
				</div>
			</div>
		</div>
	);
}
