import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";

import { ColorFamily, PRIMARY_FONT } from "../../../../../style";

import {
	_LS_EngagementsBaseAtom,
	updateEngagementMetadata,
} from "../../../../../data/learningSystem/engagement";
import { convertToNamingConvention } from "../../../../../utils/text/namingConvention";
import { LS_Engagement } from "../../../../../data/learningSystem/types";

type NewTaskProps = {
	observation: LS_Engagement;
	colorTheme: ColorFamily;
	active: boolean;
	setActive: (value: boolean) => void;
};

export default function NewTask({
	observation,
	colorTheme,
	active,
	setActive,
}: NewTaskProps) {
	const [newTaskName, setNewTaskName] = useState("");
	const inputRef = useRef<HTMLInputElement>(null);

	const createNewTask = async (taskName: string) => {
		const standardizedTaskName = convertToNamingConvention(taskName);

		// See if this task already exists and do nothing if it does
		const existingTask = observation.data.metadata.tasks?.find(
			(task) => task.name === standardizedTaskName,
		);
		if (!existingTask) {
			// Add new task to observation metadata
			const cloneOfExistingMetadata = cloneDeep(observation.data.metadata);
			cloneOfExistingMetadata.tasks?.push({
				name: standardizedTaskName,
				complete: false,
				order: cloneOfExistingMetadata.tasks.length,
				addedBy: "user",
				deletable: true,
				engagementState: "all",
			});

			await updateEngagementMetadata(observation, cloneOfExistingMetadata);
		}

		setActive(false);
		setNewTaskName("");
	};

	// Automatically focus on the <input /> element when the
	// component enters an active state.
	useEffect(() => {
		active ? inputRef.current?.focus() : inputRef.current?.blur();
	}, [active]);

	return (
		<div
			id="add-task-widget"
			css={{
				marginTop: active ? 10 : 0,
				maxHeight: active ? 100 : 0,
				transition: "all .33s ease",
				backgroundColor: colorTheme.light,
				borderRadius: 5,
				padding: active ? 10 : 0,
				opacity: active ? 1 : 0,
				pointerEvents: active ? "auto" : "none",
			}}
		>
			<input
				ref={inputRef}
				id={`add-task-input-field-${observation.observationLabel}`}
				placeholder="New Task"
				value={newTaskName}
				onChange={(event) => setNewTaskName(event.target.value)}
				onKeyUp={(event) => {
					if (newTaskName.length && event.key === "Enter")
						createNewTask(newTaskName);
				}}
				css={{
					width: "100%",
					boxSizing: "border-box",
					fontFamily: PRIMARY_FONT,
					fontSize: 11,
					fontWeight: 600,
					border: "none",
					outline: "none",
					textTransform: "uppercase",
					borderRadius: 5,
					padding: 10,
					color: colorTheme.dark,
					"::placeholder": {
						textTransform: "uppercase",
						color: colorTheme.mid,
					},
				}}
			/>
			<div
				css={{
					display: "flex",
					justifyContent: "flex-end",
					marginTop: 10,
					gap: 5,
				}}
			>
				<button
					type="button"
					disabled={!newTaskName.length}
					css={{
						fontFamily: PRIMARY_FONT,
						outline: "none",
						borderColor: colorTheme.mid,
						borderWidth: 0,
						borderStyle: "solid",
						borderRadius: 2,
						textTransform: "uppercase",
						fontWeight: 700,
						fontSize: 11,
						padding: 5,
						backgroundColor: "transparent",
						color: colorTheme.dark,
						transition: "all .33s ease",
						"&:not(:disabled):hover": {
							color: "white",
							backgroundColor: colorTheme.mid,
						},
						"&:disabled": {
							opacity: 0.5,
						},
					}}
					onClick={async () => {
						if (newTaskName.length) createNewTask(newTaskName);
					}}
				>
					Submit
				</button>
				<button
					type="button"
					css={{
						fontFamily: PRIMARY_FONT,
						outline: "none",
						borderWidth: 0,
						borderColor: colorTheme.mid,
						borderStyle: "solid",
						borderRadius: 2,
						color: colorTheme.dark,
						textTransform: "uppercase",
						backgroundColor: "transparent",
						fontWeight: 700,
						fontSize: 11,
						transition: "all .33s ease",
						":hover": {
							color: "white",
							backgroundColor: colorTheme.mid,
						},
					}}
					onClick={() => {
						setActive(false);
						setNewTaskName("");
					}}
				>
					Cancel
				</button>
			</div>
		</div>
	);
}
