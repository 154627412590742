import Color from "color";
import { useCallback, useState } from "react";
import { useAtom, useAtomValue } from "jotai";

// Assets
import Close from "../../../../../assets/svg/icons/close.svg?react";

// Components
import StringData from "../../../CourseLevelDetail/EngagementCards/StringData";

// Data & Definitions
import { PRIMARY_FONT, midTones } from "../../../../../style";
import { convertFromNamingConvention } from "../../../../../utils/text/namingConvention";
import { tagColor } from "../../../../../data/learningSystem/engagement/tags";
import {
	EngagementFilter,
	engagementFiltersAtom,
} from "../../../../../data/learningSystem/engagement/filters";

type TagProps = {
	filter: EngagementFilter;
};

export default function Tag({ filter }: TagProps) {
	const [engagementFilters, setEngagmentFilters] = useAtom(
		engagementFiltersAtom,
	);

	const [isHovered, setIsHovered] = useState(false);

	const deleteFilter = useCallback(
		(filter: EngagementFilter) => {
			setEngagmentFilters((engagementFilters) =>
				engagementFilters.filter((engagementFilter) => {
					if (engagementFilter.entity === "tag" && filter.entity === "tag") {
						return engagementFilter.tag.category === filter.tag.category &&
							engagementFilter.tag.name === filter.tag.name
							? false
							: true;
					}
					return true;
				}),
			);
		},
		[engagementFilters],
	);

	if (filter.entity !== "tag") return null;

	return (
		<div
			css={{
				cursor: "default",
				backgroundColor: tagColor(filter.tag.category),
				padding: 10,
				borderRadius: 5,
				fontFamily: PRIMARY_FONT,
				position: "relative",
			}}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<Close
				role="button"
				fill="white"
				width={10}
				height={10}
				viewBox="0 0 20 20"
				css={{
					position: "absolute",
					top: 5,
					right: 5,
					display: isHovered ? "initial" : "none",
					cursor: "pointer",
				}}
				onClick={() => deleteFilter(filter)}
			/>
			<span
				css={{
					color: "white",
					textTransform: "uppercase",
					fontWeight: 600,
					fontSize: 9,
					marginBottom: -1,
					display: "block",
				}}
			>
				{convertFromNamingConvention(filter.tag.category)}
			</span>

			<StringData
				text={convertFromNamingConvention(filter.tag.name.toString())}
				colorTheme={{
					dark: "#FFFFFF",
					mid: "#FFFFFF",
					light: "#FFFFFF",
				}}
				size="small"
			/>
		</div>
	);
}
