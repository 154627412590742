/** @jsxImportSource @emotion/react */

import { CSSProperties, useEffect, useRef } from "react";
import { darkGray, midGray, portalColors } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";

type InputFieldProps = {
	label: string;
	value: string;
	onValueChange: (newValue: string) => void;
	onValidationError?: (error: string) => void;
	type?: HTMLInputElement["type"];
	containerStyles?: CSSProperties;
};

export default function InputField({
	label,
	value,
	onValueChange,
	onValidationError,
	type = "text",
	containerStyles = {},
}: InputFieldProps) {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		onValidationError?.(inputRef.current?.validationMessage ?? "");
	}, [inputRef.current?.value, onValidationError]);

	return (
		<div css={{ ...containerStyles }}>
			<label
				htmlFor={label}
				css={{
					fontFamily: PRIMARY_FONT,
					fontSize: 12,
					fontWeight: 600,
					color: portalColors.gray.dark,
					// textTransform: "uppercase",
					margin: 0,
					marginBottom: 5,
					display: "block",
				}}
			>
				{label}
			</label>
			<input
				id={`${label}-${Math.random()}`}
				ref={inputRef}
				autoComplete="off"
				css={{
					height: 30,
					outline: "1px solid",
					outlineColor: midGray,
					border: "none",
					boxSizing: "border-box",
					fontFamily: PRIMARY_FONT,
					fontWeight: 500,
					fontSize: 12,
					paddingLeft: 10,
					paddingRight: 10,
					width: "100%",
					color: portalColors.gray.mid,
					transition: "all .5s ease",
					borderRadius: 2,
					":hover, :focus": {
						color: darkGray,
						outlineColor: portalColors.gray.mid,
					},
				}}
				type={type}
				value={value}
				minLength={type === "password" ? 12 : 0}
				onChange={(event) => onValueChange(event.target.value)}
			/>
		</div>
	);
}
