import { atom } from "jotai";
import { DateTime } from "luxon";

// Data
import {
	LS_OrganizationalCoursesAtom,
	LS_SelectedCoursesAtom,
} from "../courses";
import { selectedDateRangeAtom } from "../../dateTime";

// Definitions
import { OBSERVATION_WITH_DATE_LABEL } from "../../../types/charts";

// Utilities
import { groupByDate } from "../../../utils/groupBy";
import { getDateRange } from "../../../utils/datesAndTimes";
import { LS_EngagementsAtom } from ".";

/** All engagement observations accessible by the current user for the selected courses. */
export const LS_SelectedCoursesEngagementsAtom = atom((get) => {
	const selectedCourseIDs = get(LS_SelectedCoursesAtom).map(
		(course) => course.observationLabel,
	);
	return get(LS_EngagementsAtom).filter((engagement) =>
		selectedCourseIDs.includes(engagement.data.course_id),
	);
});

/** Get all open engagement records for the selected organization. */
export const LS_SelectedCoursesOpenEngagements = atom((get) =>
	get(LS_SelectedCoursesEngagementsAtom)
		.filter((engagement) => !engagement.data.finished_at)
		.sort(
			(a, b) =>
				DateTime.fromISO(b.data.started_at).toMillis() -
				DateTime.fromISO(a.data.started_at).toMillis(),
		),
);

/** Get all closed engagement records for the selected organization. */
export const LS_SelectedCoursesClosedEngagements = atom((get) =>
	get(LS_SelectedCoursesEngagementsAtom)
		.filter((engagement) => engagement.data.finished_at)
		.sort(
			(a, b) =>
				DateTime.fromISO(b.data.finished_at!).toMillis() -
				DateTime.fromISO(a.data.finished_at!).toMillis(),
		),
);

/**
 * Observations that provide the number of engagements
 * for each course per day within the currently selected dates.
 */
export const LS_SelectedCoursesEngagmentsByCourseAndDate = atom<
	OBSERVATION_WITH_DATE_LABEL<Record<string, number>>[]
>((get) => {
	// Get all engagement observations grouped by `started_at`
	const engagementsGroupedByStartDate = groupByDate(
		get(LS_SelectedCoursesEngagementsAtom),
		"started_at",
	);

	// What courses belong to the user's organizations?
	const organizationCourses = get(LS_OrganizationalCoursesAtom);

	// Extract the course display names
	const availableCourseDisplayNames = organizationCourses.map(
		(course) => course.data.display_name,
	);

	const selectedDateRange = get(selectedDateRangeAtom);
	const allDatesInRange = getDateRange(
		selectedDateRange.from,
		selectedDateRange.to,
	);

	const data = allDatesInRange.map((date) => {
		// Get the date as a string
		const dateAsString = date.toISODate();

		if (!dateAsString)
			throw new Error(`Could not convert ${date} to ISO string.`);

		/**
		 * Create an observation that will provided the number of engagements
		 * for each course on a given day.
		 */
		const observation: OBSERVATION_WITH_DATE_LABEL<Record<string, number>> = {
			observationLabel: date,
			data: availableCourseDisplayNames.reduce(
				(previous, next) => ({ ...previous, [next]: 0 }),
				{},
			),
		};

		// Get all engagements for the current date.
		const engagementsForCurrentDate =
			engagementsGroupedByStartDate[dateAsString];

		// Not sure this is necessary
		// if (!engagementsForCurrentDate) return observation;

		for (const engagement of engagementsForCurrentDate) {
			const currentCourseName = organizationCourses.find(
				(course) => course.observationLabel === engagement.data.course_id,
			)?.data.display_name;

			if (currentCourseName) observation.data[currentCourseName] += 1;
		}

		return observation;
	});

	return data;
});
