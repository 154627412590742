import { Grid, GridColumns, GridRows } from "@visx/grid";
import { ScaleBand, ScaleLinear } from "d3-scale";
import { CSSProperties } from "react";

type GridLinesProps = {
  lineOption?: "rows" | "columns" | "both";
  lineColor: CSSProperties["color"];
  xScale: ScaleBand<any>;
  yScale: ScaleLinear<any, any, any>;
  width: number;
  height: number;
};

export default function GridLines({
  lineOption,
  lineColor,
  xScale,
  yScale,
  width,
  height,
}: GridLinesProps) {
  switch (lineOption) {
    case "both":
      return (
        <Grid
          xScale={xScale}
          yScale={yScale}
          width={width}
          height={height}
          stroke={lineColor}
          // numTicksRows={numTicksForHeight(height)}
          // numTicksColumns={numTicksForWidth(width)}
        />
      );

    case "rows":
      return (
        <GridRows
          scale={yScale}
          width={width}
          height={height}
          stroke={lineColor}

          // numTicksRows={numTicksForHeight(height)}
          // numTicksColumns={numTicksForWidth(width)}
        />
      );

    case "columns":
      return (
        <GridColumns
          scale={xScale}
          width={width}
          height={height}
          stroke={lineColor}
          // numTicksRows={numTicksForHeight(height)}
          // numTicksColumns={numTicksForWidth(width)}
        />
      );

    default:
      return null;
  }
}
