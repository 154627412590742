import { useAtomValue } from "jotai";

// Components
import GeneralPerformance from "./GeneralPerformance";
import GoalAchievement from "./GoalAchievement";
import NodeLevelDetail from "./NodeLevelDetail";
import DateRangeSelector from "../widgets/DateRangeSelector";
import UserEnvironment from "./UserEnvironment";
import Geolocation from "./Geolocation";

// Data
import {
	currentNavigatorFeatureAtom,
	selectedOptionForEachFeatureAtom,
} from "../../data/feature";

// Definitions
import type { FeatureProps } from "../ClientNavigator/types";
import EnclosingURLSelector from "../widgets/EnclosingURLSelector";
import { mediaQueries } from "../../style";
import UserDecision from "./UserDecision";

export default function Analytics({ contentAreaHeight }: FeatureProps) {
	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);
	const currentOptionForEachFeature = useAtomValue(
		selectedOptionForEachFeatureAtom,
	).Analytics;

	const renderCurrentOption = () => {
		switch (currentOptionForEachFeature) {
			case "General Performance":
				return <GeneralPerformance contentAreaHeight={contentAreaHeight} />;

			case "Goal Achievement":
				return <GoalAchievement availableHeight={contentAreaHeight} />;

			case "User Decision":
				return <UserDecision contentAreaHeight={contentAreaHeight} />;

			case "Node Level Detail":
				return <NodeLevelDetail availableHeight={contentAreaHeight} />;

			case "Geolocation Data":
				return <Geolocation availableHeight={contentAreaHeight} />;

			case "User Technology":
				return <UserEnvironment availableHeight={contentAreaHeight} />;

			default:
				return null;
		}
	};

	return currentFeature !== "Analytics" ? null : (
		<div
			css={{
				boxSizing: "border-box",
			}}
		>
			<div
				css={{
					display: "grid",
					marginBottom: 15,
					gap: 10,
					gridTemplateRows: "auto auto",
					[mediaQueries[3]]: {
						gridTemplateRows: "auto",
						gridTemplateColumns: "max-content auto",
					},
				}}
			>
				<DateRangeSelector />
				<EnclosingURLSelector />
			</div>
			{renderCurrentOption()}
		</div>
	);
}
