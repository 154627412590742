import { ColorFamily, PRIMARY_FONT, portalColors } from "../../../../style";

import AddCircle from "../../../../assets/svg/icons/addCircle.svg?react";

export type SectionLabelProps = {
	text: string | undefined;
	colorTheme: ColorFamily;
	action?: {
		label: string;
		onClick: () => void;
	};
};

export default function SectionLabel({
	text,
	colorTheme,
	action,
}: SectionLabelProps) {
	return (
		<div
			css={{
				display: "flex",
				borderBottom: `1px solid ${portalColors.gray.mid}`,
				alignItems: "center",
				justifyContent: "space-between",
				paddingBottom: 2,
			}}
		>
			<label
				css={{
					fontWeight: 700,
					color: portalColors.gray.dark,
					textTransform: "uppercase",
					fontSize: 11,
				}}
			>
				{text}
			</label>
			{action && (
				// rome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
				<div
					css={{
						display: "flex",
						alignItems: "flex-end",
						fill: colorTheme.mid,
						color: colorTheme.mid,
						cursor: "pointer",
						fontWeight: 600,
						transition: "all .33s ease",
						":hover": {
							fill: colorTheme.dark,
							color: colorTheme.dark,
						},
					}}
					onClick={action?.onClick}
				>
					<AddCircle
						width={16}
						height={16}
						css={{
							boxSizing: "border-box",
							marginRight: 3,
							transition: "all .33s ease",
						}}
					/>
					<span
						css={{
							fontFamily: PRIMARY_FONT,
							fontSize: 10,
							color: "inherit",
							textTransform: "uppercase",
						}}
					>
						{action?.label}
					</span>
				</div>
			)}
		</div>
	);
}
