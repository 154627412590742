import { useMemo } from "react";
import { useAtomValue } from "jotai";

// Components
import StackedBarChart from "../../charts/Bar/StackedBarChart";
import ContinuousBarChart from "../../charts/Bar/ContinuousBarChart";

// Data
import {
	loadedExperiencesGroupedByDateAtom,
	startedExperiencesGroupedByDateAtom,
} from "../../../data/analytics/experience";
import { allDatesInUserSelectionAtom } from "../../../data/dateTime";

// Definitions
import { mixColorFamilies, portalColors } from "../../../style/colors";
import { OBSERVATION_WITH_SINGLE_NUMERIC_VALUE } from "../../../types/charts";
import { refreshAnalyticsSystemDataAtom } from "../../../data/analytics";

export default function StartingPercentageByDate() {
	const loadedExperiences = useAtomValue(loadedExperiencesGroupedByDateAtom);
	const startedExperiences = useAtomValue(startedExperiencesGroupedByDateAtom);
	const selectedDates = useAtomValue(allDatesInUserSelectionAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	const preparedObservations = useMemo<
		OBSERVATION_WITH_SINGLE_NUMERIC_VALUE[]
	>(() => {
		// Generate an observation for each date date in range.
		const observations = selectedDates.map((date) => {
			const dateAsString = date.toISODate();

			if (!dateAsString)
				throw new Error(`Could not create string from ${date}`);

			return {
				observationLabel: date,
				data: {
					value:
						dateAsString in startedExperiences
							? startedExperiences[dateAsString].length /
								loadedExperiences[dateAsString]?.length
							: 0,
				},
			};
		});

		return observations;
	}, [loadedExperiences, startedExperiences, selectedDates]);

	return (
		<ContinuousBarChart
			dataColors={[
				mixColorFamilies(portalColors.red, portalColors.gray, 0.5).mid,
				portalColors.blue.mid,
			]}
			observations={preparedObservations}
			dataRefreshing={dataRefreshing}
			axes={{ observationLabel: "bottom" }}
			colorTheme={mixColorFamilies(portalColors.blue, portalColors.gray, 0.9)}
			chartTitle={{
				callOut: "Experiences Started",
				text: "Percentage of loaded experiences that were started",
			}}
			dataFormatter={(data) => `${(Number(data) * 100).toFixed()}%`}
			tooltip={{ title: "Loaded/Started Percentage" }}
			chartExplanation={`
			This chart shows the percentage of 
			loaded experiencs that were started by users 
			during the selected timeframe.
			`}
		/>
	);
}
