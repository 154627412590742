import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

// Components
import EngagementCards from "./EngagementCards";

// Data & Definitions
import { LS_SelectedCoursesClosedEngagements } from "../../../data/learningSystem/engagement/selectedCourses";
import { refreshLearningSystemDataAtom } from "../../../data/learningSystem";

// Utilities
import { mixColorFamilies, portalColors } from "../../../style";
import {
	engagementFiltersAtom,
	separatedEngagementFiltersAtom,
} from "../../../data/learningSystem/engagement/filters";
import { LS_Engagement } from "../../../data/learningSystem/types";

/** Display a table of all open engagements for the selected courses. */
export default function ClosedEngagements() {
	const closedEngagements = useAtomValue(LS_SelectedCoursesClosedEngagements);
	const dataRefreshing = useAtomValue(refreshLearningSystemDataAtom);

	const allEngagementFilters = useAtomValue(engagementFiltersAtom);
	const separatedEngagementFilters = useAtomValue(
		separatedEngagementFiltersAtom,
	);

	// Prepare the data
	const [preparedData, setPreparedData] = useState<LS_Engagement[]>([]);

	useEffect(() => {
		if (!dataRefreshing) {
			if (!allEngagementFilters.length) {
				setPreparedData(closedEngagements);
			} else {
				// Step 1: Filter "in" records for inclusion filters
				const mergedInclusionFilters = [
					...separatedEngagementFilters.inclusionTags,
					...separatedEngagementFilters.inclusionTasks,
				];

				const includedByFilters = mergedInclusionFilters.length
					? closedEngagements.filter((engagement) => {
							return mergedInclusionFilters.some((inclusionFilter) => {
								return (
									(inclusionFilter.entity === "tag" &&
										engagement.data.metadata.tags?.[
											inclusionFilter.tag.category
										]?.includes(inclusionFilter.tag.name)) ||
									(inclusionFilter.entity === "task" &&
										engagement.data.metadata.tasks?.find(
											(task) => task.name === inclusionFilter.task.name,
										)?.complete === inclusionFilter.task.completed)
								);
							});
					  })
					: closedEngagements;

				// Step 2: Filter "out" records for exclusion filters
				const mergedExclusionFilters = [
					...separatedEngagementFilters.exclusionTags,
					...separatedEngagementFilters.exclusionTasks,
				];

				const excludedByFilters = mergedExclusionFilters
					? includedByFilters.filter((engagement) =>
							mergedExclusionFilters.every(
								(exclusionFilter) =>
									(exclusionFilter.entity === "tag" &&
										engagement.data.metadata.tags?.[
											exclusionFilter.tag.category
										]?.includes(exclusionFilter.tag.name)) ||
									(exclusionFilter.entity === "task" &&
										engagement.data.metadata.tasks?.find(
											(task) => task.name === exclusionFilter.task.name,
										)?.complete === exclusionFilter.task.completed),
							),
					  )
					: includedByFilters;

				setPreparedData(excludedByFilters);
			}
		}
	}, [
		dataRefreshing,
		closedEngagements,
		allEngagementFilters,
		separatedEngagementFilters,
	]);

	return (
		<EngagementCards
			chartTitle={{
				callOut: "Completed Courses",
				text: "Courses completed during the selected timeframe",
			}}
			colorTheme={mixColorFamilies(portalColors.green, portalColors.gray, 0.5)}
			// transparentBackground={true}
			// margins={{ horizontal: 0, vertical: 0 }}
			dataRefreshing={dataRefreshing}
			observations={preparedData}
			engagementState="complete"
		/>
	);
}
