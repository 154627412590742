// Components
import DataCallouts from "./DataCallouts";
import OpenEngagements from "./OpenEngagements";
import ClosedEngagements from "./ClosedEngagements";

// Hooks
import { useAvailableWidth } from "../../../data/hooks/useAvailableWidth";

export type CourseLevelDetailProps = {
	availableHeight: number;
};

export default function CourseLevelDetail({
	availableHeight,
}: CourseLevelDetailProps) {
	const availableWidth = useAvailableWidth(60);

	return (
		<div>
			<div
				css={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "space-between",
					boxSizing: "border-box",
				}}
			>
				<DataCallouts width={availableWidth} height={availableHeight * 0.25} />
				<div
					css={{
						display: "grid",
						gridTemplateColumns: `${availableWidth}px`,
						gap: 10,
						marginTop: 20,
					}}
				>
					<OpenEngagements />
					<ClosedEngagements />
				</div>
			</div>
		</div>
	);
}
