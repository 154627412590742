import useDimensions from "react-cool-dimensions";
import { useAtomValue, useSetAtom } from "jotai";

// Data

import {
	currentNavigatorFeatureAtom,
	navigatorFeatures,
	selectedOptionForEachFeatureAtom,
} from "../../data/feature";

// Definitions
import { darkGray } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";

// Assets

import { currentAtlasProjectAtom } from "../../data/project";
import { mediaQueries } from "../../style/breakpoints";
import { featureNavDimensionsAtom } from "../../data/componentDimensions";
import { selectedOrganizationAtom } from "../../data/organization";

export default function FeatureNavigation() {
	const storeComponentDimensions = useSetAtom(featureNavDimensionsAtom);
	const { observe } = useDimensions({
		useBorderBoxSize: true,
		onResize: ({ width, height }) => storeComponentDimensions([width, height]),
	});

	const selectedOrganization = useAtomValue(selectedOrganizationAtom);
	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);
	const selectedOptionForEachFeature = useAtomValue(
		selectedOptionForEachFeatureAtom,
	);

	const currentProject = useAtomValue(currentAtlasProjectAtom);

	return (
		<section
			ref={observe}
			css={{
				width: "100%",
				paddingTop: 30,
				paddingLeft: 30,
				paddingRight: 30,
				paddingBottom: 15,
				marginBottom: 15,
				backgroundColor: darkGray,
			}}
			id="feature-header"
		>
			<div
				css={{
					display: "flex",
					justifyContent: "flex-start",
					width: "100%",
					alignContent: "flex-start",
					boxSizing: "border-box",
				}}
			>
				<div>
					<div
						css={{
							backgroundColor: darkGray,
							boxSizing: "border-box",
							display: "flex",
							justifyContent: "flex-start",
						}}
					>
						<div
							id="color-block"
							css={{
								boxSizing: "border-box",
								flexBasis: 15,
								marginRight: 10,
								backgroundColor: navigatorFeatures[currentFeature].colors.mid,
								borderRadius: 2,
								transition: "background-color .5s ease",
								[mediaQueries[1]]: {
									flexBasis: 20,
								},
							}}
						/>
						<h1
							css={{
								fontFamily: PRIMARY_FONT,
								fontWeight: 700,
								fontSize: 24,
								lineHeight: 0.9,
								color: "white",
								flex: 1,
								marginTop: 15,
								marginBottom: 0,
								[mediaQueries[1]]: {
									fontSize: 42,
									maxWidth: 500,
									marginRight: 50,
								},
							}}
						>
							{`${selectedOrganization?.name} ${currentFeature}`}
						</h1>
					</div>
					<div
						css={{
							backgroundColor: darkGray,
							boxSizing: "border-box",
							display: "flex",
							marginTop: 10,
							justifyContent: "flex-start",
						}}
					>
						<div
							id="color-block"
							css={{
								width: 10,
								marginRight: 5,
								borderRadius: 2,
								transition: "background-color .5s ease",
								backgroundColor: navigatorFeatures[currentFeature].colors.light,
							}}
						/>
						<div
							css={{
								padding: 10,
								paddingLeft: 15,
								paddingRight: 25,
								backgroundColor: "white",
								borderRadius: 2,
								color: navigatorFeatures[currentFeature].colors.dark,
								transition: "color .5s ease",
							}}
						>
							<h2
								css={{
									fontFamily: PRIMARY_FONT,
									fontWeight: 600,
									fontSize: 14,
									margin: 0,
									[mediaQueries[1]]: {
										fontSize: 16,
									},
								}}
							>
								{navigatorFeatures[currentFeature].projectLevelDetail
									? currentProject?.name
									: selectedOptionForEachFeature[currentFeature]}
							</h2>
							{navigatorFeatures[currentFeature].projectLevelDetail && (
								<h3
									css={{
										fontFamily: PRIMARY_FONT,
										fontSize: 11,
										margin: 0,
										marginTop: -3,
										fontWeight: 400,
										[mediaQueries[1]]: {
											fontSize: 12,
										},
									}}
								>
									{selectedOptionForEachFeature[currentFeature]}
								</h3>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
