import { useState } from "react";
import { useAtom } from "jotai";
import { Property } from "csstype";

import InputField from "../widgets/InputField";
import SquareEdgeButton from "../widgets/SquareEdgeButton";
import { mediaQueries } from "../../style/breakpoints";
import { supabaseAuthErrorAtom } from "../../data/analytics";
import { authenticationStateAtom } from "../../data/general";
import { SpringValue } from "@react-spring/web";
import registerUser from "../../data/auth/registerUser";

type UserRegistrationProps = {
	animationSpring: { backgroundColor: SpringValue<Property.Color> };
};

export default function UserRegistration({
	animationSpring,
}: UserRegistrationProps) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [enrollmentCode, setEnrollmentCode] = useState("");
	const [registrationInProgress, setRegistrationInProgress] = useState(false);

	const [authenticationState, setAuthenticationState] = useAtom(
		authenticationStateAtom,
	);
	const [supabaseAuthError, setSupabaseAuthError] = useAtom(
		supabaseAuthErrorAtom,
	);

	const onSubmit = async () => {
		if (registrationInProgress) return;
		setRegistrationInProgress(true);

		resetForm();
		try {
			await registerUser(username, password, enrollmentCode, {
				firstName,
				lastName,
			});
			setAuthenticationState("launchNavigator");
		} catch (error: any) {
			setSupabaseAuthError(error.message);
		}
		setRegistrationInProgress(false);
	};

	const renderInputFields = () => (
		<form
			onSubmit={(event) => {
				event.preventDefault();
				onSubmit();
			}}
			css={[
				{
					display: "grid",
					gridTemplateColumns: "1fr",
					width: "100%",
					[mediaQueries[0]]: {
						gridTemplateColumns: "1fr 1fr",
					},
					gap: 10,
				},
			]}
		>
			<InputField
				label="Email (Username)"
				type="email"
				value={username}
				onValueChange={setUsername}
			/>
			<InputField
				type="password"
				label="Password"
				value={password}
				onValueChange={setPassword}
				// onValidationError={(error) => setSupabaseAuthError(error)}
			/>
			<InputField
				label="First Name"
				containerStyles={{
					[mediaQueries[0]]: {
						marginBottom: 0,
					},
				}}
				value={firstName}
				onValueChange={setFirstName}
			/>
			<InputField
				label="Last Name"
				value={lastName}
				onValueChange={setLastName}
			/>
			<InputField
				label="Enrollment Code"
				value={enrollmentCode}
				onValueChange={setEnrollmentCode}
			/>

			<SquareEdgeButton
				label="SUBMIT"
				type="submit"
				animatedStyles={animationSpring}
				disabled={
					!username ||
					!password ||
					!firstName ||
					!lastName ||
					!enrollmentCode ||
					registrationInProgress
				}
				additionalStyles={{
					marginTop: 10,
					[mediaQueries[0]]: { gridRow: "4" },
				}}
			/>
		</form>
	);

	const resetForm = () => {
		setUsername("");
		setPassword("");
		setFirstName("");
		setLastName("");
		setEnrollmentCode("");
		setSupabaseAuthError(null);
	};

	return (
		<div
			css={{
				paddingBottom: 35,
				display: authenticationState === "register" ? "flex" : "none",
				opacity: authenticationState === "register" ? 1 : 0,
			}}
		>
			{renderInputFields()}
		</div>
	);
}
