import { DateTime } from "luxon";

import { ObjectWithStringKeys } from "@form-films/atlas-engine-shared-definitions";
import {
	OBSERVATION_WITH_DATE_LABEL,
	OBSERVATION_WITH_STRING_LABEL,
} from "../../types/charts";

/**
 * This function groups an array of objects by a specified date key.
 * Optionally, you can pick specific attributes from each object to
 * include in the final grouped object.
 *
 * @param observations The array of objects to be grouped.
 * @param dateKey The key in the object to use as the date.
 * @param attributesToPick An optional array of keys. If provided, only these keys will be included in the final grouped object.
 *
 */
export function groupByDate<
	ObservationType extends
		| OBSERVATION_WITH_DATE_LABEL<ObjectWithStringKeys>
		| OBSERVATION_WITH_STRING_LABEL<ObjectWithStringKeys>,
	DateKey extends keyof ObservationType["data"],
>(observations: Array<ObservationType>, dateKey: DateKey) {
	/**
	 * Reduce the `observations` array into an object that groups the items by date.
	 * The type of the reduced object's values depends on whether attributesToPick is provided.
	 */
	const groupedByDate = observations.reduce<{
		[key: string]: Array<ObservationType>;
	}>((previous, nextObservation) => {
		const dateKeyValue = nextObservation.data[dateKey as string];

		// Convert the date from the current item to a standardized ISO date string
		const standardizedDate = DateTime.isDateTime(dateKeyValue)
			? dateKeyValue.toISODate()
			: DateTime.fromISO(dateKeyValue as string).toISODate();

		if (!standardizedDate)
			throw Error(`Could not group observations by ${dateKey.toString()}`);

		// If this date already exists in the reduced object, append the next observation
		// to the array for this date If not, create a new array for this date with
		// the observation as the first item
		const newReduction =
			standardizedDate in previous
				? {
						...previous,
						[standardizedDate]: [
							...previous[standardizedDate],
							nextObservation,
						],
				  }
				: { ...previous, [standardizedDate]: [nextObservation] };

		return newReduction;
	}, {});

	return groupedByDate;
}
