import { atom, getDefaultStore } from "jotai";
import Color from "color";

import { LS_SelectedCoursesEngagementsAtom } from "./selectedCourses";
import { midTones } from "../../../style";
import { LS_Engagement } from "../types";

/** All tags found in the engagements for the currently selected courses. */
export const allTagsForSelectedEngagementsAtom = atom((get) => {
	const engagementsForSelectedCourses = get(LS_SelectedCoursesEngagementsAtom);
	return engagementsForSelectedCourses.reduce(
		(previous, next) => {
			const currentEngagementTags = next.data.metadata.tags
				? { ...next.data.metadata.tags }
				: {};

			const allTagCategories = new Set([
				...Object.keys(currentEngagementTags),
				...Object.keys(previous),
			]);

			for (const category of allTagCategories) {
				currentEngagementTags[category] = [
					...new Set([
						...(previous[category] ? previous[category] : []),
						...(next.data.metadata.tags?.[category]
							? next.data.metadata.tags[category]
							: []),
					]),
				];
			}

			return currentEngagementTags;
		},
		{} as Required<LS_Engagement["data"]["metadata"]>["tags"],
	);
});

export const tagColor = (tagCategory: string) => {
	const allTags = getDefaultStore().get(allTagsForSelectedEngagementsAtom);

	const tagCategories = Object.keys(allTags);
	const tagCategoryIndex = tagCategories.findIndex(
		(category) => category === tagCategory,
	);

	const moduloValue = tagCategoryIndex % midTones.length;
	return Color(midTones[moduloValue]).desaturate(0).hex();
};
