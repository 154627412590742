import { useState } from "react";
import { animated } from "@react-spring/web";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import InputField from "../widgets/InputField";
import SquareEdgeButton from "../widgets/SquareEdgeButton";
import { mediaQueries } from "../../style/breakpoints";
import {
	isCurrentUserAdmin,
	supabaseAuthErrorAtom,
	supabaseClientAtom,
	supabaseSessionAtom,
	supabaseUserAtom,
	supabaseUserIsAdminAtom,
} from "../../data/analytics";
import { authenticationStateAtom } from "../../data/general";

export default function ResetPassword() {
	const [password, setPassword] = useState("");

	const [authenticationState, setAuthenticationState] = useAtom(
		authenticationStateAtom,
	);
	const client = useAtomValue(supabaseClientAtom);
	const setUser = useSetAtom(supabaseUserAtom);
	const setUserAdmin = useSetAtom(supabaseUserIsAdminAtom);
	const setUserSession = useSetAtom(supabaseSessionAtom);
	const setSupabaseAuthError = useSetAtom(supabaseAuthErrorAtom);

	const attemptPasswordReset = async () => {
		try {
			const { data, error } = await client.auth.updateUser({
				password: password,
			});
			resetForm();

			if (data.user) {
				setUser(data.user);

				// Determine if the user is an admin or not.
				const userIsAdmin = await isCurrentUserAdmin();
				setUserAdmin(userIsAdmin);
			} else {
				setSupabaseAuthError(error?.message ?? "Unexpected error.");
				return;
			}

			const {
				data: { session },
				error: sessionError,
			} = await client.auth.getSession();

			if (session) {
				setUserSession(session);
				setAuthenticationState("launchNavigator");
			} else {
				setSupabaseAuthError(error?.message ?? "Unexpected error.");
				return;
			}
		} catch (error: any) {
			console.log(error);
			setSupabaseAuthError("Unexpected network error. Try again.");
		}
	};

	const renderInputFields = () => (
		<form
			css={[
				{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					alignItems: "flex-start",
				},
			]}
		>
			<div
				css={{
					marginBottom: 20,
					display: "flex",
					flexDirection: "column",
					[mediaQueries[0]]: {
						flexDirection: "row",
						marginBottom: 15,
					},
				}}
			>
				<>
					<InputField
						type="password"
						label="New Password"
						value={password}
						onValueChange={setPassword}
					/>
				</>
			</div>

			<SquareEdgeButton
				label="SUBMIT"
				onPress={() => {
					attemptPasswordReset();
				}}
				disabled={!password}
			/>
		</form>
	);

	const resetForm = () => {
		setPassword("");
		setSupabaseAuthError(null);
	};

	return (
		<animated.div
			css={{
				flexDirection: "column",
				alignItems: "flex-start",
				justifyContent: "flex-end",
				boxSizing: "border-box",
				paddingBottom: 35,
				display: authenticationState === "resetPassword" ? "flex" : "none",
				opacity: authenticationState === "resetPassword" ? 1 : 0,
			}}
		>
			{renderInputFields()}
		</animated.div>
	);
}
