import { useAtomValue } from "jotai";

// Components
import GeneralPerformance from "./GeneralPerformance";
import DateRangeSelector from "../widgets/DateRangeSelector";
import EnclosingURLSelector from "../widgets/EnclosingURLSelector";

// Data & Definitions
import { mediaQueries } from "../../style";
import { FeatureProps } from "../ClientNavigator/types";
import {
	currentNavigatorFeatureAtom,
	selectedOptionForEachFeatureAtom,
} from "../../data/feature";

export default function Commerce({ contentAreaHeight }: FeatureProps) {
	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);
	const currentOptionForEachFeature = useAtomValue(
		selectedOptionForEachFeatureAtom,
	).Commerce;

	const renderCurrentOption = () => {
		switch (currentOptionForEachFeature) {
			case "General Performance":
				return <GeneralPerformance availableHeight={contentAreaHeight} />;

			default:
				return null;
		}
	};

	return currentFeature !== "Commerce" ? null : (
		<div
			css={{
				boxSizing: "border-box",
			}}
		>
			<div
				css={{
					display: "grid",
					marginBottom: 15,
					gap: 10,
					gridTemplateRows: "auto auto",
					[mediaQueries[3]]: {
						gridTemplateRows: "auto",
						gridTemplateColumns: "max-content auto",
					},
				}}
			>
				<DateRangeSelector />
				<EnclosingURLSelector />
			</div>
			{renderCurrentOption()}
		</div>
	);
}
