import { atom } from "jotai";
import { supabaseSessionAtom, supabaseUserAtom } from "./user";

export const authenticationStateAtom = atom<
	| "login"
	| "register"
	| "confirmUserEmail"
	| "launchNavigator"
	| "resetPassword"
	| undefined
>(undefined);

export const readyForNavigatorInteractionAtom = atom<boolean>((get) => {
	const authenticationState = get(authenticationStateAtom);
	const user = get(supabaseUserAtom);
	const session = get(supabaseSessionAtom);

	return authenticationState === "launchNavigator" &&
		user?.id &&
		session?.access_token
		? true
		: false;
});
