import { useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";

import { mediaQueries } from "../../style/breakpoints";
import { useAtomValue } from "jotai";
import { authenticationStateAtom } from "../../data/general";
import { darkGray, portalColors } from "../../style";

export default function Billboard() {
	const authenticationState = useAtomValue(authenticationStateAtom);

	const [billboardAnim, billboardAnimAPI] = useSpring(() => ({
		from: {
			defaultOpacity: 1,
			loginOpacity: 0,
			registerOpacity: 0,
			launchNavigatorOpacity: 0,
			confirmEmailOpacity: 0,
			resetPasswordOpacity: 0,
		},
		config: { friction: 30, tension: 300, mass: 2 },
	}));

	useEffect(() => {
		switch (authenticationState) {
			case "login":
				billboardAnimAPI.start({
					to: {
						loginOpacity: 1,
						defaultOpacity: 0,
						registerOpacity: 0,
						launchNavigatorOpacity: 0,
						confirmEmailOpacity: 0,
						resetPasswordOpacity: 0,
					},
				});
				break;

			case "register":
				billboardAnimAPI.start({
					to: {
						loginOpacity: 0,
						defaultOpacity: 0,
						registerOpacity: 1,
						launchNavigatorOpacity: 0,
						confirmEmailOpacity: 0,
						resetPasswordOpacity: 0,
					},
				});
				break;

			case "launchNavigator":
				billboardAnimAPI.start({
					to: {
						loginOpacity: 0,
						defaultOpacity: 0,
						registerOpacity: 0,
						launchNavigatorOpacity: 1,
						confirmEmailOpacity: 0,
						resetPasswordOpacity: 0,
					},
				});
				break;

			case "confirmUserEmail":
				billboardAnimAPI.start({
					to: {
						loginOpacity: 0,
						defaultOpacity: 0,
						registerOpacity: 0,
						launchNavigatorOpacity: 0,
						confirmEmailOpacity: 1,
						resetPasswordOpacity: 0,
					},
				});
				break;

			case "resetPassword":
				billboardAnimAPI.start({
					to: {
						loginOpacity: 0,
						defaultOpacity: 0,
						registerOpacity: 0,
						launchNavigatorOpacity: 0,
						confirmEmailOpacity: 0,
						resetPasswordOpacity: 1,
					},
				});
				break;

			default:
				billboardAnimAPI.start({
					to: {
						defaultOpacity: 1,
						loginOpacity: 0,
						registerOpacity: 0,
						launchNavigatorOpacity: 0,
						confirmEmailOpacity: 0,
						resetPasswordOpacity: 0,
					},
				});
				break;
		}
	}, [authenticationState, billboardAnimAPI]);

	return (
		<animated.div
			id="billboard"
			css={{
				backgroundColor: darkGray,
				padding: 25,
				boxSizing: "border-box",
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-end",
				position: "relative",
				flex: 1,
			}}
		>
			<animated.div
				style={{ opacity: billboardAnim.defaultOpacity }}
				css={{ position: "absolute", paddingRight: 25 }}
			>
				<h1
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 28,
						lineHeight: 1,
						marginBottom: 5,

						[mediaQueries[1]]: {
							fontSize: 36,
							lineHeight: 0.9,
						},
						[mediaQueries[3]]: {
							fontSize: 48,
							lineHeight: 0.9,
							marginRight: 25,
							marginBottom: 10,
						},
					}}
				>
					Let’s form a great story, together.
				</h1>
			</animated.div>
			<animated.div
				style={{ opacity: billboardAnim.loginOpacity }}
				css={{
					position: "absolute",
					marginBottom: 5,
					[mediaQueries[3]]: {
						marginRight: 25,
						marginBottom: 10,
					},
				}}
			>
				<h1
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 28,
						lineHeight: 1,
						marginBottom: 0,
						[mediaQueries[1]]: {
							fontSize: 36,
							lineHeight: 0.9,
						},
						[mediaQueries[3]]: {
							fontSize: 48,
							lineHeight: 0.9,
						},
					}}
				>
					Welcome back.
				</h1>
				<h2
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 22,
						lineHeight: 1,
						fontWeight: 400,
						margin: 0,
					}}
				>
					Let's get you logged in.
				</h2>
			</animated.div>
			<animated.div
				style={{ opacity: billboardAnim.registerOpacity }}
				css={{
					position: "absolute",
					marginBottom: 5,
					marginRight: 50,
					[mediaQueries[3]]: {
						marginRight: 50,
						marginBottom: 10,
					},
				}}
			>
				<h1
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 28,
						lineHeight: 1,
						marginBottom: 0,
						[mediaQueries[1]]: {
							fontSize: 36,
							lineHeight: 0.9,
						},
						[mediaQueries[3]]: {
							fontSize: 48,
							lineHeight: 0.9,
						},
					}}
				>
					Hey Howdie Hey!
				</h1>
				<h2
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 24,
						lineHeight: 1,
						fontWeight: 400,
						marginTop: 2,
					}}
				>
					Welcome to Form Films
				</h2>
			</animated.div>
			<animated.div
				style={{ opacity: billboardAnim.confirmEmailOpacity }}
				css={{
					position: "absolute",
					marginBottom: 5,
					marginRight: 50,
					[mediaQueries[3]]: {
						marginRight: 50,
						marginBottom: 10,
					},
				}}
			>
				<h1
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 28,
						lineHeight: 1.1,
						marginBottom: 0,
						[mediaQueries[1]]: {
							fontSize: 36,
							lineHeight: 0.9,
						},
						[mediaQueries[3]]: {
							fontSize: 48,
							lineHeight: 0.9,
						},
					}}
				>
					Alright Partner-
				</h1>
				<h2
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 24,
						lineHeight: 1,
						fontWeight: 400,
						margin: 0,
					}}
				>
					Go check your email to confirm your account.
				</h2>
			</animated.div>
			<animated.div
				style={{ opacity: billboardAnim.resetPasswordOpacity }}
				css={{
					position: "absolute",
					marginBottom: 5,
					marginRight: 50,
					[mediaQueries[3]]: {
						marginRight: 50,
						marginBottom: 10,
					},
				}}
			>
				<h1
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 28,
						lineHeight: 1,
						marginBottom: 0,
						[mediaQueries[1]]: {
							fontSize: 36,
							lineHeight: 0.9,
						},
						[mediaQueries[3]]: {
							fontSize: 48,
							lineHeight: 0.9,
						},
					}}
				>
					Forgotten Password...
				</h1>
				<h2
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 24,
						lineHeight: 1,
						fontWeight: 400,
						margin: 0,
					}}
				>
					Yup, we know the feeling.
				</h2>
			</animated.div>
			<animated.div
				style={{ opacity: billboardAnim.launchNavigatorOpacity }}
				css={{
					position: "absolute",
					marginBottom: 5,
					marginRight: 50,
					[mediaQueries[3]]: {
						marginRight: 50,
						marginBottom: 10,
					},
				}}
			>
				<h1
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 28,
						lineHeight: 1,
						marginBottom: 0,
						[mediaQueries[1]]: {
							fontSize: 36,
							lineHeight: 0.9,
						},
						[mediaQueries[3]]: {
							fontSize: 48,
							lineHeight: 0.9,
						},
					}}
				>
					Rock n' Roll
				</h1>
				<h2
					css={{
						fontFamily: "Montserrat",
						color: "white",
						fontSize: 24,
						lineHeight: 1,
						fontWeight: 400,
						margin: 0,
					}}
				>
					It's party time.
				</h2>
			</animated.div>
		</animated.div>
	);
}
