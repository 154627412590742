import { Suspense, useMemo } from "react";
import { useAtomValue } from "jotai";
import { stratify } from "@visx/hierarchy";

// Components
import TreemapChartV2 from "../../charts/TreemapV2";

// Definitions & Data
import { mediaQueries } from "../../../style";
import type { FeatureProps } from "../../ClientNavigator/types";
import { AS_userDecisionHierarchyAtom } from "../../../data/analytics/events/userDecision";
import { darkGray, midTones, portalColors } from "../../../style/colors";

// Hooks
import { useAvailableWidth } from "../../../data/hooks/useAvailableWidth";
import { refreshAnalyticsSystemDataAtom } from "../../../data/analytics";
import Color from "color";

export default function UserDecision({ contentAreaHeight }: FeatureProps) {
	const availableWidth = useAvailableWidth(60);
	const userDecisionHierarchy = useAtomValue(AS_userDecisionHierarchyAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	return (
		<Suspense fallback={<div />}>
			<div
				css={{
					boxSizing: "border-box",
					display: "grid",
					gap: 10,
					marginTop: 10,
					gridTemplateRows: `${contentAreaHeight * 1.5}px ${
						contentAreaHeight * 0.75
					}px`,
					gridTemplateColumns: availableWidth,
					[mediaQueries[2]]: {
						gridTemplateRows: `repeat(2, ${contentAreaHeight * 0.75}px)`,
					},
				}}
			>
				<div
					css={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
						width: availableWidth,
					}}
				>
					{Object.keys(userDecisionHierarchy).map((category) => {
						return (
							<div>
								<h1
									css={{
										fontFamily: "Montserrat",
										letterSpacing: -0.75,
										fontWeight: 500,
										fontSize: 20,
										lineHeight: 0.9,
										color: portalColors.gray.light,
										backgroundColor: darkGray,
										width: availableWidth,
										textTransform: "uppercase",
										padding: 10,
										margin: 0,
										marginBottom: 10,
										borderRadius: 2,
										[mediaQueries[1]]: {
											fontSize: 28,
										},
										[mediaQueries[2]]: {
											fontSize: 30,
										},
										[mediaQueries[4]]: {
											fontSize: 32,
										},
									}}
								>
									{category.replaceAll("_", " ")}
								</h1>
								<div
									css={{
										display: "grid",
										gridAutoRows: `minmax(500px, ${
											contentAreaHeight > 500 ? 500 : contentAreaHeight
										}px)`,
										gridTemplateColumns: `repeat(2, ${
											availableWidth / 2 - 7.5
										}px)`,
										gap: 15,
									}}
								>
									{userDecisionHierarchy[category].children?.map(
										(userDecision, decisionIndex) => {
											const primaryColor =
												midTones[decisionIndex % midTones.length];
											const secondaryColor = Color(primaryColor)
												.desaturate(0.75)
												.hex();

											return (
												<TreemapChartV2
													dataRefreshing={dataRefreshing}
													dataColors={[primaryColor, secondaryColor]}
													data={userDecision}
													chartDepth={1}
													chartTitle={{
														callOut:
															userDecision.data.observationLabel.replaceAll(
																"_",
																" ",
															),
														text: "User Decision Breakdown",
													}}
												/>
											);
										},
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</Suspense>
	);
}
