import { useAtomValue } from "jotai";

import DataHighlightBox from "../../charts/DataHighlightBox";
import {
	loadedExperiencesAtom,
	startedExperiencesAtom,
} from "../../../data/analytics";
import { mixColorFamilies, portalColors } from "../../../style";
import { useMemo } from "react";

export default function StartedExperiencesSummary({
	dataRefreshing,
}: {
	dataRefreshing: boolean;
}) {
	const startedExperiences = useAtomValue(startedExperiencesAtom);
	const loadedExperiences = useAtomValue(loadedExperiencesAtom);

	const startingPercentage = useMemo(
		() =>
			startedExperiences.length && loadedExperiences.length
				? (
						(startedExperiences.length / loadedExperiences.length) *
						100
					).toFixed(0)
				: 0,
		[startedExperiences, loadedExperiences],
	);

	return (
		<>
			<DataHighlightBox
				primaryText={startedExperiences.length}
				secondaryText={"Experiences Started"}
				colorFamily={portalColors.blue}
				dataRefreshing={dataRefreshing}
			/>

			<DataHighlightBox
				primaryText={`${startingPercentage}%`}
				secondaryText={"Percentage of Experiences Started"}
				colorFamily={mixColorFamilies(
					portalColors.blue,
					portalColors.gray,
					0.66,
				)}
				dataRefreshing={dataRefreshing}
			/>
		</>
	);
}
