import { useAtomValue } from "jotai";

import { mixColorFamilies, portalColors } from "../../../style/colors";
import DataHighlightBox from "../../charts/DataHighlightBox";

import { mediaQueries } from "../../../style";

import { refreshLearningSystemDataAtom } from "../../../data/learningSystem";
import {
	LS_SelectedCoursesClosedEngagements,
	LS_SelectedCoursesOpenEngagements,
} from "../../../data/learningSystem/engagement/selectedCourses";
import { css } from "@emotion/react";
import Color from "color";

type DataCalloutsProps = {
	width: number;
	height: number;
};

/** Provide summary statistics for all courses in an organization. */
export default function DataCallouts({ width, height }: DataCalloutsProps) {
	const openCourseEngagements = useAtomValue(LS_SelectedCoursesOpenEngagements);
	const closedCourseEngagements = useAtomValue(
		LS_SelectedCoursesClosedEngagements,
	);
	const dataRefreshing = useAtomValue(refreshLearningSystemDataAtom);

	return (
		<div>
			<div
				css={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gridTemplateRows: "1fr 1fr",
					gridAutoFlow: "row",
					gap: 10,
					width,
					minHeight: height,
					boxSizing: "border-box",
					[mediaQueries[2]]: {
						gridAutoFlow: "column",
						gridTemplateColumns: "3fr 2fr 2fr",
						gridTemplateRows: "1fr",
					},
				}}
			>
				<DataHighlightBox
					primaryText={
						openCourseEngagements.length + closedCourseEngagements.length
					}
					secondaryText={
						"Selected Courses started or finished during selected timeframe"
					}
					colorFamily={portalColors.blue}
					dataRefreshing={dataRefreshing}
				/>
				<DataHighlightBox
					primaryText={openCourseEngagements.length}
					secondaryText={
						"Selected Courses started but not finished during timeframe"
					}
					colorFamily={mixColorFamilies(
						portalColors.yellow,
						portalColors.gray,
						0,
					)}
					dataRefreshing={dataRefreshing}
				/>
				<DataHighlightBox
					primaryText={closedCourseEngagements.length}
					secondaryText={"Selected Courses completed During Selected Timeframe"}
					colorFamily={mixColorFamilies(
						portalColors.green,
						portalColors.gray,
						0,
					)}
					dataRefreshing={dataRefreshing}
				/>
			</div>
		</div>
	);
}
