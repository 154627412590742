import { cloneDeep, set } from "lodash";
import { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";

import { ColorFamily, PRIMARY_FONT } from "../../../../../style";

import {
	_LS_EngagementsBaseAtom,
	updateEngagementMetadata,
} from "../../../../../data/learningSystem/engagement";

import { convertToNamingConvention } from "../../../../../utils/text/namingConvention";
import { LS_Engagement } from "../../../../../data/learningSystem/types";

type NewTagProps = {
	observation: LS_Engagement;
	colorTheme: ColorFamily;
	active: boolean;
	setActive: (value: boolean) => void;
};

export default function NewTag({
	observation,
	colorTheme,
	active,
	setActive,
}: NewTagProps) {
	const [tagCategory, setTagCategory] = useState("");
	const [tagName, setTagName] = useState("");
	const tagCategoryInputRef = useRef<HTMLInputElement>(null);

	const createNewTag = async (tagCategory: string, tagName: string) => {
		const standardizedCategory = convertToNamingConvention(tagCategory);
		const standardizedName = convertToNamingConvention(tagName);

		const cloneOfExistingMetadata = cloneDeep(observation.data.metadata);
		const existingCategoryTags =
			cloneOfExistingMetadata.tags?.[standardizedCategory];

		set(
			cloneOfExistingMetadata,
			`tags[${standardizedCategory}]`,
			existingCategoryTags
				? [...existingCategoryTags, standardizedName]
				: [standardizedName],
		);

		await updateEngagementMetadata(observation, cloneOfExistingMetadata);

		setActive(false);
		setTagCategory("");
		setTagName("");
	};

	// Automatically focus on the <input /> element when the
	// component enters an active state.
	useEffect(() => {
		active
			? tagCategoryInputRef.current?.focus()
			: tagCategoryInputRef.current?.blur();
	}, [active]);

	const inputFieldCSS = css({
		width: "100%",
		boxSizing: "border-box",
		fontFamily: PRIMARY_FONT,
		fontSize: 11,
		fontWeight: 600,
		border: "none",
		outline: "none",
		textTransform: "uppercase",
		borderRadius: 5,
		padding: 10,
		color: colorTheme.dark,
		"::placeholder": {
			textTransform: "uppercase",
			color: colorTheme.mid,
		},
	});

	return (
		<div
			id="add-tag-widget"
			css={{
				marginTop: active ? 10 : 0,
				marginBottom: active ? 10 : 0,
				maxHeight: active ? 160 : 0,
				transition: "all .33s ease",
				backgroundColor: colorTheme.light,
				borderRadius: 5,
				padding: active ? 10 : 0,
				opacity: active ? 1 : 0,
				pointerEvents: active ? "auto" : "none",
			}}
		>
			<input
				ref={tagCategoryInputRef}
				id={`add-tag-category-field-${observation.observationLabel}`}
				placeholder="Category"
				value={tagCategory}
				onChange={(event) => setTagCategory(event.target.value)}
				css={inputFieldCSS}
			/>
			<input
				id={`add-tag-name-field-${observation.observationLabel}`}
				placeholder="Name"
				value={tagName}
				onChange={(event) => setTagName(event.target.value)}
				css={[inputFieldCSS, { marginTop: 5 }]}
			/>
			<div
				css={{
					display: "flex",
					justifyContent: "flex-end",
					marginTop: 10,
					gap: 5,
				}}
			>
				<button
					type="button"
					disabled={!tagCategory.length}
					css={{
						fontFamily: PRIMARY_FONT,
						outline: "none",
						borderColor: colorTheme.mid,
						borderWidth: 0,
						borderStyle: "solid",
						borderRadius: 2,
						textTransform: "uppercase",
						fontWeight: 700,
						fontSize: 11,
						padding: 5,
						backgroundColor: "transparent",
						color: colorTheme.dark,
						transition: "all .33s ease",
						"&:not(:disabled):hover": {
							color: "white",
							backgroundColor: colorTheme.mid,
						},
						"&:disabled": {
							opacity: 0.5,
						},
					}}
					onClick={async () => {
						if (tagCategory.length && tagName.length)
							createNewTag(tagCategory, tagName);
					}}
				>
					Submit
				</button>
				<button
					type="button"
					css={{
						fontFamily: PRIMARY_FONT,
						outline: "none",
						borderWidth: 0,
						borderColor: colorTheme.mid,
						borderStyle: "solid",
						borderRadius: 2,
						color: colorTheme.dark,
						textTransform: "uppercase",
						backgroundColor: "transparent",
						fontWeight: 700,
						fontSize: 11,
						transition: "all .33s ease",
						":hover": {
							color: "white",
							backgroundColor: colorTheme.mid,
						},
					}}
					onClick={() => {
						setActive(false);
						setTagCategory("");
					}}
				>
					Cancel
				</button>
			</div>
		</div>
	);
}
