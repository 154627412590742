import { useAtomValue } from "jotai";

import { portalColors } from "../../../style/colors";
import TreemapChart from "../../charts/Treemap";
import {
	mobileDeviceBreakdownAtom,
	refreshAnalyticsSystemDataAtom,
} from "../../../data/analytics";
import { CSSObject, SerializedStyles, css } from "@emotion/react";

export default function MobileDevices({
	additionalCSS = {},
}: { additionalCSS?: CSSObject }) {
	const mobileDeviceBreakdown = useAtomValue(mobileDeviceBreakdownAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	return (
		<TreemapChart
			additionalCSS={additionalCSS}
			dataRefreshing={dataRefreshing}
			depthToChart={2}
			observations={mobileDeviceBreakdown}
			dataColors={[portalColors.blue.mid, portalColors.gray.dark]}
			chartTitle={{
				callOut: "Mobile Devices",
				text: "Distribution of Mobile Devices used to Access Experience",
			}}
			chartExplanation={[
				"This chart displays detailed data on what mobile devices were used by visitors.",
				"Some manufactures report individual device models, while others only report the product line.",
			]}
		/>
	);
}
