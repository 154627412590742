import { useAtomValue } from "jotai";

import { NavigatorFeatureNames } from "../feature";
import { refreshAnalyticsSystemDataAtom } from "../analytics";
import { useMemo } from "react";

/**
 * Use this hook to determine if data is refreshing for
 * any or all of the Navigator's features.
 */
export default function useDataRefreshing(
	feature: "any" | NavigatorFeatureNames,
) {
	const analyticsDataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);
	const learningSystemDataRefreshing = useAtomValue(
		refreshAnalyticsSystemDataAtom,
	);

	return useMemo(() => {
		switch (feature) {
			case "any":
				return analyticsDataRefreshing || learningSystemDataRefreshing;

			case "Analytics":
				return analyticsDataRefreshing;
			case "Learning System":
				return learningSystemDataRefreshing;

			default:
				return false;
		}
	}, [feature, analyticsDataRefreshing, learningSystemDataRefreshing]);
}
