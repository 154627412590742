import { useMemo } from "react";
import { useAtomValue } from "jotai";
import { Player } from "@lottiefiles/react-lottie-player";

import Analytics from "../Analytics";
import FeatureNavigation from "../FeatureNavigation";

import {
	currentScreenDimensionsAtom,
	featureNavDimensionsAtom,
	globalNavDimensionsAtom,
} from "../../data/componentDimensions";

import { mediaQueries, MEDIA_BREAKPOINTS } from "../../style";
import LearningSystem from "../LearningSystem";
import Commerce from "../Commerce";

// Assets
import LoadingAnimation from "../../assets/lottie/loadingAnimation.json";
import { refreshAnalyticsSystemDataAtom } from "../../data/analytics";
import { refreshLearningSystemDataAtom } from "../../data/learningSystem";

export default function ContentArea() {
	const browserDimensions = useAtomValue(currentScreenDimensionsAtom);
	const featureNavDimensions = useAtomValue(featureNavDimensionsAtom);
	const gloablNavDimensions = useAtomValue(globalNavDimensionsAtom);

	const analyticsDataLoading = useAtomValue(refreshAnalyticsSystemDataAtom);
	const learningSystemDataLoading = useAtomValue(refreshLearningSystemDataAtom);

	// Determine the available dimensions for the content area.
	const contentAreaDimensions = useMemo<[number, number]>(
		() =>
			browserDimensions[0] < MEDIA_BREAKPOINTS[1]
				? [
						featureNavDimensions[0] - 60, // 60 is to adjust for padding
						browserDimensions[1] -
							featureNavDimensions[1] -
							gloablNavDimensions[1],
					]
				: [
						featureNavDimensions[0] - 60, // 60 is to adjust for padding
						browserDimensions[1] - featureNavDimensions[1],
					],
		[browserDimensions, featureNavDimensions, gloablNavDimensions],
	);

	return (
		<section
			id="primary-content"
			css={{
				display: "flex",
				flex: 1,
				flexDirection: "column",
				overflowX: "hidden",
				boxSizing: "border-box",
				marginBottom: 90, // Compensate for the global nav on mobile.
				width: "100%",
				maxWidth: "100%",
				overflowY: "scroll",
				[mediaQueries[1]]: {
					marginBottom: 0,
				},
			}}
		>
			<FeatureNavigation />

			<div
				id="feature-content"
				css={{
					position: "relative",
					padding: 30,
					paddingTop: 0,
				}}
			>
				{analyticsDataLoading || learningSystemDataLoading ? (
					<div
						css={{
							position: "absolute",
							width: contentAreaDimensions[0],
							height: contentAreaDimensions[1],
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Player
							autoplay
							loop
							renderer="svg"
							src={LoadingAnimation}
							css={{ opacity: 0.7, width: contentAreaDimensions[0] / 2 }}
						/>
					</div>
				) : (
					<>
						<Analytics contentAreaHeight={contentAreaDimensions[1]} />
						<LearningSystem contentAreaHeight={contentAreaDimensions[1]} />
						<Commerce contentAreaHeight={contentAreaDimensions[1]} />
					</>
				)}
			</div>
		</section>
	);
}
