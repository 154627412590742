import { useMemo } from "react";
import { LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import { useAtomValue } from "jotai";

import { generateColorOffsetArray, portalColors } from "../../../style/colors";
import {
	AS_categoryGoalAchievementsByDateAtom,
	AS_goalEventsByCategoryAtom,
} from "../../../data/analytics/events";
import CategoricalBarChart from "../../charts/Bar/CategoricalBarChart";
import type { OBSERVATION_WITH_STRING_LABEL } from "../../../types/charts";
import { refreshAnalyticsSystemDataAtom } from "../../../data/analytics";
import Color from "color";
import LineChart from "../../charts/Line";
import { PRIMARY_FONT } from "../../../style";
import { convertFromNamingConvention } from "../../../utils/text/namingConvention";

type GOAL_OBSERVATION = OBSERVATION_WITH_STRING_LABEL<{
	count: number;
	percentage: number;
}>;

export default function GoalsByCategory({
	height,
	width,
}: { height: number; width: number }) {
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);
	const goalEventsByCategory = useAtomValue(AS_goalEventsByCategoryAtom);
	const categoryGoalsAchievementsByDate = useAtomValue(
		AS_categoryGoalAchievementsByDateAtom,
	);

	const goalCategories = Object.keys(goalEventsByCategory);

	// What are the unique names of goals in each category?
	const uniqueGoalsInEachCategory = useMemo(() => {
		return goalCategories.reduce<{ [category: string]: string[] }>(
			(accumulation, category) => ({
				...accumulation,
				[category]: [
					...new Set(
						goalEventsByCategory[category].map(
							(goalEvent) => goalEvent.data.data.name,
						),
					),
				],
			}),
			{},
		);
	}, [goalCategories, goalEventsByCategory]);

	const goalCountsByCategory = useMemo(() => {
		const summaryByCategory: {
			[category: string]: GOAL_OBSERVATION[];
		} = {};

		for (const [categoryName, categoryData] of Object.entries(
			goalEventsByCategory,
		)) {
			const dataSummary = categoryData.reduce<GOAL_OBSERVATION[]>(
				(accumulated, current) => {
					const goalName =
						current.data.data.name?.replaceAll("_", " ") ??
						current.data.data.type?.replaceAll("_", " ");

					const existingRecord = accumulated.find(
						(record) => record.observationLabel === goalName,
					);

					return [
						...accumulated.filter(
							(record) => record.observationLabel !== goalName,
						),
						existingRecord
							? {
									...existingRecord,
									data: {
										count: existingRecord.data.count + 1,
										percentage:
											(existingRecord.data.count + 1) / categoryData.length,
									},
								}
							: {
									observationLabel: goalName,
									data: {
										count: 1,
										percentage: 1 / categoryData.length,
									},
								},
					];
				},
				[],
			);

			summaryByCategory[categoryName] = dataSummary.sort(
				(a, b) => b.data.count - a.data.count,
			);
		}

		return summaryByCategory;
	}, [goalEventsByCategory]);

	return (
		<div css={{ gap: 25, display: "grid" }}>
			{goalCategories.map((goalCategory) => {
				const dataColors = generateColorOffsetArray(
					Color(portalColors.orange.mid).desaturate(0.1),
					-40,
					goalCountsByCategory[goalCategory].length,
				);

				return (
					<div
						css={{
							display: "grid",
							width,
							rowGap: 5,
							columnGap: 15,
							gridTemplateRows: `${height * 0.75}px auto`,
							gridTemplateColumns: `repeat(${2}, ${width * 0.5 - 15 / 2}px)`,
						}}
					>
						<CategoricalBarChart
							dataRefreshing={dataRefreshing}
							key={`goalCategorySummary[0]-${Math.random()}`}
							observations={goalCountsByCategory[goalCategory]}
							chartTitle={{
								text: `${goalCountsByCategory[goalCategory].reduce(
									(previous, current) => previous + current.data.count,
									0,
								)} goal achievements were recorded for this category`,
								callOut: `${goalCategory.replace("_", " ")} Summary`,
							}}
							dataColors={dataColors}
							gridLines="rows"
							chartExplanation={[
								`Goals are organized into categories. In this case, the category is "${goalCategory.replace(
									"_",
									" ",
								)}".`,
								"Any number of goals can be associated with a category. Each one of them is shown as a separate bar in this chart.",
							]}
						/>
						<LineChart
							seriesNames={uniqueGoalsInEachCategory[goalCategory]}
							dataRefreshing={dataRefreshing}
							axes={{ observationLabel: "bottom" }}
							observations={categoryGoalsAchievementsByDate[goalCategory]}
							chartTitle={{
								text: "Goal Achievements by Date",
								callOut: `${goalCategory.replace("_", " ")} Chronology`,
							}}
							dataColors={dataColors}
							gridLines="rows"
							chartExplanation={[
								`This chart also displays ${goalCategory.replace(
									"_",
									" ",
								)} goal achievements, but in a chronological manner.`,
							]}
						/>
						<div
							id={`shared-chart-legend-${goalCategory}`}
							css={{
								backgroundColor: portalColors.gray.light,
								borderRadius: 5,
								padding: 20,
								gridColumn: "span 2",
								display: "grid",
								gridTemplateRows: "1fr 1fr 1fr",
								gridAutoFlow: "column",
							}}
						>
							{uniqueGoalsInEachCategory[goalCategory].map((goal, index) => (
								<div css={{ display: "flex", alignItems: "center", gap: 5 }}>
									<div
										css={{
											height: 12,
											width: 12,
											backgroundColor: dataColors[index],
											borderRadius: 2,
										}}
									/>
									<span
										css={{
											fontSize: 11,
											fontFamily: PRIMARY_FONT,
											textTransform: "capitalize",
											fontWeight: 500,
											color: portalColors.gray.dark,
										}}
									>
										{goal}
									</span>
								</div>
							))}
							{/* <LegendOrdinal
								scale={scaleOrdinal({
									domain: uniqueGoalsInEachCategory[goalCategory],
									range: dataColors,
								})}
								direction="column"
								labelMargin="0 0px 0 2px"
								labelFormat={(item) => convertFromNamingConvention(item)}
								shapeHeight={10}
								shapeWidth={10}
								css={{
									fontSize: 11,
									fontFamily: PRIMARY_FONT,
									textTransform: "capitalize",
									fontWeight: 500,
									color: portalColors.gray.dark,
									display: "grid",
									gridTemplateColumns: "1fr 1fr",
								}}
							/> */}
						</div>
					</div>
				);
			})}
		</div>
	);
}
