import { ObjectWithStringKeys } from "@form-films/atlas-engine-shared-definitions";
import { getDefaultStore } from "jotai";

import supabaseClientAtom from "../supabaseClient";
import {
	isCurrentUserAdmin,
	supabaseSessionAtom,
	supabaseUserAtom,
	supabaseUserIsAdminAtom,
} from "../user";

/**
 * Register a new portal user.
 *
 * The user must provide a valid username, password,
 * and enrollment code for this operation to be
 * successful.
 */
export default async function registerUser(
	username: string,
	password: string,
	enrollmentCode: string,
	metadata: ObjectWithStringKeys,
): Promise<boolean> {
	const jotai = getDefaultStore();
	const supabase = jotai.get(supabaseClientAtom);

	// Evaluate if the enrollment code provided is valid
	const { data: codeValidResponse, error: codeValidError } = await supabase.rpc(
		"check_portal_enrollment_code",
		{
			enrollment_code_param: enrollmentCode,
		},
	);

	if (codeValidError) throw Error(codeValidError.message);

	// Register User
	const { data: signupData, error: signupError } = await supabase.auth.signUp({
		email: username,
		password: password,
		options: {
			data: metadata,
		},
	});

	if (signupError) throw Error(signupError?.message ?? "Unexpected error.");

	// Set the user and session variables
	jotai.set(supabaseUserAtom, signupData.user!);
	jotai.set(supabaseSessionAtom, signupData.session!);

	// Determine if the user is an admin or not.
	const userIsAdmin = await isCurrentUserAdmin();
	jotai.set(supabaseUserIsAdminAtom, userIsAdmin);

	// Attempt to add user to organization as a manager
	const { error } = await supabase.rpc("add_organization_manager", {
		user_id: signupData.user!.id,
		param_enrollment_code: enrollmentCode,
	});

	if (error) {
		throw Error(error.message);
	}

	return true;
}
