import type { AtlasEngineDatabase } from "@form-films/atlas-engine-shared-definitions";
import type { PostgrestFilterBuilder } from "@supabase/postgrest-js";
import { getDefaultStore } from "jotai";
import { currentAtlasProjectAtom } from "../project";
import { supabaseSessionAtom } from "../user";

export async function gatherRecords<RowType extends Record<string, unknown>>(
	dataQuery: PostgrestFilterBuilder<
		AtlasEngineDatabase["public"],
		RowType,
		RowType[]
	>,
) {
	const DATABASE_ROW_LIMIT = 100000;
	const accumulatedData: RowType[] = [];

	async function collectRecords() {
		const { data, error } = await dataQuery.range(
			accumulatedData.length,
			accumulatedData.length + DATABASE_ROW_LIMIT - 1,
		);

		if (data) {
			accumulatedData.push(...data);
			if (data.length === DATABASE_ROW_LIMIT) await collectRecords();
		}
	}

	const jotaiStore = getDefaultStore();
	const currentProject = jotaiStore.get(currentAtlasProjectAtom);
	const userSession = jotaiStore.get(supabaseSessionAtom);

	if (!userSession || !currentProject) return [];

	await collectRecords();
	return accumulatedData;
}
