import { DateTime, Interval } from "luxon";

/** Obtain the earliest and latest dates from an array of dates. */
export const getMinMaxDates = (dates: DateTime[]): [DateTime, DateTime] => {
	const datesAsNumbers = dates.map((date) => date.valueOf());
	return [
		DateTime.fromMillis(Math.min(...datesAsNumbers)),
		DateTime.fromMillis(Math.max(...datesAsNumbers)),
	];
};

function* days(interval: Interval) {
	if (!interval.isValid) throw new Error("The `Interval` provided is invalid.");

	let cursor = interval.start!.startOf("day");
	while (cursor <= interval.end!) {
		yield cursor;
		cursor = cursor.plus({ days: 1 });
	}
}

/** Return an array of all dates between a start and end date. */
export const getDateRange = (startDate: DateTime, endDate: DateTime) => {
	return Array.from(days(Interval.fromDateTimes(startDate, endDate)));
};

// Useful Type Guard Function
export function isDateTime(object: unknown): object is DateTime {
	return object instanceof DateTime;
}
