import { useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";
import {
	supabaseUserAtom,
	supabaseUserIsAdminAtom,
} from "../../data/analytics";
import { portalColors, PRIMARY_FONT } from "../../style";

// Assets
import PowerIcon from "../../assets/svg/icons/powerIcon.svg?react";

export default function UserInfo() {
	const isUserAdmin = useAtomValue(supabaseUserIsAdminAtom);
	const [user, setUser] = useAtom(supabaseUserAtom);

	const { userDisplayName, userTitle } = useMemo(() => {
		return {
			userDisplayName:
				user &&
				`${user.user_metadata.firstName} ${user.user_metadata.lastName} `,
			userTitle: user?.user_metadata.title ?? "Superstar Data Analyst",
		};
	}, [user]);

	return (
		<div css={{ position: "absolute", left: 45, bottom: 15 }}>
			<h3
				css={{
					fontFamily: PRIMARY_FONT,
					fontSize: 24,
					fontWeight: 700,
					margin: 0,
					color: "white",
					letterSpacing: -0.5,
					marginBottom: -3,
				}}
			>
				{userDisplayName}
			</h3>
			<p
				css={{
					fontFamily: PRIMARY_FONT,
					color: "white",
					fontSize: 11,
					fontWeight: 600,
					margin: 0,
				}}
			>
				{userTitle}
			</p>
			{isUserAdmin && (
				<div
					css={{
						backgroundColor: portalColors.orange.mid,
						marginTop: 5,
						borderRadius: 5,
						padding: 5,
						paddingRight: 10,
						display: "flex",
						alignItems: "center",
					}}
				>
					<PowerIcon css={{ height: 20, fill: "white" }} />
					<span
						css={{
							fontFamily: PRIMARY_FONT,
							color: "white",
							fontSize: 10,
							fontWeight: 600,
							margin: 0,
							textTransform: "uppercase",
						}}
					>
						Phenomenal Cosmic Powers
					</span>
				</div>
			)}
		</div>
	);
}
