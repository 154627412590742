import { ColorFamily } from "../../../../style";

export type CardLabelProps = {
  text: string | undefined;
  colorTheme: ColorFamily;
};

export default function CardLabel({ text, colorTheme }: CardLabelProps) {
  return (
    <label
      css={{
        fontWeight: 600,
        color: colorTheme.mid,
        textTransform: "uppercase",
        fontSize: 10,
      }}>
      {text}
    </label>
  );
}
