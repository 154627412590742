import { useAtomValue } from "jotai";

// Components
import PieChart from "../../charts/PieChart";

// Data
import { portalColors } from "../../../style/colors";
import {
	AS_mobileDesktopSummaryAtom,
	refreshAnalyticsSystemDataAtom,
} from "../../../data/analytics";

export default function MobileVsDesktopSummary() {
	const mobileDesktopComparison = useAtomValue(AS_mobileDesktopSummaryAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	return (
		<PieChart
			observations={mobileDesktopComparison}
			dataRefreshing={dataRefreshing}
			chartTitle={{
				callOut: "Summary",
				text: "Mobile vs Desktop Users",
			}}
			dataColors={[portalColors.blue.mid, portalColors.orange.mid]}
			chartExplanation={[
				"Chart of what percentage of users are on mobile vs. desktop devices during your selected timeframe.",
				"Note that this breakdown is for all users, whether or not they started the experience.",
			]}
		/>
	);
}
