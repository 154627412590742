import { atom } from "jotai";
import { startedExperiencesGroupedByDateAtom } from "../analytics/experience";
import { AS_TransferCartEvents } from "./transferCart";
import { AS_AddCartItemEvents } from "./addItem";
import { OBSERVATION_WITH_DATE_LABEL } from "../../types/charts";
import { DateTime } from "luxon";

/** Percentage of started experiences that transferred cart data grouped by date. */
export const AS_DailyCommerceFunnelPercentages = atom((get) => {
	const startedExperiencesByDate = get(startedExperiencesGroupedByDateAtom);

	const addCartItemEvents = get(AS_AddCartItemEvents);
	const transferCartEvents = get(AS_TransferCartEvents);

	const uniqueExperiencesWithTransferCartEvents = [
		...new Set(transferCartEvents.map((event) => event.data.experience_id)),
	];

	const uniqueExperiencesWithAddItemEvents = [
		...new Set(addCartItemEvents.map((event) => event.data.experience_id)),
	];

	/**
	 * Determine the percentage of started experiences that
	 * have transferCart events by date.
	 * */
	const dailyPercentages = Object.entries(startedExperiencesByDate).reduce<
		OBSERVATION_WITH_DATE_LABEL<{
			add_item_percentage: number;
			transfer_cart_percentage: number;
		}>[]
	>((previous, [currentDate, currentDateExperiences]) => {
		/** Get the daily count of unique experiences with various event types */
		const dailyExperienceCounts = {
			addItem: 0,
			transferCart: 0,
		};

		for (const experience of currentDateExperiences) {
			if (
				uniqueExperiencesWithAddItemEvents.includes(experience.observationLabel)
			)
				dailyExperienceCounts.addItem += 1;

			if (
				uniqueExperiencesWithTransferCartEvents.includes(
					experience.observationLabel,
				)
			)
				dailyExperienceCounts.transferCart += 1;
		}

		return [
			...previous,
			{
				observationLabel: DateTime.fromISO(currentDate),
				data: {
					add_item_percentage:
						dailyExperienceCounts.addItem / currentDateExperiences.length,
					transfer_cart_percentage:
						dailyExperienceCounts.transferCart / currentDateExperiences.length,
				},
			},
		];
	}, []);

	return dailyPercentages;
});
