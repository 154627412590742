import { CSSProperties } from "react";
import { mediaQueries } from "../../style/breakpoints";
import { PRIMARY_FONT } from "../../style/fonts";

export type ChartTitleProps = {
	callOut?: string;
	text: string;
	color: CSSProperties["color"];
	containerCSS?: CSSProperties;
};

export default function ChartTitle({
	callOut,
	text,
	color,
	containerCSS = {},
}: ChartTitleProps) {
	const renderCallout = () => {
		if (!callOut) return null;

		return (
			<h1
				css={{
					fontFamily: "Montserrat",
					letterSpacing: -0.75,
					fontWeight: 800,
					fontSize: 20,
					lineHeight: 0.9,
					color: color,
					margin: 0,
					[mediaQueries[1]]: {
						fontSize: 28,
						maxWidth: "70%",
					},
					[mediaQueries[2]]: {
						fontSize: 30,
					},
					[mediaQueries[4]]: {
						fontSize: 32,
					},
				}}
			>
				{callOut}
			</h1>
		);
	};

	return (
		<div
			css={[
				{
					display: "flex",
					flexDirection: "column",
					gap: 2,
					[mediaQueries[4]]: { gap: 5 },
				},
				{ ...containerCSS },
			]}
		>
			{renderCallout()}
			<p
				css={{
					fontFamily: PRIMARY_FONT,
					lineHeight: 1.1,
					fontWeight: 500,
					fontSize: 12,
					margin: 0,
					marginTop: 1,
					color: color,
					[mediaQueries[4]]: {
						fontSize: 14,
					},
				}}
			>
				{text}
			</p>
		</div>
	);
}
