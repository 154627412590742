import { useEffect, useMemo, useState } from "react";
import { useTrail, animated, easings } from "@react-spring/web";
import { useTooltip } from "@visx/tooltip";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { LegendOrdinal } from "@visx/legend";
import { AxisRight } from "@visx/axis";
import { LinePath, Circle, Bar, Line } from "@visx/shape";
import * as allCurves from "@visx/curve";
import type { ScaleBand } from "d3-scale";

// Components
import GridLines from "../GridLines";
import CustomDateTimeBottomAxis from "../CustomDateTimeBottomAxis";
import Tooltip from "../../widgets/Tooltip";

// Data & Definitions
import { PRIMARY_FONT, darkGray, portalColors } from "../../../style";
import {
	type OBSERVATION_WITH_NUMERIC_VALUES,
	areObservationsDateTimeLabeled,
} from "../../../types/charts";
import type { MULTI_SERIES_CHART_PROPS } from "../../../types/charts/props";

// Hooks
import useDimensions from "react-cool-dimensions";
import useSVGDimensions from "../../../hooks/useSVGDimensions";
import useBandScale from "../../../hooks/useBandScale";
import useLinearScale from "../../../hooks/useLinearScale";
import useFlattenSeriesValues from "../../../data/hooks/useFlattenSeriesValues";
import type { DateTime } from "luxon";
import { convertFromNamingConvention } from "../../../utils/text/namingConvention";
import { isDateTime } from "../../../utils/datesAndTimes";
import ChartContainer from "../../ChartContainer";

/** Default Line Chart */
export default function LineChart<
	ObservationType extends OBSERVATION_WITH_NUMERIC_VALUES,
>({
	observations,
	dataRefreshing,
	axes,
	seriesNames,
	margins = { vertical: 25, horizontal: 25 },
	dataColors,
	chartTitle,
	colorTheme = portalColors.gray,
	transparentBackground = false,
	displayLegend,
	gridLines = "rows",
	labelFormatter = (label) => label.toString(),
	dataFormatter = (data) => data.toString(),
	tooltip,
	chartActions,
	chartExplanation,
	additionalCSS,
}: MULTI_SERIES_CHART_PROPS<ObservationType>) {
	const [highlightedRecordIndex, setHighlightedRecordIndex] = useState<
		number | null
	>(null);

	const {
		observe: observeComponent,
		width: componentWidth,
		height: componentHeight,
	} = useDimensions({
		useBorderBoxSize: true,
	});

	// Need to observe the height of a couple of sub-components.
	const { observe: observeTitle, height: titleHeight } = useDimensions({
		useBorderBoxSize: true,
	});

	const { observe: observeLegend, height: legendHeight } = useDimensions({
		useBorderBoxSize: true,
	});

	// Determine the effective dimensions for the actual SVG element.
	const svgDimensions = useSVGDimensions({
		width: componentWidth,
		height: componentHeight,
		margins,
		heightSubstractors: [titleHeight, legendHeight],
	});

	const xScale = useBandScale({
		domain: observations.map((datum) => datum.observationLabel),
		range: [0, svgDimensions[0] - 30],
	});

	const flattenedObservationValues = useFlattenSeriesValues(
		observations,
		seriesNames,
	) as number[];

	const yScale = useLinearScale({
		domain: [
			Math.min(...flattenedObservationValues),
			Math.max(...flattenedObservationValues),
		],
		range: [svgDimensions[1] - (axes?.observationLabel ? 40 : 0), 0],
	});

	const colorScale = useMemo(
		() =>
			scaleOrdinal({
				domain: seriesNames,
				range: dataColors,
			}),
		[dataColors, seriesNames],
	);

	const [lineTrails, lineTrailsAPI] = useTrail(seriesNames.length, () => ({
		from: { opacity: 0, filter: "saturate(0%)" },
		config: { easing: easings.easeInOutCirc, duration: 100 },
	}));
	const [markerTrails, markerTrailsAPI] = useTrail(
		observations.length,
		(index) => ({
			from: { opacity: 0, filter: "saturate(0%)" },
			config: { easing: easings.easeInOutCirc, duration: 100 },
		}),
	);

	useEffect(() => {
		if (dataRefreshing) {
			lineTrailsAPI.start({
				to: { opacity: 0, filter: "saturate(0%)" },
				immediate: true,
			});
			markerTrailsAPI.start({
				to: { opacity: 0, filter: "saturate(0%)" },
				immediate: true,
			});
		} else {
			lineTrailsAPI.start({
				to: { opacity: 1, filter: "saturate(100%)" },
				immediate: false,
				onRest: () => {
					markerTrailsAPI.start({
						to: { opacity: 1, filter: "saturate(100%)" },
						immediate: false,
					});
				},
			});
		}
	}, [dataRefreshing, lineTrailsAPI, markerTrailsAPI]);

	const {
		tooltipData,
		tooltipLeft,
		tooltipTop,
		tooltipOpen,
		showTooltip,
		hideTooltip,
	} = useTooltip<ObservationType>();

	const renderTooltip = () => {
		return (
			tooltipOpen &&
			tooltipData && (
				<Tooltip
					positioning={{ top: tooltipTop, left: tooltipLeft }}
					title={tooltip?.title ?? "Record Details"}
					subtitle={
						isDateTime(tooltipData.observationLabel)
							? tooltipData.observationLabel.toISODate()!
							: tooltipData.observationLabel
					}
					animationDelay={350}
					additionalData={tooltipData.data}
					additionalDataLabelFormatter={labelFormatter}
					additionalDataFormatter={dataFormatter}
					// additionalDataDisregardFalseyValues={true}
					colorScale={colorScale}
				/>
			)
		);
	};

	return (
		<ChartContainer
			colorTheme={colorTheme}
			margins={margins}
			transparentBackground={transparentBackground}
			chartTitle={{ ...chartTitle }}
			chartExplanation={chartExplanation}
			observer={observeComponent}
			titleObserver={observeTitle}
			chartActions={chartActions}
			additionalCSS={additionalCSS}
		>
			{renderTooltip()}
			{svgDimensions[0] && svgDimensions[1] && (
				<svg
					viewBox={`0 0 ${svgDimensions[0]} ${svgDimensions[1]}`}
					css={{
						width: svgDimensions[0],
						height: svgDimensions[1],
						overflow: "unset",
					}}
				>
					<title>Line Chart</title>
					<Group name="current-record-highlight">
						{observations.map((observation, index) => (
							<Line
								key={`line-${observation.observationLabel}`}
								from={{
									x:
										xScale.bandwidth() / 2 +
										(xScale(observation.observationLabel) ?? 0),
									y: 0,
								}}
								to={{
									x:
										xScale.bandwidth() / 2 +
										(xScale(observation.observationLabel) ?? 0),
									y: yScale(0),
								}}
								x={xScale(observation.observationLabel)}
								y={0}
								height={yScale(0)}
								strokeWidth={highlightedRecordIndex === index ? 2 : 1}
								stroke={colorTheme.mid}
								strokeDasharray={"4"}
								css={{
									opacity: highlightedRecordIndex === index ? 0.75 : 0,
								}}
							/>
						))}
					</Group>
					<GridLines
						lineOption={gridLines}
						lineColor={colorTheme.mid}
						xScale={xScale}
						yScale={yScale}
						width={svgDimensions[0] - 30}
						height={svgDimensions[1]}
					/>
					<Group name="lines">
						{seriesNames.map((series, lineIndex) => {
							return (
								<animated.g style={lineTrails[lineIndex]} key={lineIndex}>
									<LinePath
										curve={allCurves.curveMonotoneX}
										data={observations}
										x={(d) =>
											(xScale(d.observationLabel) ?? 0) + xScale.bandwidth() / 2
										}
										y={(d) => yScale(d.data[series])}
										stroke={colorScale(series)}
										strokeWidth={3}
									/>
									{observations.map((observation, markerIndex) => {
										return (
											<animated.g
												style={markerTrails[markerIndex]}
												key={`line-${lineIndex}-marker-${markerIndex}`}
											>
												<Circle
													css={{ transition: "r .25s ease" }}
													r={highlightedRecordIndex === markerIndex ? 8 : 4}
													cx={
														(xScale(observation.observationLabel) ?? 0) +
														xScale.bandwidth() / 2
													}
													cy={yScale(observation.data[series])}
													fill={
														observation.data[series]
															? colorScale(series)
															: "transparent"
													}
												/>
											</animated.g>
										);
									})}
								</animated.g>
							);
						})}
					</Group>
					<Group name="axes">
						<AxisRight
							left={svgDimensions[0] - 15}
							// top={5}
							scale={yScale}
							hideZero
							tickLength={0}
							stroke={"transparent"}
							tickFormat={dataFormatter}
							tickStroke={darkGray}
							tickLabelProps={() => ({
								fill: colorTheme.dark,
								fontSize: 11,
								// textAnchor: "start",
								verticalAnchor: "middle",
								x: -5,
							})}
						/>
						{axes?.observationLabel === "bottom" &&
						areObservationsDateTimeLabeled(observations) ? (
							<CustomDateTimeBottomAxis
								yPosition={svgDimensions[1] - 40}
								color={colorTheme.dark}
								dateScale={xScale as ScaleBand<DateTime>}
							/>
						) : null}
					</Group>
					<Group name="hidden-bars">
						{observations.map((observation, hiddenBarIndex) => (
							<Group key={`hidden-bar-${hiddenBarIndex}`}>
								<Bar
									x={xScale(observation.observationLabel)}
									y={0}
									height={yScale(0)}
									width={xScale.bandwidth()}
									fill="transparent"
									onMouseMove={(event) => {
										const left =
											(xScale(observation.observationLabel) ?? 0) +
											xScale.bandwidth();

										showTooltip({
											tooltipData: observation,
											tooltipTop: titleHeight,
											tooltipLeft: left,
										});
										setHighlightedRecordIndex(hiddenBarIndex);
									}}
									onMouseLeave={() => {
										hideTooltip();
										setHighlightedRecordIndex(null);
									}}
								/>
							</Group>
						))}
					</Group>
				</svg>
			)}

			{displayLegend && (
				<div ref={observeLegend} css={{ paddingTop: 5, paddingBottom: 5 }}>
					<LegendOrdinal
						scale={colorScale}
						direction="column"
						labelMargin="0 0px 0 2px"
						labelFormat={(item) => convertFromNamingConvention(item)}
						shapeHeight={10}
						shapeWidth={10}
						css={{
							fontSize: 11,
							fontFamily: PRIMARY_FONT,
							textTransform: "capitalize",
							fontWeight: 500,
							color: colorTheme.dark,
						}}
					/>
				</div>
			)}
		</ChartContainer>
	);
}
