import { Getter, Setter, atom } from "jotai";
import { AtlasEngineDatabase } from "@form-films/atlas-engine-shared-definitions";

import supabaseClientAtom from "../supabaseClient";
import { _LS_EngagementsBaseAtom } from "./engagement";
import { gatherRecords } from "../utils/utilities";
import { OBSERVATION_WITH_STRING_LABEL } from "../../types/charts";
import { convertToObservationsWithStringLabels } from "../../utils/observation";

type EngagementDetailObservation = OBSERVATION_WITH_STRING_LABEL<
	Omit<
		AtlasEngineDatabase["public"]["Tables"]["learning_system_course_engagement_detail"]["Row"],
		"id"
	>
>;

/**
 * Get all course engagement detail records accessible by authenticated user.
 *
 * RLS policies will only allow the user to SELECT records
 * that belong to the user directly or those that that belong
 * to organizations the user is also associated with.
 * */
export const LS_EngagementDetailsAtom = atom<EngagementDetailObservation[]>([]);

export const LS_FinishedEngagementDetailsAtom = atom((get) =>
	get(LS_EngagementDetailsAtom).filter((detail) => detail.data.finished_at),
);

export async function updateLearningSystemEngagementDetails(
	get: Getter,
	set: Setter,
) {
	const supabaseClient = get(supabaseClientAtom);
	const courseEngagements = get(_LS_EngagementsBaseAtom);

	const records = await gatherRecords<
		AtlasEngineDatabase["public"]["Tables"]["learning_system_course_engagement_detail"]["Row"]
	>(
		supabaseClient
			.from("learning_system_course_engagement_detail")
			.select("*")
			.order("started_at")
			.in(
				"engagement_id",
				courseEngagements.map((engagement) => engagement.observationLabel),
			),
	);

	set(
		LS_EngagementDetailsAtom,
		convertToObservationsWithStringLabels(records, "id"),
	);
}
