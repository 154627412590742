import { atom } from "jotai";

import { AS_eventsObservationsFilteredByEnclosingURLsAtom } from "../analytics/events";
import { AddCartItemEvent } from "./types";
import { startedExperiencesAtom } from "../analytics/experience";
import {
	_makeObservation,
	_makeStringBasedObservation,
} from "../../utils/observation";
import { groupByDate } from "../../utils/groupBy";
import { OBSERVATION_WITH_SINGLE_NUMERIC_VALUE } from "../../types/charts";
import { allDatesInUserSelectionAtom } from "../dateTime";

/**
 * Obtain all add_cart_item events.
 * Filtered by the currently selected enclosing URLs.
 * */
export const AS_AddCartItemEvents = atom((get) => {
	const allEvents = get(AS_eventsObservationsFilteredByEnclosingURLsAtom);

	const addCartItemEvents = allEvents.filter(
		(event) => event.data.event_name === "add_cart_item",
	) as AddCartItemEvent[];

	return addCartItemEvents;
});

/** Count of addCartEvents by date */
export const AS_AddCartEventCountsByDate = atom((get) => {
	const addCartEvents = get(AS_AddCartItemEvents);
	const dateSelection = get(allDatesInUserSelectionAtom);

	const groupedEvents = groupByDate(addCartEvents, "event_timestamp");

	return dateSelection.reduce<Array<OBSERVATION_WITH_SINGLE_NUMERIC_VALUE>>(
		(accumulation, current) => {
			const newAccumulation = [
				...accumulation,
				{
					observationLabel: current,
					data: {
						value:
							current.toISODate()! in groupedEvents
								? groupedEvents[current.toISODate()!].length
								: 0,
					},
				},
			];

			return newAccumulation;
		},
		[],
	);
});

/** Total value added to carts */
export const AS_AllAddedItemsValue = atom((get) => {
	const addItemEvents = get(AS_AddCartItemEvents);

	const sumOfAllAddedItems = addItemEvents.reduce((allItemsTotal, current) => {
		return (
			allItemsTotal +
			(typeof current.data.data.price === "object"
				? current.data.data.price.final
				: current.data.data.price)
		);
	}, 0);

	return _makeObservation(
		{
			label: "sumOfAddedItems",
			value: +sumOfAllAddedItems.toFixed(0),
		},
		"label",
	);
});

/**
 * Average number of added items per experience with engaged shoppers.
 * And "engaged" shopper adds at least one item to a cart.
 * */
export const AS_EngagedShopperAverageNumberOfAddedItems = atom((get) => {
	const addItemEvents = get(AS_AddCartItemEvents);

	const uniqueExperiences = [
		...new Set(addItemEvents.map((event) => event.data.experience_id)),
	];

	return _makeObservation(
		{
			label: "shopperAverageNumberOfAddedItems",
			value: addItemEvents.length / uniqueExperiences.length,
		},
		"label",
	);
});

/**
 * Average number of added items per experience.
 * */
export const AS_AverageNumberOfAddedItems = atom((get) => {
	const addItemEvents = get(AS_AddCartItemEvents);
	const startedExperiences = get(startedExperiencesAtom);

	const uniqueExperiences = [
		...new Set(addItemEvents.map((event) => event.data.experience_id)),
	];

	return _makeStringBasedObservation(
		{
			label: "overallPercentage",
			value: (
				(uniqueExperiences.length / startedExperiences.length) *
				100
			).toFixed(1),
		},
		"label",
	);
});
