import { useAtomValue } from "jotai";

// Components
import StackedBarChart from "../../charts/Bar/StackedBarChart";

// Data
import { portalColors } from "../../../style/colors";
import {
	AS_mobileDesktopCountsByDateAtom,
	refreshAnalyticsSystemDataAtom,
} from "../../../data/analytics";

export default function MobileVsDesktop() {
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);
	const mobileDesktopCountsByDate = useAtomValue(
		AS_mobileDesktopCountsByDateAtom,
	);

	return (
		<div>
			<StackedBarChart
				axes={{ observationLabel: "bottom" }}
				observations={mobileDesktopCountsByDate}
				dataRefreshing={dataRefreshing}
				dataColors={[portalColors.orange.mid, portalColors.blue.mid]}
				chartTitle={{
					callOut: "Mobile / Desktop",
					text: "Comparison of Mobile vs. Desktop User by Day",
				}}
				chartExplanation="This chart provides a day-by-day breakdown of mobile vs. desktop users for your project."
				seriesNames={["desktop", "mobile"]}
				displayLegend
				tooltip={{ title: "Mobile/Desktop Device Breakdown" }}
			/>
		</div>
	);
}
