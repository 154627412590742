import { atom } from "jotai";
import { experiencesFilteredByEnclosingURLAtom } from ".";
import { groupByDate } from "../../../utils/groupBy";

/** All fully loaded experiences for the current project. */
export const loadedExperiencesAtom = atom((get) => {
	const filteredExperiences = get(experiencesFilteredByEnclosingURLAtom);
	return filteredExperiences.filter((experience) => experience.data.ready_at);
});

/** Loaded experiences grouped by date. */
export const loadedExperiencesGroupedByDateAtom = atom((get) => {
	const loadedExperiences = get(loadedExperiencesAtom);
	return groupByDate(loadedExperiences, "loaded_at");
});
