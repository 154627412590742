import { useAtomValue } from "jotai";

import { mixColorFamilies, portalColors } from "../../../style/colors";
import DataHighlightBox from "../../charts/DataHighlightBox";

import { mediaQueries } from "../../../style";
import { LS_OrganizationalCoursesAtom } from "../../../data/learningSystem/courses";
import {
	LS_UsersAtom,
	LS_UsersByEnrollmentDateAtom,
} from "../../../data/learningSystem/users";
import {
	LS_ClosedEngagementsAtom,
	LS_OpenEngagementsAtom,
} from "../../../data/learningSystem/engagement";
import { refreshLearningSystemDataAtom } from "../../../data/learningSystem";

type DataCalloutsProps = {
	width: number;
	height: number;
};

/** Provide summary statistics for all courses in an organization. */
export default function DataCallouts({ width, height }: DataCalloutsProps) {
	const courses = useAtomValue(LS_OrganizationalCoursesAtom);
	const organizationUsers = useAtomValue(LS_UsersAtom);
	const openCourseEngagements = useAtomValue(LS_OpenEngagementsAtom);
	const closedCourseEngagements = useAtomValue(LS_ClosedEngagementsAtom);
	const dataRefreshing = useAtomValue(refreshLearningSystemDataAtom);
	const enrollmentsByDate = useAtomValue(LS_UsersByEnrollmentDateAtom);

	const newEnrollments = enrollmentsByDate.reduce(
		(previous, current) => previous + current.data.value,
		0,
	);

	return (
		<div>
			<div
				css={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					// gridAutoRows: "1fr",
					gap: 10,
					width,
					minHeight: height,
					boxSizing: "border-box",
					marginBottom: 10,
					[mediaQueries[0]]: {
						gridTemplateColumns: "repeat(3, 1fr)",
					},
					[mediaQueries[2]]: {
						gridTemplateColumns: "repeat(3, 1fr)",
					},
					[mediaQueries[4]]: {
						gridTemplateColumns: "repeat(4, 1fr)",
						gridTemplateRows: "1fr 1fr",
						gridAutoFlow: "column",
					},
				}}
			>
				<DataHighlightBox
					primaryText={`${courses.length}`}
					secondaryText={"Courses Available to users in your organization"}
					colorFamily={portalColors.blue}
					dataRefreshing={dataRefreshing}
					additionalStyles={{
						gridColumn: "span 2",
						gridRow: "span 2",
						[mediaQueries[0]]: { gridColumn: "span 1", gridRow: "span 1" },
						[mediaQueries[4]]: { gridColumn: "span 1", gridRow: "span 2" },
					}}
				/>

				<DataHighlightBox
					primaryText={organizationUsers.length}
					secondaryText={"Users in your organization"}
					colorFamily={mixColorFamilies(
						portalColors.blue,
						portalColors.gray,
						0.5,
					)}
					dataRefreshing={dataRefreshing}
				/>
				<DataHighlightBox
					primaryText={newEnrollments}
					secondaryText={"New users who signed up during this period"}
					colorFamily={mixColorFamilies(
						portalColors.blue,
						portalColors.gray,
						0.75,
					)}
					dataRefreshing={dataRefreshing}
				/>
				<DataHighlightBox
					primaryText={
						openCourseEngagements.length + closedCourseEngagements.length
					}
					secondaryText={
						"Courses started or finished during selected timeframe"
					}
					colorFamily={portalColors.green}
					dataRefreshing={dataRefreshing}
					additionalStyles={{
						gridColumn: "span 2",
						gridRow: "span 2",
						[mediaQueries[0]]: { gridColumn: "span 1", gridRow: "span 1" },
						[mediaQueries[4]]: { gridColumn: "span 1", gridRow: "span 2" },
					}}
				/>
				<DataHighlightBox
					primaryText={openCourseEngagements.length}
					secondaryText={
						"Courses started but not finished during selected timeframe"
					}
					colorFamily={mixColorFamilies(
						portalColors.green,
						portalColors.gray,
						0.5,
					)}
					dataRefreshing={dataRefreshing}
				/>
				<DataHighlightBox
					primaryText={closedCourseEngagements.length}
					secondaryText={"Courses Completed During Selected Timeframe"}
					colorFamily={mixColorFamilies(
						portalColors.green,
						portalColors.gray,
						0.75,
					)}
					dataRefreshing={dataRefreshing}
				/>
			</div>
		</div>
	);
}
