import { useAtomValue } from "jotai";
import {
	EngagementFilter,
	engagementFiltersAtom,
} from "../../../../../data/learningSystem/engagement/filters";
import { useEffect, useMemo } from "react";
import { ColorFamily, PRIMARY_FONT, portalColors } from "../../../../../style";
import Tag from "./Tag";
import Task from "./Task";

type CurrentFiltersProps = {
	colorTheme: ColorFamily;
};

export default function CurrentFilters({ colorTheme }: CurrentFiltersProps) {
	const engagementFilters = useAtomValue(engagementFiltersAtom);

	const [
		additiveTagFilters,
		additiveTaskFilters,
		substactiveTagFilters,
		substactiveTaskFilters,
	] = useMemo(
		() => [
			engagementFilters.filter(
				(filter) => filter.behavior === "inclusion" && filter.entity === "tag",
			),
			engagementFilters.filter(
				(filter) => filter.behavior === "inclusion" && filter.entity === "task",
			),
			engagementFilters.filter(
				(filter) => filter.behavior === "exclusion" && filter.entity === "tag",
			),
			engagementFilters.filter(
				(filter) => filter.behavior === "exclusion" && filter.entity === "task",
			),
		],
		[engagementFilters],
	);

	const renderFilters = (
		behavior: EngagementFilter["behavior"],
		tagFilters: EngagementFilter[],
		taskFilters: EngagementFilter[],
	) =>
		!tagFilters.length && !taskFilters.length ? null : (
			<div
				id={`${behavior}-filters`}
				css={{ borderRadius: 2, overflow: "hidden", marginBottom: 20 }}
			>
				<div
					css={{
						backgroundColor: portalColors.gray.light,
						padding: 10,
						color: portalColors.gray.dark,
						// textTransform: "none",
					}}
				>
					{behavior === "inclusion"
						? "Inclusion Criteria"
						: "Exclusion Criteria"}
					<p
						css={{
							margin: 0,
							fontWeight: 500,
							textTransform: "capitalize",
							fontSize: 11,
							marginRight: 150,
						}}
					>
						{behavior === "inclusion"
							? "Records will be included if they meet any of the following conditions"
							: "Records will be exclude unless they meet all the following conditions"}
					</p>
				</div>

				<div
					css={{
						display: "grid",
						gap: 5,
						marginTop: 5,
					}}
				>
					<div css={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
						{tagFilters.map((filter) => (
							<Tag key={Math.random()} filter={filter} />
						))}
					</div>
					{
						<div css={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
							{taskFilters.map((filter) => (
								<Task key={Math.random()} filter={filter} />
							))}
						</div>
					}
				</div>
			</div>
		);

	return (
		<div
			css={{
				fontFamily: PRIMARY_FONT,
				fontSize: 11,
				textTransform: "uppercase",
				fontWeight: 700,
				color: portalColors.gray.dark,
			}}
		>
			{renderFilters("inclusion", additiveTagFilters, additiveTaskFilters)}
			{renderFilters(
				"exclusion",
				substactiveTagFilters,
				substactiveTaskFilters,
			)}
		</div>
	);
}
