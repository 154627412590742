import { useAtomValue } from "jotai";

import GlobalNav from "../GlobalNav/Desktop";
import ContentArea from "./ContentArea";
import GlobalNavMobile from "../GlobalNav/Mobile";

import { useEffect } from "react";
import NoAssignedRoles from "./NoAssignedRoles";
import { currentScreenDimensionsAtom } from "../../data/componentDimensions";
import { MEDIA_BREAKPOINTS } from "../../style/breakpoints";
import { useSpring, animated } from "@react-spring/web";
import { globalNavOpenAtom } from "../../data/navigation";
import { readyForNavigatorInteractionAtom } from "../../data/general";
import { userOrganizationsAtom } from "../../data/organization";

export default function ClientNavigator() {
	const screenDimensions = useAtomValue(currentScreenDimensionsAtom);
	const availableOrganizations = useAtomValue(userOrganizationsAtom);
	const readyForNavigator = useAtomValue(readyForNavigatorInteractionAtom);

	const globalNavOpen = useAtomValue(globalNavOpenAtom);

	const [componentAnim, componentAnimAPI] = useSpring(() => ({
		from: { opacity: 0 },
		config: { friction: 30, tension: 300, mass: 2 },
	}));

	useEffect(() => {
		readyForNavigator
			? componentAnimAPI.start({ opacity: 1, delay: 1000 })
			: componentAnimAPI.start({ opacity: 0 });
	}, [readyForNavigator, componentAnimAPI]);

	return (
		<animated.div
			id="form-films-navigator"
			style={componentAnim}
			css={{
				pointerEvents: readyForNavigator && !globalNavOpen ? "all" : "none",
				width: "100vw",
				height: ["-webkit-fill-available", "100vh"],
				display: "flex",
				boxSizing: "border-box",
			}}
		>
			{!readyForNavigator ? null : readyForNavigator &&
			  !availableOrganizations.length ? (
				<NoAssignedRoles />
			) : (
				<>
					{screenDimensions[0] < MEDIA_BREAKPOINTS[1] ? (
						<GlobalNavMobile />
					) : (
						<GlobalNav />
					)}
					<ContentArea />
				</>
			)}
		</animated.div>
	);
}
