import { useEffect, useState } from "react";
import { ColorFamily, PRIMARY_FONT } from "../../../style";

import ExpandIcon from "../../../assets/svg/icons/expandIcon.svg?react";
import { convertFromNamingConvention } from "../../../utils/text/namingConvention";
import { SerializedStyles, css } from "@emotion/react";

type DropdownProps<ValueType extends string | boolean> = {
	title: string;
	options: Array<ValueType>;
	onChange: (newValue: ValueType) => void;
	value: ValueType | null;
	colorTheme: ColorFamily;
	containerCSS?: SerializedStyles;
	initiallyOpen?: boolean;
};

function Dropdown<ValueType extends string | boolean>({
	title,
	options,
	value,
	onChange,
	colorTheme,
	containerCSS = css(),
	initiallyOpen = false,
}: DropdownProps<ValueType>) {
	const [isOpen, setIsOpen] = useState(false);
	const toggleOpen = () => setIsOpen(!isOpen);

	useEffect(() => {
		initiallyOpen && !value && setIsOpen(true);
	}, [initiallyOpen]);

	const optionCSS = css({
		cursor: "pointer",
		transition: "all .33s ease",
		borderRadius: 5,
		fontFamily: PRIMARY_FONT,
		fontSize: 11,
		fontWeight: 600,
		textTransform: "uppercase",
		padding: 10,
		color: colorTheme.mid,
		backgroundColor: "white",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		":hover": {
			color: "white",
			backgroundColor: colorTheme.mid,
		},
	});

	return (
		<div
			css={[
				{ borderRadius: 2, overflow: "hidden", cursor: "pointer" },
				containerCSS,
			]}
		>
			<div
				css={{
					transition: "all .33s ease",
					fontFamily: PRIMARY_FONT,
					fontSize: 11,
					fontWeight: 600,
					textTransform: "uppercase",
					padding: 10,
					color: isOpen ? "white" : colorTheme.mid,
					backgroundColor: isOpen ? colorTheme.mid : colorTheme.light,
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
				onClick={toggleOpen}
			>
				{value ? convertFromNamingConvention(value) : title}
				<ExpandIcon
					fill={isOpen ? "white" : colorTheme.mid}
					width={20}
					height={20}
					css={{
						transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
						transition: "all .33s ease",
					}}
				/>
			</div>
			{isOpen && (
				<div
					css={{
						backgroundColor: colorTheme.light,
						display: "flex",
						flexWrap: "wrap",
						gap: 5,
						padding: 10,
					}}
				>
					{options.map((option) => (
						<div
							role="button"
							css={optionCSS}
							onClick={() => {
								onChange(option);
								setIsOpen(false);
							}}
						>
							{convertFromNamingConvention(option)}
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default Dropdown;
