export function convertToNamingConvention(value: string | boolean) {
	return typeof value === "boolean"
		? value.toString()
		: value.toLowerCase().replaceAll(" ", "_");
}

export function convertFromNamingConvention(value: string | boolean) {
	return typeof value === "boolean"
		? value.toString()
		: value.replaceAll("_", " ");
}
