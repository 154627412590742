import useDimensions from "react-cool-dimensions";

/**
 * This hook returns observers that can watch/report the height of
 * a chart and various subcomponents.
 * */
export default function useObserveChartComponents() {
  const {
    observe: observeComponent,
    width: componentWidth,
    height: componentHeight,
  } = useDimensions({
    useBorderBoxSize: true,
  });

  const { observe: observeTitle, height: titleHeight } = useDimensions({
    useBorderBoxSize: true,
  });

  const { observe: observeLegend, height: legendHeight } = useDimensions({
    useBorderBoxSize: true,
  });

  return {
    observeComponent,
    componentWidth,
    componentHeight,
    observeTitle,
    observeLegend,
    titleHeight,
    legendHeight,
  };
}
