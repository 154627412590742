import { useAtomValue } from "jotai";

// Components
import GeneralPerformance from "./GeneralPerformance";
import DateRangeSelector from "../widgets/DateRangeSelector";
import CourseLevelDetail from "./CourseLevelDetail";

// Data
import {
	currentNavigatorFeatureAtom,
	selectedOptionForEachFeatureAtom,
} from "../../data/feature";

// Definitions
import { mediaQueries } from "../../style";
import CourseSelector from "./widgets/CourseSelector";
import EngagementFilters from "./widgets/EngagementFilters";
import { FeatureProps } from "../ClientNavigator/types";
import { useAvailableWidth } from "../../data/hooks/useAvailableWidth";

export default function LearningSystem({ contentAreaHeight }: FeatureProps) {
	const availableWidth = useAvailableWidth(60);

	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);
	const currentOptionForEachFeature = useAtomValue(
		selectedOptionForEachFeatureAtom,
	)["Learning System"];

	const renderCurrentOption = () => {
		switch (currentOptionForEachFeature) {
			case "All Courses Summary":
				return <GeneralPerformance availableHeight={contentAreaHeight} />;

			case "Course Level Detail":
				return <CourseLevelDetail availableHeight={contentAreaHeight} />;

			default:
				return null;
		}
	};

	return currentFeature !== "Learning System" ? null : (
		<div
			css={{
				width: availableWidth,
				overflow: "hidden",
			}}
		>
			<div
				css={{
					width: availableWidth,
					display: "grid",
					marginBottom: 15,
					rowGap: 10,
					columnGap: 15,
					gridTemplateRows: "auto",
					gridTemplateColumns: "max-content",
					[mediaQueries[3]]: {
						gridTemplateRows: "auto",
						gridTemplateColumns: "max-content 1fr 1fr",
					},
				}}
			>
				<DateRangeSelector />
				{currentOptionForEachFeature === "Course Level Detail" && (
					<>
						<CourseSelector />
						<EngagementFilters />
					</>
				)}
			</div>
			{renderCurrentOption()}
		</div>
	);
}
