import { Getter, Setter, atom } from "jotai";

import { AtlasEngineDatabase } from "@form-films/atlas-engine-shared-definitions";

import { convertToObservationsWithStringLabels } from "../../utils/observation";
import { OBSERVATION_WITH_STRING_LABEL } from "../../types/charts";
import supabaseClientAtom from "../supabaseClient";
import { gatherRecords } from "../utils/utilities";

type LearningModuleObservation = OBSERVATION_WITH_STRING_LABEL<
	Omit<
		AtlasEngineDatabase["public"]["Tables"]["learning_system_module"]["Row"],
		"id"
	>
>;

/**
 * Records on the learning modules that belong to courses
 * which are associated with the organization(s) belonging
 * to the user.
 * */
export const LS_ModulesAtom = atom<Array<LearningModuleObservation>>([]);

/** Update the available courses from the database. */
export async function updateLearningSystemModules(get: Getter, set: Setter) {
	const supabaseClient = get(supabaseClientAtom);
	if (!supabaseClient) return [];

	const modules = await gatherRecords(
		supabaseClient.from("learning_system_module").select("*"),
	);

	set(LS_ModulesAtom, convertToObservationsWithStringLabels(modules, "id"));
}
