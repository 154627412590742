import { atom } from "jotai";

import { AS_eventsObservationsFilteredByEnclosingURLsAtom } from ".";
import { GeolocationEvent } from "./types";

/**
 * All "record_geolocation_data" event observations for the current project.
 * Filtered by the current selected enclosing URLs.
 * */
export const AS_geolocationEventsAtom = atom((get) => {
	const allEvents = get(AS_eventsObservationsFilteredByEnclosingURLsAtom);

	return allEvents.filter(
		(event) => event.data.event_name === "record_geolocation_data",
	) as GeolocationEvent[];
});
