import { useMemo } from "react";
import { scaleBand } from "@visx/scale";
import { DateTime } from "luxon";

/**
 * Return a band scale for the given domain and range.
 *
 * A band scale maps a string or DateTime to a numeric range.
 * */
export default function useBandScale<T extends string | DateTime>({
	domain,
	range,
}: {
	domain: T[];
	range: [number, number];
}) {
	return useMemo(() => {
		const scale = scaleBand({
			domain,
			range,
			round: true,
			padding: 0.1,
			paddingOuter: 0.1,
		});

		return scale;
	}, [domain, range]);
}
