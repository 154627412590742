import { DateTime } from "luxon";

import { ObjectWithStringKeys } from "@form-films/atlas-engine-shared-definitions";

// Data (Datum Collection)
export type DATE_BASED_NUMBERIC_DATA = { [date: string]: number };
export type STRING_KEYED_NUMERIC_DATA = Array<STRING_NUMBER_TUPLE>;
export type STRING_KEYED_NUMERIC_DATA_SERIES = Array<
	[string, STRING_KEYED_NUMERIC_DATA]
>;

// Datum
export type STRING_NUMBER_TUPLE = [string, number];
export type NUMERIC_ARRAY = Array<number>;
export type SERIES_DATUM = [string, {}];

/**
 * All data related to Atlas experiences should be converted
 * to an Observation at the earliest possible moment to
 * ensure data consistency and interoperability throughout
 * the application.
 * */
export type OBSERVATION_GENERIC<
	LabelType extends DateTime | string = DateTime | string,
	// biome-ignore lint/complexity/noBannedTypes: <explanation>
	ObservationData extends ObjectWithStringKeys = {},
> = {
	observationLabel: LabelType;
	data: ObservationData;
};

export type OBSERVATION_WITH_DATE_LABEL<
	ObservationData extends ObjectWithStringKeys,
> = OBSERVATION_GENERIC<DateTime, ObservationData>;

export type OBSERVATION_WITH_STRING_LABEL<
	ObservationData extends ObjectWithStringKeys,
> = OBSERVATION_GENERIC<string, ObservationData>;

export type OBSERVATION_WITH_SINGLE_NUMERIC_VALUE =
	| OBSERVATION_WITH_DATE_LABEL<{ value: number }>
	| OBSERVATION_WITH_STRING_LABEL<{ value: number }>;

/** Observation that only allows numeric values. */
export type OBSERVATION_WITH_NUMERIC_VALUES =
	| OBSERVATION_WITH_DATE_LABEL<{ [key: string]: number }>
	| OBSERVATION_WITH_STRING_LABEL<{ [key: string]: number }>;

/** Pie Chart Specific Data Type */
export type PIE_CHART_OBSERVATION = OBSERVATION_GENERIC<
	string,
	{
		count: number;
		percentage: number;
	}
>;

/** Data shape for treemap charts.
 * MAYBE: All hierarchical charts.
 */
export type TreemapData = Array<HIERARCHY_OBSERVATION>;
export type HIERARCHY_OBSERVATION = OBSERVATION_WITH_STRING_LABEL<{
	parent: string | null;
	count?: number;
}>;

////////////////////////////
///// HELPER FUNCTIONS /////
////////////////////////////

/** Determine if an array of observations are all DateTime labeled. */
export function areObservationsDateTimeLabeled<
	ObservationData extends ObjectWithStringKeys,
>(
	observations: Array<OBSERVATION_GENERIC>,
): observations is Array<OBSERVATION_WITH_DATE_LABEL<ObservationData>> {
	return observations.every(
		(observation) => observation.observationLabel instanceof DateTime,
	);
}

/** Determine if an array of observations are all string labeled. */
export function areObservationsStringLabeled<
	ObservationData extends ObjectWithStringKeys,
>(
	observations: Array<OBSERVATION_GENERIC>,
): observations is Array<OBSERVATION_WITH_STRING_LABEL<ObservationData>> {
	return observations.every(
		(observation) => typeof observation.observationLabel === "string",
	);
}
