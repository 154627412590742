import { Property } from "csstype";
import { useEffect, useMemo } from "react";

import { useAtomValue } from "jotai";

import TreemapChart from "../../charts/Treemap";

import {
	finalNodeEntryEventsAtom,
	refreshAnalyticsSystemDataAtom,
} from "../../../data/analytics";
import { HIERARCHY_OBSERVATION } from "../../../types/charts";

export type FinalNodeDistributionProps = {
	colors: [Property.Color, Property.Color];
};

export default function FinalNodeDistribution({
	colors,
}: FinalNodeDistributionProps) {
	const finalNodeEntryEvents = useAtomValue(finalNodeEntryEventsAtom);
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);

	const preparedData = useMemo<HIERARCHY_OBSERVATION[]>(() => {
		const textTransformAssociatedNode = finalNodeEntryEvents.map((event) => ({
			...event,
			associated_node_name: event.data.associated_node_name?.replaceAll(
				/[_-]/g,
				" ",
			),
		}));

		const dataGroupedByExitNode = textTransformAssociatedNode.reduce<
			HIERARCHY_OBSERVATION[]
		>((previous, current) => {
			// Disregard any node events without an associated_node_name property.
			if (!current.data.associated_node_name) return [...previous];

			/** Determine if the current node already has a record. */
			const existingRecord = previous.find(
				(record) =>
					record.observationLabel === current.data.associated_node_name,
			);

			// Get a version of previous that filters out any existing record.
			const newReduction = [
				...previous.filter(
					(entry) =>
						entry.observationLabel !== current.data.associated_node_name,
				),
			];

			// Add a new/augmented entry for the current record.
			newReduction.push(
				existingRecord
					? {
							...existingRecord,
							data: {
								...existingRecord.data,
								count: (existingRecord.data.count ?? 0) + 1,
							},
					  }
					: {
							observationLabel: current.data.associated_node_name,
							data: {
								parent: "root",
								count: 1,
							},
					  },
			);

			return newReduction;
		}, []);

		// Sort the data
		dataGroupedByExitNode
			.sort(
				(previous, current) =>
					(current.data.count ?? 0) - (previous.data.count ?? 0),
			)
			.splice(10);

		// // CONDITIONALLY DO THIS BASED ON USESTATE
		// // Separate the top ten records from the rest.
		// const totalCountOutsideOfTopTen = dataGroupedByExitNode
		//   .splice(10)
		//   .reduce((previous, next) => previous + next.count, 0);

		// dataGroupedByExitNode.push({
		//   id: "Other",
		//   parent: "root",
		//   count: totalCountOutsideOfTopTen,
		// });

		// // CONDITIONALLY DO THIS BASED ON USESTATE

		const augmentedRootNode = [
			{ observationLabel: "root", data: { parent: null } },
			...dataGroupedByExitNode,
		];

		return augmentedRootNode;
	}, [finalNodeEntryEvents]);

	return (
		<TreemapChart
			key={[...preparedData.values()]
				.map((value) => ("count" in value ? value.count : 0))
				.toString()}
			dataRefreshing={dataRefreshing}
			dataColors={colors}
			observations={preparedData}
			depthToChart={1}
			chartTitle={{
				callOut: "Exit Node Breakdown",
				text: "Last Node Viewed by Users Prior to Exit",
			}}
		/>
	);
}
