import { css } from "@emotion/react";
import { useAtom, useAtomValue } from "jotai";
import { useMemo, useState } from "react";
import useDimensions from "react-cool-dimensions";

// Assets
import FilterIcon from "../../../../assets/svg/icons/filter.svg?react";

// Components
import SquareEdgeButton from "../../../widgets/SquareEdgeButton";

// Data
import {
	currentNavigatorFeatureAtom,
	navigatorFeatures,
} from "../../../../data/feature";
import { allTagsForSelectedEngagementsAtom } from "../../../../data/learningSystem/engagement/tags";

// Definitions
import {
	PRIMARY_FONT,
	darkGray,
	mediaQueries,
	midGray,
	portalColors,
} from "../../../../style";
import { currentScreenDimensionsAtom } from "../../../../data/componentDimensions";
import { engagementFiltersAtom } from "../../../../data/learningSystem/engagement/filters";
import NewFilter from "./NewFilter";
import CurrentFilters from "./CurrentFilters";

/**
 * This component provides a pop-up menu that allows the
 * user to select which courses they are interested
 * in viewing data for.
 */
export default function EngagementFilters() {
	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);
	const featureNavDimensions = useAtomValue(currentScreenDimensionsAtom);

	const [engagementFilters, setEngagmentFilters] = useAtom(
		engagementFiltersAtom,
	);

	const [widgetActive, setWidgetActive] = useState(false);

	const displayValue = useMemo(
		() =>
			engagementFilters.length > 1
				? "Multiple Filters"
				: engagementFilters.length === 1
				? "Single Filter"
				: "No Filters",
		[engagementFilters],
	);

	const buttonCSS = css({
		border: "none",
		outline: "none",
		backgroundColor: "transparent",
		fontFamily: PRIMARY_FONT,
		color: darkGray,
		fontWeight: 600,
	});

	const { observe, height: widgetHeight, entry } = useDimensions();

	return (
		<div
			id="engagement-filters"
			css={{
				zIndex: 75,
				position: "relative",
				height: "100%",
			}}
		>
			{/* rome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
			<div
				id="persistent-ui"
				ref={observe}
				onClick={() => setWidgetActive((currentValue) => !currentValue)}
				css={{
					height: "100%",
					cursor: "pointer",
					display: "flex",
					alignItems: "center",

					boxSizing: "border-box",
				}}
			>
				<div
					css={{
						display: "flex",
						alignItems: "center",
						backgroundColor: !widgetActive
							? darkGray
							: navigatorFeatures[currentFeature].colors.mid,
						transition: "background-color .33s ease",
						padding: 5,
						paddingLeft: 10,
						paddingRight: 10,
						height: "100%",
						borderStyle: "solid",
						borderColor: widgetActive
							? navigatorFeatures[currentFeature].colors.mid
							: midGray,
						borderWidth: 1,
						borderRightWidth: 0,
						borderTopLeftRadius: 5,
						borderBottomLeftRadius: 5,
					}}
				>
					<FilterIcon css={{ fill: "white" }} />
					<span
						css={[
							buttonCSS,
							{
								fontSize: 11,
								color: "white",
								fontWeight: 600,
								marginLeft: 5,
								textTransform: "uppercase",
							},
						]}
					>
						Engagement Filters
					</span>
				</div>
				<span
					css={{
						flex: 1,
						padding: 10,
						fontSize: 11,
						fontFamily: PRIMARY_FONT,
						fontWeight: 600,
						textTransform: "uppercase",
						fontStyle: "italic",
						color: portalColors.gray.dark,
						borderStyle: "solid",
						borderColor: widgetActive
							? navigatorFeatures[currentFeature].colors.mid
							: midGray,
						borderWidth: 1,
						borderLeftWidth: 0,
						borderTopRightRadius: 5,
						borderBottomRightRadius: 5,
					}}
				>
					{displayValue}
				</span>
			</div>
			{widgetActive && (
				<div
					id="expandable-panel"
					css={{
						position: "absolute",
						top: widgetHeight - 5,
						backgroundColor: "white",
						borderStyle: "solid",
						borderColor: midGray,
						borderWidth: 1,
						borderTopWidth: 5,
						borderTopColor: navigatorFeatures[currentFeature].colors.dark,
						padding: 10,
						width: featureNavDimensions[0] - 60,
						maxWidth: 500,
						boxSizing: "border-box",

						[mediaQueries[2]]: {
							width:
								featureNavDimensions[0] -
									45 -
									// @ts-expect-error
									entry?.target?.getBoundingClientRect().left ?? 0,
						},
						[mediaQueries[3]]: {
							maxWidth: "unset",
						},
					}}
				>
					<NewFilter colorTheme={navigatorFeatures[currentFeature].colors} />
					<CurrentFilters
						colorTheme={navigatorFeatures[currentFeature].colors}
					/>

					<div
						css={{
							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							gridTemplateRows: "1fr",
							gridAutoFlow: "column",
							gap: 3,
							marginTop: 20,
							marginBottom: 5,
						}}
					>
						<SquareEdgeButton
							label="Reset Filters"
							onPress={() => setEngagmentFilters([])}
						/>
						<SquareEdgeButton
							label="Close"
							onPress={() => {
								setWidgetActive(false);
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
}
