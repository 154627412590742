// Data && Definitions
import { SerializedStyles, css } from "@emotion/react";
import {
	ColorFamily,
	PRIMARY_FONT,
	mixColorFamilies,
	portalColors,
} from "../../../../../style";

type TaskProps = {
	userResponse: {
		question: string;
		response: any;
	};
	colorTheme: ColorFamily;
};

function StringData({
	text,
	colorTheme,
	additionalCSS = css(),
}: {
	text: string;
	colorTheme: ColorFamily;
	additionalCSS?: SerializedStyles;
}) {
	return (
		<p
			css={[
				{
					margin: 0,
					fontSize: 12,
					fontWeight: 600,
					color: colorTheme.dark,
				},
				additionalCSS,
			]}
		>
			{text}
		</p>
	);
}

function ArrayData({
	data,
	colorTheme,
}: { data: Array<string>; colorTheme: ColorFamily }) {
	return (
		<div css={{ display: "flex", flexWrap: "wrap", gap: 5, marginTop: 5 }}>
			{data.map((datum) => (
				<div css={{ backgroundColor: "white", padding: 7, borderRadius: 5 }}>
					<StringData
						text={datum}
						colorTheme={colorTheme}
						additionalCSS={css({ fontSize: 11, fontWeight: 600 })}
					/>
				</div>
			))}
		</div>
	);
}

export default function UserResponse({ userResponse, colorTheme }: TaskProps) {
	return (
		<div
			css={{
				cursor: "default",
				margin: 0,
				fontFamily: PRIMARY_FONT,
				position: "relative",
				display: "flex",
				flexDirection: "column",
				gap: 5,
				alignItems: "flex-start",
			}}
		>
			<div
				css={{
					fontSize: 11,
					backgroundColor: colorTheme.light,
					padding: 10,
					borderRadius: 5,
				}}
			>
				<span
					css={{
						color: colorTheme.mid,
						textTransform: "uppercase",
						fontWeight: 600,
						fontSize: 9,
						marginBottom: -1,
						display: "block",
					}}
				>
					{userResponse.question}
				</span>
				{Array.isArray(userResponse.response) ? (
					<ArrayData data={userResponse.response} colorTheme={colorTheme} />
				) : (
					<StringData text={userResponse.response} colorTheme={colorTheme} />
				)}
			</div>
		</div>
	);
}
