import { atom } from "jotai";
import { DateTime } from "luxon";
import { AtlasEngineAnalyticsEventPayloads } from "@form-films/atlas-engine-shared-definitions";

import { AS_eventsObservationsFilteredByEnclosingURLsAtom } from ".";
import {
	OBSERVATION_WITH_DATE_LABEL,
	HIERARCHY_OBSERVATION,
	PIE_CHART_OBSERVATION,
	TreemapData,
} from "../../../types/charts";
import { groupByDate } from "../../../utils/groupBy/date";
import { RecordEnvironmentEvent } from "./types";

/** All "record_environment" events for the current project. */
export const AS_environmentEventsAtom = atom((get) => {
	const allEvents = get(AS_eventsObservationsFilteredByEnclosingURLsAtom);
	const environmentEvents = allEvents.filter(
		(event) => event.data.event_name === "record_environment",
	) as Array<RecordEnvironmentEvent>;

	return environmentEvents;
});

/** Group Environment Events by Day */
export const environmentDataByDayAtom = atom((get) => {
	const environmentEvents = get(AS_environmentEventsAtom);
	const eventsByDate = groupByDate(environmentEvents, "event_timestamp");

	return eventsByDate;
});

/** Get number of mobile & desktop users by date. */
export const AS_mobileDesktopCountsByDateAtom = atom<
	OBSERVATION_WITH_DATE_LABEL<{ mobile: number; desktop: number }>[]
>((get) => {
	const eventsByDate = get(environmentDataByDayAtom);

	const summaryCounts = Object.entries(eventsByDate).map((eventsOnDate) => {
		const counts = {
			mobile: 0,
			desktop: 0,
		};

		for (const event of eventsOnDate[1]) {
			if (event.data.data.device.mobile === false) {
				counts.desktop = counts.desktop + 1;
			} else {
				counts.mobile = counts.mobile + 1;
			}
		}

		return {
			observationLabel: DateTime.fromISO(eventsOnDate[0]),
			data: counts,
		};
	});

	return summaryCounts;
});

/**
 * Comparison of mobile/desktop users.
 * - Raw counts & percentages.
 * */
export const AS_mobileDesktopSummaryAtom = atom<PIE_CHART_OBSERVATION[]>(
	(get) => {
		const eventRecords = get(AS_environmentEventsAtom);

		const counts = {
			mobile: 0,
			desktop: 0,
		};

		for (const event of eventRecords) {
			counts[event.data.data.device.mobile ? "mobile" : "desktop"] += 1;
		}

		return [
			{
				observationLabel: "mobile",
				data: {
					count: counts.mobile,
					percentage: counts.mobile / (counts.desktop + counts.mobile),
				},
			},
			{
				observationLabel: "desktop",
				data: {
					count: counts.desktop,
					percentage: counts.desktop / (counts.desktop + counts.mobile),
				},
			},
		];
	},
);

/** Get a breakdown of mobile devices. */
export const mobileDeviceBreakdownAtom = atom<TreemapData>((get) => {
	const topManufacturers = ["Apple", "Samsung", "Google"];

	const hierarchicalData = [
		{ observationLabel: "root", data: { parent: null } },
		{
			observationLabel: "Apple",
			data: { parent: "root" },
		},
		{
			observationLabel: "Samsung",
			data: { parent: "root" },
		},
		{
			observationLabel: "Other",
			data: { parent: "root" },
		},
		{
			observationLabel: "Google",
			data: { parent: "root" },
		},
	];

	const environmentEvents = get(AS_environmentEventsAtom);
	const mobileDeviceRecords = environmentEvents
		.map((event) => event.data.data.device)
		.filter((device) => device.mobile) as Array<{
		mobile: true;
		vendor: string | undefined;
		model: string | undefined;
	}>;

	const countByModel = mobileDeviceRecords.reduce(
		(
			previous: { [key: string]: { parent: string; count: number } },
			current,
		) => {
			return !current.model || current.model === "none"
				? { ...previous }
				: {
						...previous,
						[current.model]: {
							parent:
								current.vendor && topManufacturers.includes(current.vendor)
									? current.vendor
									: "Other",
							count: previous[current.model]
								? previous[current.model].count + 1
								: 1,
						},
				  };
		},
		{},
	);

	return [
		...hierarchicalData,
		...Object.entries(countByModel).map((entry) => ({
			observationLabel: entry[0],
			data: entry[1],
		})),
	];
});

/** Get a breakdown of browsers */
export const browserBreakdownAtom = atom<TreemapData>((get) => {
	const environmentEvents = get(AS_environmentEventsAtom);
	const browserData = environmentEvents.map((event) => event.data.data.browser);

	const countByBrowser = browserData.reduce(
		(previous: { [key: string]: HIERARCHY_OBSERVATION }, currentBrowser) => {
			const browserData: { name: string; version: string } = currentBrowser;
			const existingCount = previous[browserData.name]?.data.count;

			return {
				...previous,
				[browserData.name]: {
					observationLabel: browserData.name,
					data: {
						parent: "root",
						count: existingCount ? existingCount + 1 : 1,
					},
				},
			};
		},
		{},
	);

	return [
		{ observationLabel: "root", data: { parent: null } },
		...Object.entries(countByBrowser).map((entry) => ({
			...entry[1],
		})),
	];
});

/** Get a breakdown of browsers */
export const osBreakdownAtom = atom<TreemapData>((get) => {
	const environmentEvents = get(AS_environmentEventsAtom);
	const operatingSystemData = environmentEvents.map(
		(event) => event.data.data.os,
	);

	const countByOperatingSystem = operatingSystemData.reduce(
		(previous: { [key: string]: HIERARCHY_OBSERVATION }, current) => {
			const browserData: { name: string; version: string } = current;
			const existingCount = previous[browserData.name]?.data.count;

			return {
				...previous,
				[browserData.name]: {
					observationLabel: browserData.name,
					data: {
						parent: "root",
						count: existingCount ? existingCount + 1 : 1,
					},
				},
			};
		},
		{},
	);

	return [
		{ observationLabel: "root", data: { parent: null } },
		...Object.entries(countByOperatingSystem).map((entry) => ({
			...entry[1],
		})),
	];
});
