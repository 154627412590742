import { css } from "@emotion/react";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

// Assets
import GraduationCap from "../../../assets/svg/icons/graduationCap.svg?react";

// Components
import SquareEdgeButton from "../../widgets/SquareEdgeButton";
import MultipleChoiceOption from "../../widgets/MultipleChoiceOption";

// Data
import {
	LS_OrganizationalCoursesAtom,
	LS_SelectedCoursesAtom,
} from "../../../data/learningSystem/courses";
import {
	currentNavigatorFeatureAtom,
	navigatorFeatures,
} from "../../../data/feature";

// Definitions
import { PRIMARY_FONT, darkGray, midGray, portalColors } from "../../../style";
import useDimensions from "react-cool-dimensions";

/**
 * This component provides a pop-up menu that allows the
 * user to select which courses they are interested
 * in viewing data for.
 */
export default function CourseSelector() {
	const organizationCourses = useAtomValue(LS_OrganizationalCoursesAtom);
	const [selectedCourses, setSelectedCourses] = useAtom(LS_SelectedCoursesAtom);
	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);

	// Store pending selections updates.
	// We execute updates in batches to cut down on data reloading.
	const [mutableSelectedCourses, setMutableSelectedCourses] =
		useState(selectedCourses);

	const [widgetActive, setWidgetActive] = useState(false);

	// Whenever Jotai storage changes, sync the local data.
	useEffect(() => {
		setMutableSelectedCourses(selectedCourses);
	}, [selectedCourses]);

	/** Select all courses for a potential update. */
	const selectAllCourses = () => {
		setMutableSelectedCourses(organizationCourses);
	};

	/** Deselect all courses for a potential update. */
	const deselectAllCourses = () => {
		setMutableSelectedCourses([]);
	};

	const displayValue = useMemo(() => {
		if (selectedCourses.length === organizationCourses.length) {
			return "All Courses";
		}

		if (selectedCourses.length > 1) {
			return "Multiple Courses";
		}

		return selectedCourses[0].data.display_name;
	}, [selectedCourses, organizationCourses]);

	const buttonCSS = css({
		border: "none",
		outline: "none",
		backgroundColor: "transparent",
		fontFamily: PRIMARY_FONT,
		color: darkGray,
		fontWeight: 600,
	});

	const { observe, height: widgetHeight, entry } = useDimensions();

	return (
		<div
			id="learning-system-course-selector"
			css={{
				zIndex: 100,
				position: "relative",
			}}
		>
			<div
				id="persistent-ui"
				ref={observe}
				onClick={() => setWidgetActive((currentValue) => !currentValue)}
				css={{
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
					height: "100%",
				}}
			>
				<div
					css={{
						display: "flex",
						alignItems: "center",
						height: "100%",
						backgroundColor: !widgetActive
							? darkGray
							: navigatorFeatures[currentFeature].colors.mid,
						transition: "background-color .33s ease",
						padding: 5,
						paddingLeft: 10,
						paddingRight: 10,
						borderStyle: "solid",
						borderColor: widgetActive
							? navigatorFeatures[currentFeature].colors.mid
							: midGray,
						borderWidth: 1,
						borderRightWidth: 0,
						borderTopLeftRadius: 5,
						borderBottomLeftRadius: 5,
					}}
				>
					<GraduationCap css={{ fill: "white" }} />
					<span
						css={[
							buttonCSS,
							{
								fontSize: 11,
								color: "white",
								fontWeight: 600,
								marginLeft: 5,
								textTransform: "uppercase",
							},
						]}
					>
						Course Selection
					</span>
				</div>
				<span
					css={{
						height: "100%",
						flex: 1,
						display: "flex",
						alignItems: "center",
						padding: 10,
						fontSize: 11,
						fontFamily: PRIMARY_FONT,
						fontWeight: 600,
						textTransform: "uppercase",
						fontStyle: "italic",
						color: portalColors.gray.dark,
						borderStyle: "solid",
						borderColor: widgetActive
							? navigatorFeatures[currentFeature].colors.mid
							: midGray,
						borderWidth: 1,
						borderTopRightRadius: 5,
						borderBottomRightRadius: 5,
					}}
				>
					{displayValue}
				</span>
			</div>
			{widgetActive && (
				<div
					id="expandable-panel"
					css={{
						position: "absolute",
						top: widgetHeight - 5,
						backgroundColor: "white",
						borderStyle: "solid",
						borderColor: midGray,
						borderWidth: 1,
						borderTopWidth: 5,
						borderTopColor: navigatorFeatures[currentFeature].colors.dark,
						padding: 10,
						width: "100%",
						boxSizing: "border-box",
					}}
				>
					{organizationCourses.map((course) => (
						<div css={{ marginBottom: 15 }} key={course.observationLabel}>
							<div
								css={{
									display: "grid",
									gridTemplateColumns: "1fr",
									rowGap: 5,
								}}
							>
								<MultipleChoiceOption
									text={course.data.display_name}
									selected={
										mutableSelectedCourses.find(
											(mutableCourse) =>
												mutableCourse.observationLabel ===
												course.observationLabel,
										) !== undefined
									}
									onClick={() => {
										mutableSelectedCourses.find(
											(mutableCourse) =>
												mutableCourse.observationLabel ===
												course.observationLabel,
										)
											? setMutableSelectedCourses(
													mutableSelectedCourses.filter(
														(mutableCourse) =>
															mutableCourse.observationLabel !==
															course.observationLabel,
													),
											  )
											: setMutableSelectedCourses([
													...mutableSelectedCourses,
													course,
											  ]);
									}}
								/>
							</div>
						</div>
					))}
					<div
						css={{
							display: "grid",
							gridTemplateColumns: "1fr 1fr 1fr",
							gridTemplateRows: "1fr 1fr",
							gridAutoFlow: "column",
							gap: 3,
							marginBottom: 5,
						}}
					>
						<SquareEdgeButton
							label="Select All"
							onPress={selectAllCourses}
							additionalStyles={{ padding: undefined, gridColumn: "span 2" }}
						/>
						<SquareEdgeButton
							label="Clear All"
							onPress={deselectAllCourses}
							additionalStyles={{ padding: undefined, gridColumn: "span 2" }}
						/>
						<SquareEdgeButton
							label="Update"
							disabled={!mutableSelectedCourses.length}
							additionalStyles={{
								width: "100%",
								gridRow: "span 2",
							}}
							onPress={() => {
								setWidgetActive(false);
								setSelectedCourses(mutableSelectedCourses);
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
}
