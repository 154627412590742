import { atom } from "jotai";

import { LS_SelectedCoursesEngagementsAtom } from "./selectedCourses";

/** All task names found in engagements for the currently selected courses. */
export const allTaskNamesForSelectedEngagementsAtom = atom((get) => {
	const engagementsForSelectedCourses = get(LS_SelectedCoursesEngagementsAtom);
	return engagementsForSelectedCourses.reduce<Array<string>>(
		(previous, next) => {
			const currentEngagementTasks = next.data.metadata.tasks ?? [];

			const updateListOfTaskNames = [
				...new Set([
					...currentEngagementTasks.map((task) => task.name),
					...previous,
				]),
			].sort((a, b) => a.localeCompare(b));

			return updateListOfTaskNames;
		},
		[],
	);
});
