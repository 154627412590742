import { useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import {
	NavigatorFeatureNames,
	currentNavigatorFeatureAtom,
	navigatorFeatures,
	selectedOptionForEachFeatureAtom,
} from "../../data/feature";
import { PRIMARY_FONT, darkGray, mediaQueries } from "../../style";
import { globalNavOpenAtom } from "../../data/navigation";

type FeatureMenuProps = {
	feature: NavigatorFeatureNames;
};

/** Displays the menu options for a given feature. */
export default function FeatureMenuOptions({ feature }: FeatureMenuProps) {
	const componentRef = useRef<HTMLDivElement>(null);

	const currentFeature = useAtomValue(currentNavigatorFeatureAtom);
	const [currentOptionForEachFeature, setCurrentOptionForEachFeature] = useAtom(
		selectedOptionForEachFeatureAtom,
	);
	const setGlobalNavOpen = useSetAtom(globalNavOpenAtom);

	const { height } = useSpring({
		from: { height: 0 },
		to: {
			height:
				feature === currentFeature
					? componentRef.current?.scrollHeight ?? 0
					: 0,
		},
	});

	return (
		<animated.div
			id={`${currentFeature}-menu-options`}
			ref={componentRef}
			style={{ height }}
			css={{
				overflow: "hidden",
				paddingTop: 15,
				boxSizing: "border-box",
				marginLeft: 25,
				marginRight: 35,
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				[mediaQueries[1]]: {
					gridTemplateColumns: "1fr",
					justifyItems: "start",
				},
			}}
		>
			{navigatorFeatures[feature].menu.map((option) => (
				<button
					type="button"
					key={`${option}-button`}
					onClick={() => {
						setCurrentOptionForEachFeature({
							...currentOptionForEachFeature,
							[feature]: option,
						});
						setTimeout(() => setGlobalNavOpen(false), 200);
					}}
					css={{
						fontFamily: PRIMARY_FONT,
						fontWeight: 600,
						fontSize: 12,
						padding: 10,
						marginBottom: 5,
						outline: "none",
						border: "none",
						transition: "all .25s ease",
						borderRadius: 2,
						...(currentOptionForEachFeature[feature] === option
							? {
									color: "white",
									backgroundColor: navigatorFeatures[feature].colors.mid,
							  }
							: {
									backgroundColor: "transparent",
									color: darkGray,
							  }),
					}}
				>
					{option}
				</button>
			))}
		</animated.div>
	);
}
