import { atom } from "jotai";
import { isEqual } from "lodash";

import { currentAtlasProjectAtom } from "../../project";
import { analyticsExperiencesAtom } from ".";
import { supabaseUserIsAdminAtom } from "../../user";

export type PageEmbed = {
	url: string;
	domain: string;
	extension: string;
	selected: boolean;
};

/**
 * A useful representation of all unique embedding URLs
 * found in the current set of experience records.
 * */
export const allEmbeddingURLsAtom = atom<Omit<PageEmbed, "selected">[]>(
	(get) => {
		const experiencesAtom = get(analyticsExperiencesAtom);
		const uniqueEmbedURLs = [
			...new Set(
				experiencesAtom.map((experience) =>
					experience.data.enclosing_url
						?.replace(/^(http:\/\/|https:\/\/)/, "")
						?.replace(/\/$/, ""),
				),
			),
		];

		// Generate an array of objects containing the URL, domain, and extension
		// of each unique enclosing URL, sorted alphabetically by URL
		const urlArray = uniqueEmbedURLs
			.map((url) => {
				const urlParts = url?.match(/^(?:https?:\/\/)?([^/\n?]+)([/?]*.+)?/);

				return {
					url: url ?? "unknown",
					domain: urlParts?.[1] ?? "unknown",
					extension: urlParts?.[2] ?? "/",
				};
			})
			.sort((a, b) => a.url.localeCompare(b.url));

		return urlArray;
	},
);

/**
 * This atom is responsible to determining which embedding URLs which can
 * be selected by the user.
 *
 * Non-admin users (clients) should only be presented with data from
 * domains that are official registered for their projects. Otherwise,
 * they will receive "noise" from testing, bots, etc that won't help
 * with data analysis.
 *
 * One exception to this is that if there are *NO* officially registered
 * domains for a project, then everything will be displayed.
 */
export const filteredEmbeddingURLsAtom = atom<Omit<PageEmbed, "selected">[]>(
	(get) => {
		const allEmbeddingURLS = get(allEmbeddingURLsAtom);
		const currentProject = get(currentAtlasProjectAtom);
		const isCurrentUserAdmin = get(supabaseUserIsAdminAtom);

		if (!currentProject) return [];

		return isCurrentUserAdmin || !currentProject.domains.length
			? allEmbeddingURLS
			: // If the user isn't an admin
				allEmbeddingURLS.filter((embeddingURL) =>
					// Only include URLs that belong to official
					// domains of the project.
					currentProject.domains.some((officialProjectDomain) =>
						embeddingURL.domain.includes(officialProjectDomain),
					),
				);
	},
);

const selectableEnclosingURLsBaseAtom = atom<PageEmbed[] | null>(null);

/**
 * Represents which enclosing URLs have been "selected" by
 * the user to specify which experience records should
 * be included in data results.
 * */
export const selectableEnclosingURLsAtom = atom<
	PageEmbed[],
	[PageEmbed[]],
	void
>(
	(get) => {
		const valuesFromDatabase = get(filteredEmbeddingURLsAtom).map(
			(databaseRecord) => ({ ...databaseRecord, selected: true }),
		);
		const updatedValues = get(selectableEnclosingURLsBaseAtom);

		return !updatedValues ||
			!isEqual(
				updatedValues.map((value) => value.domain),
				valuesFromDatabase.map((value) => value.domain),
			)
			? valuesFromDatabase
			: updatedValues;
	},
	(get, set, newValues) => {
		set(selectableEnclosingURLsBaseAtom, newValues);
	},
);
