import { useAtomValue } from "jotai";

// Components
import ContinuousBarChart from "../charts/Bar/ContinuousBarChart";
import DataHighlightBox from "../charts/DataHighlightBox";

// Data
import { refreshAnalyticsSystemDataAtom } from "../../data/analytics";
import {
	AS_AllCartsValue,
	AS_AverageCartValue,
	AS_TransferCartDailyPercentage,
	AS_TransferCartEvents,
	AS_TransferCartPercentage,
} from "../../data/commerce/transferCart";

// Definitions
import { mixColorFamilies, portalColors } from "../../style/colors";
import {
	AS_AddCartEventCountsByDate,
	AS_AddCartItemEvents,
	AS_AllAddedItemsValue,
	AS_AverageNumberOfAddedItems,
	AS_EngagedShopperAverageNumberOfAddedItems,
} from "../../data/commerce/addItem";
import LineChart from "../charts/Line";
import { AS_DailyCommerceFunnelPercentages } from "../../data/commerce";

export type GeneralPerformanceProps = {
	availableHeight: number;
};

export default function GeneralPerformance({
	availableHeight,
}: GeneralPerformanceProps) {
	const dataRefreshing = useAtomValue(refreshAnalyticsSystemDataAtom);
	const addCartItemEvents = useAtomValue(AS_AddCartItemEvents);
	const allAddItemsValue = useAtomValue(AS_AllAddedItemsValue);

	const engagedShopperAverageAddItemEvents = useAtomValue(
		AS_EngagedShopperAverageNumberOfAddedItems,
	);
	const averageAddItemEvents = useAtomValue(AS_AverageNumberOfAddedItems);

	const dailyFunnelPercentages = useAtomValue(
		AS_DailyCommerceFunnelPercentages,
	);

	const transferCartEvents = useAtomValue(AS_TransferCartEvents);
	const overallPercentage = useAtomValue(AS_TransferCartPercentage);
	const totalCartValue = useAtomValue(AS_AllCartsValue);
	const averageCartValue = useAtomValue(AS_AverageCartValue);

	return (
		<div>
			<div
				css={{
					display: "grid",
					gridTemplateColumns: "repeat(6, 1fr)",
					gridTemplateRows: "400px 1fr 1fr",
					gap: 10,
				}}
			>
				<LineChart
					seriesNames={["add_item_percentage", "transfer_cart_percentage"]}
					additionalCSS={{ gridColumn: "span 6" }}
					dataColors={[portalColors.blue.mid, portalColors.green.mid]}
					observations={dailyFunnelPercentages}
					dataRefreshing={dataRefreshing}
					axes={{ observationLabel: "bottom" }}
					colorTheme={mixColorFamilies(portalColors.tan, portalColors.gray, 1)}
					chartTitle={{
						callOut: "Funnel Performance",
						text: "Daily Sales Funnel Breakdown",
					}}
					dataFormatter={(data) => `${(Number(data) * 100).toFixed()}%`}
					tooltip={{ title: "Funnel Performance" }}
					chartExplanation={[
						`This chart shows daily ROI as measured by different 
					stages of the commerce experience.`,
						`You can use this chart to see how your users 
					persist through various stages of the funnel.`,
					]}
				/>
				<DataHighlightBox
					primaryText={averageAddItemEvents.data.value}
					postfix="%"
					secondaryText={"Percent of Users Added At Least 1 Item to Cart"}
					colorFamily={mixColorFamilies(
						portalColors.blue,
						portalColors.gray,
						0.3,
					)}
					dataRefreshing={dataRefreshing}
					additionalStyles={{ gridColumn: "span 2" }}
				/>
				<DataHighlightBox
					primaryText={addCartItemEvents.length}
					secondaryText={"Total Number of Items Added to Shopping Carts"}
					colorFamily={mixColorFamilies(
						portalColors.blue,
						portalColors.gray,
						0.7,
					)}
					dataRefreshing={dataRefreshing}
				/>
				<DataHighlightBox
					primaryText={`${(overallPercentage.data.value * 100).toFixed(1)}`}
					postfix="%"
					secondaryText={"Percent of Users Transferred Cart Data"}
					colorFamily={mixColorFamilies(
						portalColors.green,
						portalColors.gray,
						0.3,
					)}
					dataRefreshing={dataRefreshing}
					additionalStyles={{ gridColumn: "span 2" }}
				/>
				<DataHighlightBox
					primaryText={transferCartEvents.length}
					secondaryText={"Total Number of Carts Transferred to Checkout"}
					colorFamily={mixColorFamilies(
						portalColors.green,
						portalColors.gray,
						0.7,
					)}
					dataRefreshing={dataRefreshing}
				/>

				<DataHighlightBox
					primaryText={`${allAddItemsValue.data.value}`}
					prefix="$"
					secondaryText={"Total Value of all items added to shopping carts"}
					colorFamily={mixColorFamilies(
						portalColors.blue,
						portalColors.gray,
						0.33,
					)}
					dataRefreshing={dataRefreshing}
					additionalStyles={{ gridColumn: "span 2" }}
				/>

				<DataHighlightBox
					primaryText={`${totalCartValue.data.value}`}
					prefix="$"
					secondaryText={"Total Value of all carts transferred to checkout"}
					colorFamily={mixColorFamilies(
						portalColors.green,
						portalColors.gray,
						0,
					)}
					dataRefreshing={dataRefreshing}
					additionalStyles={{ gridColumn: "span 2" }}
				/>

				<DataHighlightBox
					primaryText={`${averageCartValue.data.value}`}
					prefix="$"
					secondaryText={"Average Value of Carts Transferred To Checkout"}
					colorFamily={mixColorFamilies(portalColors.tan, portalColors.gray, 0)}
					dataRefreshing={dataRefreshing}
					additionalStyles={{ gridColumn: "span 2" }}
				/>
			</div>
		</div>
	);
}
