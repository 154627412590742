import { useAtomValue } from "jotai";
import {
  PRIMARY_FONT,
  SECONDARY_FONT,
  darkGray,
  midGray,
  portalColors,
} from "../../style";
import {
  currentNavigatorFeatureAtom,
  navigatorFeatures,
} from "../../data/feature";

type MultipleChoiceOptionProps = {
  text: string;
  selected: boolean;
  onClick: () => void;
};

export default function MultipleChoiceOption({
  text,
  selected,
  onClick,
}: MultipleChoiceOptionProps) {
  const currentFeature = useAtomValue(currentNavigatorFeatureAtom);

  return (
    <div
      css={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={onClick}>
      <div
        css={{
          borderStyle: "solid",
          borderColor: selected
            ? navigatorFeatures[currentFeature].colors.dark
            : portalColors.gray.mid,
          borderWidth: 1,
          minHeight: 12,
          minWidth: 12,
          marginRight: 5,
          borderRadius: 2,
          position: "relative",
        }}>
        <div
          css={{
            backgroundColor: selected
              ? navigatorFeatures[currentFeature].colors.mid
              : "transparent",
            height: 10,
            width: 10,
            top: 1,
            left: 1,
            position: "relative",
          }}
        />
      </div>
      <span
        css={{
          fontFamily: SECONDARY_FONT,
          fontSize: 12,
          color: portalColors.gray.dark,
        }}>
        {text}
      </span>
    </div>
  );
}
