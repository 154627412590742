import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { authenticationStateAtom } from "../../data/general";
import {
	isCurrentUserAdmin,
	supabaseClientAtom,
	supabaseSessionAtom,
	supabaseUserAtom,
	supabaseUserIsAdminAtom,
} from "../../data/analytics";

export default function useAlternativeAuthenticationMethods() {
	const client = useAtomValue(supabaseClientAtom);
	const setUser = useSetAtom(supabaseUserAtom);
	const setUserAdmin = useSetAtom(supabaseUserIsAdminAtom);
	const setUserSession = useSetAtom(supabaseSessionAtom);
	const setAuthenticationState = useSetAtom(authenticationStateAtom);

	// TODO: Need error handling.

	useEffect(() => {
		/**
		 * If the user has a one-time password, this function
		 * will attempt to use it to login.
		 */
		const loginViaOTP = async (email: string, opt: string) => {
			const { data, error } = await client.auth.verifyOtp({
				email: email,
				token: opt,
				type: "recovery",
			});

			// If the verification was successful, we will have retrieve
			// a new session for the user. Need to update the URL to
			// eliminate the (now) expired OTP token in case
			// the user refreshes the page.
			if (data.user?.id) {
				window.history.replaceState(
					null,
					"",
					window.location.href.split("?")[0],
				);
				setAuthenticationState("resetPassword");
			}
		};

		/**
		 * If the user has completed authorization outside of the app (or is coming back after auth),
		 * this function will obtain their profile and session data.
		 * */
		const retrieveLoginCredentials = async () => {
			const {
				data: { user },
			} = await client.auth.getUser();
			const {
				data: { session },
				error,
			} = await client.auth.getSession();

			if (user && session) {
				setUser(user);
				setUserSession(session);

				// Determine if the user is an admin or not.
				const userIsAdmin = await isCurrentUserAdmin();
				setUserAdmin(userIsAdmin);

				setAuthenticationState("launchNavigator");
			}
		};

		const urlParams = new URLSearchParams(window.location.search);
		const oneTimePassword = urlParams.get("otp");
		const userEmail = urlParams.get("email");

		oneTimePassword && userEmail
			? loginViaOTP(userEmail, oneTimePassword)
			: retrieveLoginCredentials();
	}, []);
}
