import { atom } from "jotai";

import { AS_eventsObservationsFilteredByEnclosingURLsAtom } from ".";
import { startedExperiencesAtom } from "../experience";
import { GoalAchievementEvent } from "./types";
import { DateTime } from "luxon";
import { OBSERVATION_WITH_NUMERIC_VALUES } from "../../../types/charts";
import { allDatesInUserSelectionAtom } from "../../dateTime";
import {
	_makeDateBasedObservation,
	_makeObservation,
} from "../../../utils/observation";

/**
 * All "goal_achievement" events for the current project.
 * - Filtered by the current selected enclosing URLs.
 * */
export const AS_goalAchievementObservationsAtom = atom((get) => {
	const allEvents = get(AS_eventsObservationsFilteredByEnclosingURLsAtom);

	// All Goal Achievement Events
	const allGoalAchievementEvents = allEvents.filter(
		(event) => event.data.event_name === "goal_achievement",
	);

	// Filter out some historical events that do not qualify for inclusion.
	return allGoalAchievementEvents.filter(
		(event) =>
			event.data.data &&
			typeof event.data.data === "object" &&
			Object.hasOwn(event.data.data, "category"),
	) as GoalAchievementEvent[];
});

/**
 * What percentage of started experiences had at least one goal completion?
 * */
export const AS_percentOfExperiencesWithGoalCompletionsAtom = atom((get) => {
	const startedExperiences = get(startedExperiencesAtom);
	const goalAchievementEvents = get(AS_goalAchievementObservationsAtom);

	// Create a Set that holds the UIDs of experiences
	// that have at least one goal completion.
	const experiencesWithGoalAchievements = new Set();

	for (const event of goalAchievementEvents) {
		experiencesWithGoalAchievements.add(event.data.experience_id);
	}

	return experiencesWithGoalAchievements.size && startedExperiences.length
		? Math.round(
				(experiencesWithGoalAchievements.size / startedExperiences.length) *
					100,
		  )
		: 0;
});

/** Separate goal achievement events by category. */
export const AS_goalEventsByCategoryAtom = atom((get) => {
	const allAchievements = get(AS_goalAchievementObservationsAtom);
	return allAchievements.reduce<{ [key: string]: typeof allAchievements }>(
		(previous, current) => {
			return previous[current.data.data.category]
				? {
						...previous,
						[current.data.data.category]: [
							...previous[current.data.data.category],
							current,
						],
				  }
				: { ...previous, [current.data.data.category]: [current] };
		},
		{},
	);
});

/** Categorized goal events grouped by date */
export const AS_categoryGoalAchievementsByDateAtom = atom((get) => {
	const goalEventsByCategory = get(AS_goalEventsByCategoryAtom);
	const allSelectedDates = get(allDatesInUserSelectionAtom);

	return Object.entries(goalEventsByCategory).reduce<{
		[goalCategory: string]: OBSERVATION_WITH_NUMERIC_VALUES[];
	}>((previous, [currentCategoryName, currentCategoryEvents]) => {
		// Determine all the goals belonging to this category
		const categoryGoals = [
			...new Set(currentCategoryEvents.map((event) => event.data.data.name)),
		];

		// For each category, we need create an observation of each
		// date that counts the various goal events.
		const countOfGoalAchievementsByDate = allSelectedDates.map((date) => {
			const categoryEventsForDate = currentCategoryEvents.filter((event) => {
				return DateTime.fromISO(event.data.event_timestamp!)
					.startOf("day")
					.equals(date);
			});

			// Create the observation with 0 values for each of the goals in the
			// category. We will increment those values as appropriate.
			const dateObservation = {
				observationLabel: date,
				data: categoryGoals.reduce<{ [goal: string]: number }>(
					(accumulation, current) => {
						return { ...accumulation, [current]: 0 };
					},
					{},
				),
			};

			// Count of each goal event in the current category for the current date
			for (const event of categoryEventsForDate) {
				dateObservation.data[event.data.data.name] += 1;
			}

			return dateObservation;
		});

		return {
			...previous,
			[currentCategoryName]: countOfGoalAchievementsByDate,
		};
	}, {});
});
