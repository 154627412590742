import { useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

// Definitions
import { darkGray, portalColors } from "../../style/colors";
import { PRIMARY_FONT } from "../../style/fonts";

// Components
import ChevronIcon from "../../assets/svg/icons/chevronRight.svg?react";

// Data

import { mediaQueries } from "../../style";
import {
	selectedOrganizationAtom,
	userOrganizationsAtom,
} from "../../data/organization";
import { currentNavigatorFeatureAtom } from "../../data/feature";

export default function OrganizationSelector() {
	const [state, setState] = useState<"open" | "closed">("closed");

	const userOrganizations = useAtomValue(userOrganizationsAtom);
	const [selectedOrganization, setSelectedOrganization] = useAtom(
		selectedOrganizationAtom,
	);

	const setCurrentFeature = useSetAtom(currentNavigatorFeatureAtom);

	return (
		<div id="organization-selector" css={{ marginTop: 25, marginBottom: 0 }}>
			<p
				css={{
					fontFamily: PRIMARY_FONT,
					color: darkGray,
					fontWeight: 700,
					fontSize: 12,
					marginBottom: 0,
					textTransform: "uppercase",
				}}
			>
				Organization
			</p>
			<div
				css={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					cursor: "default",
				}}
				onClick={() => {
					if (userOrganizations.length > 1) {
						setState(state === "closed" ? "open" : "closed");
					}
				}}
			>
				<span
					css={{
						fontFamily: PRIMARY_FONT,
						fontWeight: 500,
						fontSize: 12,
						color: darkGray,
					}}
				>
					{selectedOrganization?.name}
				</span>
				{userOrganizations.length > 1 && (
					<ChevronIcon
						width={20}
						height={20}
						fill={darkGray}
						css={{
							transition: "all .5s ease",
							...(state === "open" ? { transform: "rotate(90deg)" } : {}),
							opacity: userOrganizations!.length > 1 ? 1 : 0,
						}}
					/>
				)}
			</div>
			<div
				css={{
					borderTopStyle: "solid",
					borderTopWidth: 1,
					borderTopColor: darkGray + "40",
					marginTop: 5,
				}}
			>
				<div
					css={{
						transition: "all .5s ease",
						height:
							state === "closed" ? 0 : 40 * (userOrganizations.length - 1) + 10,
						overflow: "hidden",
						borderBottomStyle: "solid",
						borderBottomWidth: 1,
						borderBottomColor: darkGray + "40",
						opacity: state === "closed" ? 0 : 1,
						paddingTop: 5,
						paddingBottom: 5,
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
					}}
				>
					{userOrganizations
						.filter(
							(organization) =>
								organization.name !== selectedOrganization?.name,
						)
						.map((organization) => (
							<button
								type="button"
								key={organization.id}
								onClick={() => {
									setSelectedOrganization(organization);
									setCurrentFeature("Analytics");
									setState("closed");
								}}
								css={{
									transition: "all .25s ease",
									fontFamily: PRIMARY_FONT,
									fontWeight: 600,
									fontSize: 12,
									color: "white",
									marginTop: 5,
									cursor: "default",
									padding: 10,
									border: "none",
									outline: "none",
									backgroundColor: portalColors.gray.dark,
									"&:hover": {
										color: "white",
										backgroundColor: portalColors.gray.dark,
									},
									[mediaQueries[1]]: {
										backgroundColor: portalColors.gray.mid,
										color: "white",
									},
								}}
							>
								{organization.name}
							</button>
						))}
				</div>
			</div>
		</div>
	);
}
