import { atom } from "jotai";
import { DateTime } from "luxon";
import type { HierarchyNode } from "d3-hierarchy";
import { stratify } from "@visx/hierarchy";

import { AS_eventsObservationsFilteredByEnclosingURLsAtom } from ".";
import { startedExperiencesAtom } from "../experience";
import type { UserDecisionEvent } from "./types";
import type {
	HIERARCHY_OBSERVATION,
	OBSERVATION_WITH_NUMERIC_VALUES,
} from "../../../types/charts";
import { allDatesInUserSelectionAtom } from "../../dateTime";
import {
	_makeDateBasedObservation,
	_makeObservation,
} from "../../../utils/observation";
import { groupBy } from "lodash";

/**
 * All "user_decision" events for the current project.
 * - Filtered by the current selected enclosing URLs.
 * */
export const AS_userDecisionsAtom = atom((get) => {
	const allEvents = get(AS_eventsObservationsFilteredByEnclosingURLsAtom);

	const allUserDecisionEvents = allEvents.filter(
		(event) => event.data.event_name === "user_decision",
	) as UserDecisionEvent[];

	return allUserDecisionEvents;
});

/** Separate user decision events by category and name. */
export const AS_userDecisionsByCategoryAndNameAtom = atom((get) => {
	const userDecisions = get(AS_userDecisionsAtom);
	const groupedByCategory = groupBy(userDecisions, "data.data.category");

	const groupedByCategoryAndName: {
		[category: string]: { [name: string]: UserDecisionEvent[] };
	} = {};

	for (const [category, decisions] of Object.entries(groupedByCategory)) {
		groupedByCategoryAndName[category] = groupBy(decisions, "data.data.name");
	}

	return groupedByCategoryAndName;
});

/** User decision events transformed into a hierarchy structure. */
export const AS_userDecisionHierarchyAtom = atom((get) => {
	const userDecisionsByCategoryAndName = get(
		AS_userDecisionsByCategoryAndNameAtom,
	);

	/**
	 * { category: [HierarchyNodes]}
	 */

	const categoryHierarchyNodes: {
		[category: string]: HierarchyNode<HIERARCHY_OBSERVATION>;
	} = {};

	for (const category in userDecisionsByCategoryAndName) {
		const hierarchyRecords: HIERARCHY_OBSERVATION[] = [
			{ observationLabel: category, data: { parent: null } },
		];

		for (const [name, decisions] of Object.entries(
			userDecisionsByCategoryAndName[category],
		)) {
			// Add each category decision to the hierarchy
			hierarchyRecords.push({
				observationLabel: name,
				data: { parent: category },
			});

			// Reduce the decision observations
			// into a simple choice: count object
			// that can then be pushed onto the hierarchy
			const countOfEachDecision = decisions.reduce<{
				[decision: string]: number;
			}>((previous, current) => {
				const userDecision = current.data.data.data as string;

				return {
					...previous,
					[userDecision]: previous[userDecision]
						? previous[userDecision] + 1
						: 1,
				};
			}, {});

			for (const key in countOfEachDecision) {
				hierarchyRecords.push({
					observationLabel: key,
					data: { parent: name, count: countOfEachDecision[key] },
				});
			}
		}

		categoryHierarchyNodes[category] = stratify<HIERARCHY_OBSERVATION>()
			.id((d) => d.observationLabel)
			.parentId((d) => d.data.parent)(hierarchyRecords)
			.sum((d) => d.data.count ?? 0);
	}

	return categoryHierarchyNodes;
});
