import { Getter, Setter, atom, getDefaultStore } from "jotai";
import { AtlasEngineDatabase } from "@form-films/atlas-engine-shared-definitions";

// Data
import { LS_OrganizationalCoursesAtom } from "../courses";
import { selectedDateRangeAtom } from "../../dateTime";
import supabaseClientAtom from "../../supabaseClient";

// Definitions
import {
	OBSERVATION_WITH_DATE_LABEL,
	OBSERVATION_GENERIC,
	OBSERVATION_WITH_STRING_LABEL,
} from "../../../types/charts";

// Utilities
import { groupByDate } from "../../../utils/groupBy";
import { getDateRange } from "../../../utils/datesAndTimes";
import { gatherRecords } from "../../utils/utilities";
import {
	_makeObservation,
	_makeStringBasedObservation,
} from "../../../utils/observation";
import { LS_FinishedEngagementDetailsAtom } from "../engagementDetail";
import { LS_ModulesAtom } from "../modules";
import { learningSystemAtom } from "..";
import { LS_Engagement } from "../types";
import { LS_userProfiles } from "../userProfiles";

/**
 * Get all course engagement records accessible by the authenticated user
 * for the currently selected organizational courses that meet the date
 * threshold currently selected:
 *
 * 1. Incomplete engagements that were started during selected
 *    time period are included.
 * 2. Complete engagements that were completed during the selected
 *    time period are also included.
 *
 * RLS policies will only allow the user to SELECT records
 * that belong to the user directly or those that that belong
 * to organizations the user is also associated with.
 * */
export const updateLearningSystemEngagements = async (
	get: Getter,
	set: Setter,
) => {
	const supabaseClient = get(supabaseClientAtom);
	const selectedDateRange = get(selectedDateRangeAtom);
	const organizationCourses = get(LS_OrganizationalCoursesAtom);

	const incompleteEngagements = await gatherRecords<
		AtlasEngineDatabase["public"]["Tables"]["learning_system_course_engagement"]["Row"]
	>(
		supabaseClient
			.from("learning_system_course_engagement")
			.select("*")
			.in(
				"course_id",
				organizationCourses.map((course) => course.observationLabel),
			)
			.is("finished_at", null)
			.gte("started_at", selectedDateRange.from.toUTC())
			.lte("started_at", selectedDateRange.to.toUTC()),
	);

	const completeEngagements = await gatherRecords<
		AtlasEngineDatabase["public"]["Tables"]["learning_system_course_engagement"]["Row"]
	>(
		supabaseClient
			.from("learning_system_course_engagement")
			.select("*")
			.in(
				"course_id",
				organizationCourses.map((course) => course.observationLabel),
			)
			.not("finished_at", "is", null)
			.gte("finished_at", selectedDateRange.from.toUTC())
			.lte("finished_at", selectedDateRange.to.toUTC()),
	);

	const mergedRecords = [...incompleteEngagements, ...completeEngagements];
	mergedRecords.sort((a, b) => a.started_at.localeCompare(b.started_at));

	set(
		_LS_EngagementsBaseAtom,
		mergedRecords.map((engagement) => ({
			..._makeStringBasedObservation(engagement, "id"),
		})),
	);
};

/** Update the metadata of single engagement */
export const updateEngagementMetadata = async (
	engagmentObservation: LS_Engagement,
	metadata: LS_Engagement["data"]["metadata"],
) => {
	const jotaiStore = getDefaultStore();
	const engagements = jotaiStore.get(_LS_EngagementsBaseAtom);
	const learningSystem = jotaiStore.get(learningSystemAtom);

	const matchingEngagementIndex = engagements.findIndex(
		(engagement) =>
			engagement.observationLabel === engagmentObservation.observationLabel,
	);

	if (learningSystem && matchingEngagementIndex !== -1) {
		const updatedEngagement = await learningSystem()._updateCourseEngagement({
			engagementID: engagmentObservation.observationLabel,
			userID: engagmentObservation.data.user_id,
			metadata,
		});

		jotaiStore.set(
			_LS_EngagementsBaseAtom,
			engagements.map((engagement, index) =>
				index === matchingEngagementIndex
					? _makeStringBasedObservation(updatedEngagement, "id")
					: engagement,
			),
		);
	}
};

export const _LS_EngagementsBaseAtom = atom<Array<LS_Engagement>>([]);

/**
 * All engagements for the currently selected organizational courses
 * with additional data merged in from other database records:
 *
 * 1. The last completed module.
 * 2. The display name of the associated course.
 * 3. A nested observation of the user associated with the engagement.
 * */
export const LS_EngagementsAtom = atom<Array<LS_Engagement>>((get) => {
	const baseEngagements = get(_LS_EngagementsBaseAtom);
	const engagementDetails = get(LS_FinishedEngagementDetailsAtom);
	const courseModules = get(LS_ModulesAtom);
	const userProfiles = get(LS_userProfiles);
	const organizationCourses = get(LS_OrganizationalCoursesAtom);

	// Augment database records with additional detail.
	const updatedEngagements = baseEngagements.map((engagement) => {
		// Completed modules for this engagement
		const completedModules = engagementDetails.filter(
			(detail) => detail.data.engagement_id === engagement.observationLabel,
		);

		// Sort by completion timestamp
		completedModules.sort();

		return {
			...engagement,
			data: {
				...engagement.data,
				last_completed_module: completedModules.length
					? courseModules.find(
							(module) =>
								module.observationLabel ===
								completedModules[completedModules.length - 1].data.module_id,
					  )?.data.display_name ?? undefined
					: undefined,
				course_display_name: organizationCourses.find(
					(course) => course.observationLabel === engagement.data.course_id,
				)?.data.display_name,
				user: userProfiles.find(
					(profile) => profile.observationLabel === engagement.data.user_id,
				),
			},
		};
	});

	return updatedEngagements;
});

/** Get all open engagement records for the selected organization. */
export const LS_OpenEngagementsAtom = atom((get) =>
	get(LS_EngagementsAtom).filter((engagement) => !engagement.data.finished_at),
);

/** Get all closed engagement records for the selected organization. */
export const LS_ClosedEngagementsAtom = atom((get) =>
	get(LS_EngagementsAtom).filter((engagement) => engagement.data.finished_at),
);

export const LS_EngagmentsByCourseAndDate = atom<
	OBSERVATION_WITH_DATE_LABEL<Record<string, number>>[]
>((get) => {
	const organizationCourses = get(LS_OrganizationalCoursesAtom);

	const availableCourseDisplayNames = organizationCourses.map(
		(course) => course.data.display_name,
	);

	const engagementsGroupedByStartDate = groupByDate(
		get(LS_EngagementsAtom),
		"started_at",
	);

	const selectedDateRange = get(selectedDateRangeAtom);
	const allDatesInRange = getDateRange(
		selectedDateRange.from,
		selectedDateRange.to,
	);

	const data = allDatesInRange.map((date) => {
		const dateAsString = date.toISODate();

		const observation: OBSERVATION_WITH_DATE_LABEL<Record<string, number>> = {
			observationLabel: date,
			data: availableCourseDisplayNames.reduce(
				(previous, next) => ({ ...previous, [next]: 0 }),
				{},
			),
		};

		const engagementsForCurrentDate =
			engagementsGroupedByStartDate[dateAsString!];

		if (!engagementsForCurrentDate) return observation;

		for (const engagement of engagementsForCurrentDate) {
			const currentCourseName = organizationCourses.find(
				(course) => course.observationLabel === engagement.data.course_id,
			)?.data.display_name;

			if (currentCourseName) observation.data[currentCourseName] += 1;
		}

		return observation;
	});

	return data;
});
