import { atom } from "jotai";
import { updateAnalyticsExperiences } from "./experience";
import { updateAnalyticsEvents } from "./events";

export { default as supabaseClientAtom } from "../supabaseClient";
export * from "../user";
export * from "./events";
export * from "./experience";
export * from "../project";

export const refreshAnalyticsSystemDataAtom = atom<boolean, [boolean], void>(
	false,
	async (get, set, value) => {
		const existingRefresh = get(refreshAnalyticsSystemDataAtom);
		if (existingRefresh) return;
		console.debug("Navigator: Refreshing Analytics System data");

		set(refreshAnalyticsSystemDataAtom, true);
		await Promise.all([
			updateAnalyticsExperiences(get, set),
			updateAnalyticsEvents(get, set),
		]);

		set(refreshAnalyticsSystemDataAtom, false);
	},
);
