import { atom } from "jotai";

import { AS_eventsObservationsFilteredByEnclosingURLsAtom } from ".";
import { startedExperiencesAtom } from "../experience";
import { NodeEntryEvent } from "./types";

/**
 * All "node_entry" observations for the current project.
 * - Filtered by the current selected enclosing URLs.
 * - Filtered by currently selected dates.
 * */
export const AS_nodeEntryObservationAtom = atom((get) => {
	const allEvents = get(AS_eventsObservationsFilteredByEnclosingURLsAtom);

	// All NodeEntry events
	const allNodeEntryEvents = allEvents.filter(
		(event) => event.data.event_name === "node_entry",
	);

	// Filter out old version of node entry that
	// didn't contain an `order` property
	return allNodeEntryEvents.filter(
		(event) =>
			event.data.data &&
			typeof event.data.data === "object" &&
			Object.hasOwn(event.data.data, "order"),
	) as NodeEntryEvent[];
});

/** The last node_entry event for each experience. */
export const finalNodeEntryEventsAtom = atom((get) => {
	const entryEvents = get(AS_nodeEntryObservationAtom);
	const experienceSummaries = get(startedExperiencesAtom);

	return entryEvents.filter((event) =>
		experienceSummaries.find(
			(summary) =>
				summary.observationLabel === event.data.experience_id &&
				summary.data.last_entry_timestamp === event.data.event_timestamp,
		),
	);
});
