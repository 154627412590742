import { atom } from "jotai";
import { DateTime } from "luxon";

import { getDateRange } from "../utils/datesAndTimes";
import { refreshLearningSystemDataAtom } from "./learningSystem";
import { refreshAnalyticsSystemDataAtom } from "./analytics";

const _selectedDateRangeAtom = atom<{
	from: DateTime;
	to: DateTime;
}>({
	from: DateTime.now().minus({ days: 14 }).startOf("day"),
	to: DateTime.now().endOf("day"),
});

/**
 * The current timeframe selected by the user.
 *
 * Updates to this atom will cause a data refresh for
 * learning system data.
 * */
export const selectedDateRangeAtom = atom<
	{ from: DateTime; to: DateTime },
	[{ from: DateTime; to: DateTime }],
	void
>(
	(get) => get(_selectedDateRangeAtom),
	(_get, set, newValues) => {
		set(_selectedDateRangeAtom, newValues);
		set(refreshLearningSystemDataAtom, true);
		set(refreshAnalyticsSystemDataAtom, true);
	},
);

/**
 * An array of all dates between the user selected start
 * and end dates (inclusive).
 *  */
export const allDatesInUserSelectionAtom = atom((get) => {
	const selectedStartAndEndDates = get(selectedDateRangeAtom);

	return getDateRange(
		selectedStartAndEndDates.from,
		selectedStartAndEndDates.to,
	);
});
