import { ObjectWithStringKeys } from "@form-films/atlas-engine-shared-definitions";

import {
	OBSERVATION_WITH_DATE_LABEL,
	OBSERVATION_WITH_STRING_LABEL,
} from "../types/charts";
import { DateTime } from "luxon";

// biome-ignore lint/suspicious/noExplicitAny: Allow transformers to be anything.
type AttributeTransformer = (value: any) => any;

/**
 * Convert an object to a single Observation.
 *
 * For data consistency across the application,
 * all source data should be converted to Observations
 * at the earliest feasible moment.
 * */
export function _makeObservation<
	ReturnType extends
		| OBSERVATION_WITH_DATE_LABEL<Omit<DataRecord, ObservationLabel>>
		| OBSERVATION_WITH_STRING_LABEL<Omit<DataRecord, ObservationLabel>>,
	DataRecord extends ObjectWithStringKeys,
	ObservationLabel extends keyof DataRecord,
>(
	dataRecord: DataRecord,
	label: ObservationLabel,
	transformers?: {
		[key in Exclude<keyof DataRecord, ObservationLabel>]?: AttributeTransformer;
	},
): ReturnType {
	const observationLabel = dataRecord[label] as string | DateTime;

	const additionalData = Object.entries(
		dataRecord,
	).reduce<ObjectWithStringKeys>((accumulation, currentAttribute) => {
		// Ignore the observation label.
		if (currentAttribute[0] === label) return accumulation;

		const transformerFunction =
			transformers?.[
				currentAttribute[0] as Exclude<keyof DataRecord, ObservationLabel>
			];

		// If a transformer function exists, use it,
		// otherwise, pass through the original value on the object.
		accumulation[currentAttribute[0]] = transformerFunction
			? transformerFunction(currentAttribute[1])
			: currentAttribute[1];
		return accumulation;
	}, {});

	return { observationLabel, data: additionalData } as ReturnType;
}

/** Make a date labelled observation from an object. */
export function _makeDateBasedObservation<
	DataRecord extends ObjectWithStringKeys,
	ObservationLabel extends keyof DataRecord,
>(
	dataRecord: DataRecord,
	label: ObservationLabel,
	transformers?: {
		[key in Exclude<keyof DataRecord, ObservationLabel>]?: AttributeTransformer;
	},
): OBSERVATION_WITH_DATE_LABEL<Omit<DataRecord, ObservationLabel>> {
	return _makeObservation<
		OBSERVATION_WITH_DATE_LABEL<Omit<DataRecord, ObservationLabel>>,
		DataRecord,
		ObservationLabel
	>(
		{ ...dataRecord, [label]: DateTime.fromISO(dataRecord[label] as string) },
		label,
		transformers,
	);
}

/** Make a string labelled observation from an object. */
export function _makeStringBasedObservation<
	DataRecord extends ObjectWithStringKeys,
	ObservationLabel extends keyof DataRecord,
>(
	dataRecord: DataRecord,
	label: ObservationLabel,
	transformers?: {
		[key in Exclude<keyof DataRecord, ObservationLabel>]?: AttributeTransformer;
	},
): OBSERVATION_WITH_STRING_LABEL<Omit<DataRecord, ObservationLabel>> {
	return _makeObservation<
		OBSERVATION_WITH_STRING_LABEL<Omit<DataRecord, ObservationLabel>>,
		DataRecord,
		ObservationLabel
	>(dataRecord, label, transformers);
}

export function convertToObservationsWithStringLabels<
	DataRecord extends ObjectWithStringKeys,
	ObservationLabel extends keyof DataRecord,
>(
	records: DataRecord[],
	label: ObservationLabel,
	transformers?: {
		[key in Exclude<keyof DataRecord, ObservationLabel>]?: AttributeTransformer;
	},
) {
	return records.map((record) =>
		_makeObservation<
			OBSERVATION_WITH_STRING_LABEL<Omit<DataRecord, ObservationLabel>>,
			DataRecord,
			ObservationLabel
		>(record, label, transformers),
	);
}

export function convertToObservationsWithDateLabels<
	DataRecord extends ObjectWithStringKeys,
	ObservationLabel extends keyof DataRecord,
>(
	records: DataRecord[],
	label: ObservationLabel,
	transformers?: {
		[key in Exclude<keyof DataRecord, ObservationLabel>]?: AttributeTransformer;
	},
) {
	return records.map((record) =>
		_makeObservation<
			OBSERVATION_WITH_DATE_LABEL<Omit<DataRecord, ObservationLabel>>,
			DataRecord,
			ObservationLabel
		>(record, label, transformers),
	);
}
