import { Pie } from "@visx/shape";

import { portalColors } from "../../../style/colors";
import { COMMON_CHART_PROPS } from "../../../types/charts/props";
import { PIE_CHART_OBSERVATION } from "../../../types/charts";
import useSVGDimensions from "../../../hooks/useSVGDimensions";
import SegmentGenerator from "./SegmentGenerator";
import { useAtomValue } from "jotai";
import { refreshAnalyticsSystemDataAtom } from "../../../data/analytics";
import useObserveChartComponents from "../../../data/hooks/useObserveChartComponents";
import ChartContainer from "../../ChartContainer";

type PieChartProps = COMMON_CHART_PROPS<PIE_CHART_OBSERVATION>;

export default function PieChart({
	observations,
	margins = { vertical: 20, horizontal: 20 },
	dataColors,
	chartTitle,
	colorTheme = portalColors.gray,
	transparentBackground = false,
	chartExplanation,
	chartActions,
}: PieChartProps) {
	const dataLoading = useAtomValue(refreshAnalyticsSystemDataAtom);

	const {
		observeComponent,
		componentWidth,
		componentHeight,
		observeTitle,
		observeLegend,
		titleHeight,
		legendHeight,
	} = useObserveChartComponents();

	// Determine the effective dimensions for the actual SVG element.
	const svgDimensions = useSVGDimensions({
		width: componentWidth,
		height: componentHeight,
		margins,
		heightSubstractors: [titleHeight, legendHeight],
	});

	const radius = Math.min(svgDimensions[0], svgDimensions[1]) / 2;

	return (
		<ChartContainer
			colorTheme={colorTheme}
			margins={margins}
			transparentBackground={transparentBackground}
			chartTitle={{ ...chartTitle }}
			chartExplanation={chartExplanation}
			observer={observeComponent}
			titleObserver={observeTitle}
			chartActions={chartActions}
		>
			{svgDimensions[0] && svgDimensions[1] && (
				<svg
					viewBox={`0 0 ${svgDimensions[0]} ${svgDimensions[1]}`}
					css={{
						width: svgDimensions[0],
						height: svgDimensions[1],
						overflow: "unset",
					}}
				>
					<Pie
						data={observations}
						pieValue={(datum) => datum.data.percentage}
						outerRadius={radius}
						innerRadius={svgDimensions[0] * 0.05}
						padAngle={0.2}
						padRadius={25}
						fill={(slice) => dataColors[slice.index]}
					>
						{(pie) =>
							dataLoading ? null : (
								<SegmentGenerator
									svgDimensions={svgDimensions}
									dataColors={dataColors}
									{...pie}
								/>
							)
						}
					</Pie>
				</svg>
			)}
			<div>
				{/* <LegendThreshold
          scale={colorScale}
          // Transform the decimal to a percentage.
          labelFormat={(value) => {
            return `${value * 100}%`;
          }}>
          {(labels) => {
            return labels.reverse().map((label, i) => (
              <LegendItem key={`legend-quantile-${i}`} margin="1px 0">
                <svg width={10} height={10}>
                  <rect fill={label.value} width={10} height={10} />
                </svg>
                <LegendLabel
                  align="left"
                  margin="2px 0 0 10px"
                  css={{
                    fontSize: 12,
                    fontFamily: SECONDARY_FONT,
                    color: darkGray,
                    fontWeight: 500,
                  }}>
                  {label.text}
                </LegendLabel>
              </LegendItem>
            ));
          }}
        </LegendThreshold> */}
			</div>
		</ChartContainer>
	);
}
